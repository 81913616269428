import React from 'react';

const EmailPreviewDropdownOptions = ({email, onDropdownSelection}) => {

    return (
        <div id={email.keys.triggeredsendexternalkey + "div_id"} key={email.keys.triggeredsendexternalkey + "div_key"} className='dropdown-item' onClick={() => onDropdownSelection(email)}>
            {email.values.name}
        </div> 
    )
}

export default EmailPreviewDropdownOptions