import React, { useEffect, useState } from 'react';
import Loading from '../bootstrap-components/Loading';
import { useSelector } from 'react-redux';
import { getMe } from '../../redux/meSlice';
import './SfmcInstall.scss';

const SfmcInstall = () => {

    const [errorMessage, setErrorMessage] = useState();
    const [installData, setInstallData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const apiMeData = useSelector(getMe);
    const [authToken, setAuthToken] = useState('');

    // useEffect(() => {
    //     async function getAuthToken() {
    //         const getAuthTokenCall = await fetch('/authtoken');
    //         const getAccessTokenCallData = await getAuthTokenCall.json();
    //         setAuthToken(getAccessTokenCallData.accessToken);
    //     }
    //     getAuthToken();
    // }, [])

    // useEffect(() => {
    //     if(installData || errorMessage) {
    //         setIsLoading(false);
    //     }
    // },[installData, errorMessage])

    async function callInstallEndpoint() {
        setIsLoading(true);
        const subdomain = apiMeData.user.rest.rest_instance_url.split('//').pop().split('.')[0];
        const mid = apiMeData.user.organization.member_id;

        const installBody = {
            "subdomain": subdomain,
            "mid": mid.toString(),
            "access_token": authToken, 
            "action": "create"
        }

        console.log("installBody", installBody);

        try {
            const resInstall = await fetch("https://rvw-eds-api.herokuapp.com/install_test", {
                method: "POST",
                body: JSON.stringify(installBody),
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": '*',
                    "Connection": 'keep-alive',
                    "Accept-Encoding": 'gzip, deflate, br',
                    "Host": "rvw-eds-api.herokuapp.com",
                    "Accept": "*/*",
                }
            })

            if (!resInstall.ok) {
                const message = `An error has occured: ${resInstall.status} - ${resInstall.statusText}`;
                setErrorMessage(message);
            }

            const dataInstall = await resInstall.text();
            setInstallData(dataInstall);

        } catch(err) {
            setErrorMessage(err.message);
        }
    }

    return (
        <div className='install-page-container'>
            <h3>Stand Up Your Marketing Cloud BU in Minutes</h3>
            <p className='install-page-item'>Reach out to your Red Van Workshop representative to help you run an Autobahn Install.</p>
            <p className='install-page-item'>The Autobahn Install packages together all your essentials so you and your team don't have to touch code or spend hours if not days creating Email Templates, Trigger Sends, and Data Extensions.</p>
            <p className='install-page-item'>This Script will also create the code and data necessary for this App to become the powerful and helpful tool you'll come to love. You can look forward to an Analytics Dashboard and the ability to quickly edit emails without coding.</p>
            {/* {isLoading
                ? <div className='install-page-item'><Loading /></div>
                : installData 
                    ? <p style={{color: "green"}}>{installData}</p>
                    : errorMessage 
                        ? <p style={{color: "red"}}>{errorMessage}</p>
                        : <button className='btn-primary install-page-item' onClick={callInstallEndpoint}>Start Install</button>
            } */}
        </div>
    )
}

export default SfmcInstall