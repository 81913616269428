import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ExpressService from '../../service/ExpressService';
import SegmentationService from '../../service/SegmentationService';

const initialState = {
    queryFolderValue: null,
    queryFolderStatus: 'idle',
    error: null,
    updateRunPostResult: null,
    updateRunPostStatus: 'idle',
    updateRunPostError: null,
    checkRunPostResult: null,
    checkRunPostStatus: 'idle',
    checkRunPostError: null,
    activeDeKey: null,
    updateSegmentListingsResult: null,
    updateSegmentListingsStatus: 'idle',
    updateSegmentListingsError: null,
    activeQueryId: null,
    dERowCountStatus: 'idle',
    dERowCountResult: null,
    createDeDataStatus: 'idle',
    createDeDataResult: null,
    createQueryDataStatus: 'idle',
    createQueryDataResult: null,
    isCreatePending: null,
    isCreateCompleteSuccess: null,
    isCreateCompleteError: null,
    isRunQueryCompleteSuccess: null,
    isRunQueryCompleteError: null,
    isShowNewSegmentationEditorRow: null,
    isShowSegmentationEditorRow: null,
    openedRowSegmentName: null
};

const headers = {
    "Content-Type": "application/json"
}

//Get Query Folder ID (categoryId)
export const fetchFolderId = createAsyncThunk('query/getFolderId', async () => {
    const folders = await fetch('/rest/automation/v1/folders?$filter=categorytype%20eq%20queryactivity%20');
    const data = await folders.json();
    return data.items[0].categoryId;
});


//Run query activity
export const runQuery = createAsyncThunk('query/runQuery', async (queryId, { rejectWithValue }) => {
    const res = await fetch('/rest/automation/v1/queries/' + queryId + '/actions/start/', {
        method: "POST",
        headers: headers
    })

    const data = await res.json();

    if (data?.errors && data?.errors[0]?.message) {
        return rejectWithValue(data?.errors[0]?.message);
    } else if (data !== 'OK' && 'errorcode' in data) {   // generic errors https://developer.salesforce.com/docs/marketing/marketing-cloud/guide/error-handling.html
        return rejectWithValue(data?.message);
    }

    return data;
});


//Check query isDone
export const checkQuery = createAsyncThunk('query/checkQuery', async (queryId, {rejectWithValue}) => {
    const res = await fetch('/rest/automation/v1/queries/' + queryId + '/actions/isrunning/');

    const data = await res.json();

    if (data?.errors && data?.errors[0]?.message) {
        return rejectWithValue(data?.errors[0]?.message);
    } else if (data !== 'OK' && 'errorcode' in data) {   // generic errors https://developer.salesforce.com/docs/marketing/marketing-cloud/guide/error-handling.html
        return rejectWithValue(data?.message);
    }

    return data;
});

//Update Segment_Listings DE
// export const updateSegmentListingsDE = createAsyncThunk('query/updateSegmentListings', async (updatedSegmentData) => {
//     return await ExpressService.callSFMC('/rest/data/v1/async/dataextensions/key:segment_listings/rows', 'PUT', 'DE', updatedSegmentData);
// });
export const updateSegmentListingsDE = createAsyncThunk('query/updateSegmentListings', async (updatedSegmentData) => {
    return await ExpressService.callSFMC("/rest/hub/v1/dataevents/key:segment_listings/rowset", "POST", null, updatedSegmentData);
});


//Get DE Row Count
export const fetchDeRowCount = createAsyncThunk('query/fetchRowCount', async (activeDeKey) => {
    return await SegmentationService.getSegmentRowCount(activeDeKey);
});

// Create new segment data
export const createDeDataCall = createAsyncThunk('query/createDeDataCall', async ({ segmentName, segmentFolderId, segmentType }) => {
    return await SegmentationService.createDe(segmentName, segmentFolderId, segmentType);
});

// Create new query
export const createQueryDataCall = createAsyncThunk('query/createQueryDataCall', async ({ segmentName, qFolderName, query, deID }) => {
    return await SegmentationService.createQuery(segmentName, qFolderName, query, deID);
});


const segmentationSlice = createSlice({
    name: 'segmentation',
    initialState,
    reducers: {
        resetCheckQueryStatus(state) {
            return {
                ...state,
                checkRunPostStatus: 'idle',
                checkRunPostResult: null,
                checkRunPostError: null
            }
        },
        setActiveDeKey(state, deKey) {
            return {...state, activeDeKey: deKey}
        },
        resetUpdateSegmentListings(state) {
            return {
                ...state,
                updateSegmentListingsResult: null,
                updateSegmentListingsStatus: 'idle',
                updateSegmentListingsError: null
            }
        },
        setActiveQueryId(state, queryId) {
            return {...state, activeQueryId: queryId}
        },
        resetActiveDe(state) {
            return {...state, activeDeKey: null}
        },
        resetDeRowCount(state) {
            return {
                ...state,
                dERowCountResult: null,
                dERowCountStatus: 'idle'
            }
        },
        setIsCreatePending(state, param) {
            return {...state, isCreatePending: param.payload}
        },
        setIsCreateCompleteSuccess(state, param) {
            return {...state, isCreateCompleteSuccess: param.payload}
        },
        setIsCreateCompleteError(state, param) {
            return {...state, isCreateCompleteError: param.payload}
        },
        setIsRunQueryCompleteSuccess(state, param) {
            return {...state, isRunQueryCompleteSuccess: param.payload}
        },
        setIsRunQueryCompleteError(state, param) {
            return {...state, isRunQueryCompleteError: param.payload}
        },
        setIsShowNewSegmentationEditorRow(state, param) {
            return {...state, isShowNewSegmentationEditorRow: param.payload}
        },
        setIsShowSegmentationEditorRow(state, param) {
            return {...state, isShowSegmentationEditorRow: param.payload}
        },
        setOpenedRowSegmentName(state, param) {
            return {...state, openedRowSegmentName: param.payload}
        },
        resetCreateFlags(state) {
            return {
                ...state,
                isCreatePending: null,
                isCreateCompleteSuccess: null,
                isCreateCompleteError: null,
                createDeDataResult: null,
                createDeDataStatus: 'idle',
                createQueryDataResult: null,
                createQueryDataStatus: 'idle',
                updateRunPostResult: null,
                updateRunPostStatus: 'idle',
                updateRunPostError: null,
                isRunQueryCompleteSuccess: null,
                isRunQueryCompleteError: null,
            }
        },
    },
    extraReducers: {
        [fetchFolderId.pending]: (state, action) => {
            return {...state, queryFolderStatus: 'pending'};
        },
        [fetchFolderId.fulfilled]: (state, action) => {
            return {...state, queryFolderStatus: 'fulfilled', queryFolderValue: action.payload};
        },
        [fetchFolderId.rejected]: (state, action) => {
            return {...state, queryFolderStatus: 'rejected',  queryFolderValue: action.payload};
        },
        [runQuery.pending]: (state, action) => {
            return {...state,
                updateRunPostStatus: 'pending',
                updateRunPostResult: null,
                updateRunPostError: null
            };
        },
        [runQuery.fulfilled]: (state, action) => {
            if (action?.payload?.status === "200-OK") {
                return {...state,
                    updateRunPostStatus: 'fulfilled',
                    updateRunPostResult: action.payload,
                    value: action.meta.arg
                };
            } else {
                return {...state,
                    updateRunPostStatus: 'fulfilled',
                    updateRunPostResult: action.payload
                };
            }
        },
        [runQuery.rejected]: (state, action) => {
            return {...state, updateRunPostStatus: 'rejected'};
        },
        [checkQuery.pending]: (state, action) => {
            return {...state,
                checkRunPostStatus: 'pending',
                checkRunPostResult: null,
                checkRunPostError: null
            };
        },
        [checkQuery.fulfilled]: (state, action) => {
            if (action?.payload?.status === "200-OK") {
                return {...state,
                    checkRunPostStatus: 'fulfilled',
                    checkRunPostResult: action.payload,
                    value: action.meta.arg
                };
            } else {
                return {...state,
                    checkRunPostStatus: 'fulfilled',
                    checkRunPostResult: action.payload
                };
            }
        },
        [checkQuery.rejected]: (state, action) => {
            return {...state, checkRunPostStatus: 'rejected'};
        },
        [updateSegmentListingsDE.pending]: (state, action) => {
            return {...state,
                updateSegmentListingsStatus: 'pending',
                updateSegmentListingsResult: null,
                updateSegmentListingsError: null
            };
        },
        [updateSegmentListingsDE.fulfilled]: (state, action) => {
            if (action?.payload?.status === '202-Accepted') {
                return {...state,
                    updateSegmentListingsStatus: 'fulfilled',
                    updateSegmentListingsResult: action.payload,
                    value: action.meta.arg
                };
            } else {
                return {...state,
                    updatePutStatus: 'fulfilled',
                    updateSegmentListingsResult: action.payload
                };
            }
        },
        [updateSegmentListingsDE.rejected]: (state, action) => {
            return {...state, updateSegmentListingsStatus: 'rejected'};
        },
        [fetchDeRowCount.pending]: (state, action) => {
            return {...state, dERowCountStatus: 'pending'};
        },
        [fetchDeRowCount.fulfilled]: (state, action) => {
            return {...state, dERowCountStatus: 'fulfilled', dERowCountResult: action.payload};
        },
        [fetchDeRowCount.rejected]: (state, action) => {
            return {...state, dERowCountStatus: 'rejected',  dERowCountResult: action.payload};
        },
        [createDeDataCall.pending]: (state, action) => {
            return {...state, createDeDataStatus: 'pending'};
        },
        [createDeDataCall.fulfilled]: (state, action) => {
            return {...state, createDeDataStatus: 'fulfilled', createDeDataResult: action.payload};
        },
        [createDeDataCall.rejected]: (state, action) => {
            return {...state, createDeDataStatus: 'rejected',  createDeDataResult: action.payload};
        },
        [createQueryDataCall.pending]: (state, action) => {
            return {...state, createQueryDataStatus: 'pending'};
        },
        [createQueryDataCall.fulfilled]: (state, action) => {
            return {...state, createQueryDataStatus: 'fulfilled', createQueryDataResult: action.payload};
        },
        [createQueryDataCall.rejected]: (state, action) => {
            return {...state, createQueryDataStatus: 'rejected',  createQueryDataResult: action.payload};
        },
    }
});

export const getFolderId = state => state?.segmentation?.queryFolderValue;
export const getFolderIdStatus = state => state?.segmentation?.queryFolderStatus;

export const getRunQueryResult = state => state?.segmentation?.updateRunPostResult;
export const getRunQueryStatus = state => state?.segmentation?.updateRunPostStatus;

export const getCheckQueryStatus = state => state?.segmentation?.checkRunPostStatus;
export const getCheckQueryResult = state => state?.segmentation?.checkRunPostResult;

export const { resetCheckQueryStatus, setActiveDeKey, resetUpdateSegmentListings, setActiveQueryId, resetActiveDe, resetDeRowCount, setIsCreatePending, setIsCreateCompleteSuccess, setIsCreateCompleteError, setIsRunQueryCompleteSuccess, setIsRunQueryCompleteError, setIsShowNewSegmentationEditorRow, setIsShowSegmentationEditorRow, setOpenedRowSegmentName, resetCreateFlags } = segmentationSlice.actions;

export const getActiveDeKey = state => state?.segmentation?.activeDeKey?.payload;

export const getActiveQueryId = state => state?.segmentation?.activeQueryId?.payload;

export const getSegmentListingsPutResult = state => state?.segmentation?.updateSegmentListingsResult;

export const getDeRowCountStatus = state => state?.segmentation?.dERowCountStatus;

export const getDeRowCountResults = state => state?.segmentation?.dERowCountResult;

export const getCreateDeDataStatus = state => state?.segmentation?.createDeDataStatus;
export const getCreateDeDataResults = state => state?.segmentation?.createDeDataResult;

export const getCreateQueryDataStatus = state => state?.segmentation?.createQueryDataStatus;
export const getCreateQueryDataResult = state => state?.segmentation?.createQueryDataResult;

export const getIsCreatePending = state => state?.segmentation?.isCreatePending;
export const getIsCreateCompleteSuccess = state => state?.segmentation?.isCreateCompleteSuccess;
export const getIsCreateCompleteError = state => state?.segmentation?.isCreateCompleteError;

export const getIsRunQueryCompleteSuccess = state => state?.segmentation?.isRunQueryCompleteSuccess;
export const getIsRunQueryCompleteError = state => state?.segmentation?.isRunQueryCompleteError;

export const getIsShowNewSegmentationEditorRow = state => state?.segmentation?.isShowNewSegmentationEditorRow;
export const getIsShowSegmentationEditorRow = state => state?.segmentation?.isShowSegmentationEditorRow;

export const getOpenedRowSegmentName = state => state?.segmentation?.openedRowSegmentName;


export default segmentationSlice.reducer;