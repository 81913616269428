import ReactGA from "react-ga4";

const GoogleAnalyticsService = {
    triggerEvent: function(category, action) {
        ReactGA.event({
            category: category, 
            action: action
        })
    }
}

export default GoogleAnalyticsService;