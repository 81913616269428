import React, { useEffect, useState } from 'react';
import './StyleRow.scss';

const StyleRowFontDropdown = ({onStyleChange, style, brandFontArray, dropdownType}) => {

    const [valueValue, setValueValue] = useState('');
    const [hasMadeChange, setHasMadeChange] = useState(false);
    const [styleObject, setStyleObject] = useState(null);
    const [displayValue, setDisplayValue] = useState();

    const displayFontStyle = {
        fontFamily: valueValue
    }

    const emailSafeFonts = [
        "Arial",
        "Courier New",
        "Georgia",
        "Lucida Sans Unicode",
        "Tahoma",
        "Times New Roman",
        "Trebuchet MS",
        "Verdana",
        "Roboto, sans-serif",
        "Roboto Condensed, sans-serif",
        "Roboto Slab, serif"
    ]

    useEffect(() => {
        setValueValue(style.value);
    }, [])

    useEffect(() => {
        let styleObjectSetter = {
            "key": style.key,
            "value": valueValue,
            "name": style.name,
            "isactive": style.isactive,
            "category": style.category,
            "sub_category": style.sub_category,
            "input_identifier": style.input_identifier,
            "input_type": style.input_type
        }
        setStyleObject(styleObjectSetter);

        if (brandFontArray) {
            // Display the name of the font (primary, secondary, tertiary). If this row's value == an item in the brandFontArray, then show brandFont Name
            const brandFontIndex = brandFontArray.findIndex(_element => _element.value === valueValue)
            if (brandFontIndex > -1) {
                setDisplayValue(brandFontArray[brandFontIndex].name);
            } else {
                setDisplayValue(valueValue);
            }
        }
    }, [valueValue])

    useEffect(() => {
        if (styleObject?.value) {
            //Needed to stop onStyleChange useEffect from executing on initial load, which flips the didStylesChange flag
            if (hasMadeChange) {
                onStyleChange(styleObject);
                setHasMadeChange(false);
            }
        }
    }, [styleObject])

    function handleChooseFont(font) {
        setHasMadeChange(true);
        setValueValue(dropdownType === "brandFont" ? font : font.value);
    }

    function renderDropdown() {
        if (dropdownType === "brandFont") {
            return (
                emailSafeFonts.map((safeFont, index) => (
                    <div key={'brandFontDrop' + safeFont + index} style={{fontFamily: safeFont}} className="color-dropdown-container dropdown-item" onClick={() => handleChooseFont(safeFont)}>{safeFont}</div>
                ))
            )
        } else {
            return (
                brandFontArray.slice().sort((a,b) => a.key.localeCompare(b.key)).map((brandFont, index) => (
                    <div key={'brandFontDrop' + brandFont.key + index} style={{fontFamily: brandFont.value}} className="color-dropdown-container dropdown-item" onClick={() => handleChooseFont(brandFont)}>{brandFont.name}</div>
                ))
            )
        }
    }

    return (
        <div className='style-color-container'>
            <div style={displayFontStyle}>{style.input_identifier}</div>
            <div class="style-color-input-container">
                <div class="form-control-dropdown-container" role="button" aria-label="Text input with dropdown button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="form-control color-form-control style-dropdown-input font-dropdown-input form-control-dropdown">{dropdownType === "brandFont" ? valueValue : displayValue}</div>
                    <div className="dropdown-icon-container d-flex align-items-center">
                        <div className="dropdown-icon"></div>
                    </div>
                </div>
                <div className="dropdown-menu colors-menu">
                    {renderDropdown()}
                </div>
            </div>
        </div>
    )
}

export default StyleRowFontDropdown