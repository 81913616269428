import React, { useEffect, useState } from 'react';
import { checkQuery, fetchFolderId, getCheckQueryResult, getCheckQueryStatus, getFolderId, getFolderIdStatus, getRunQueryResult, runQuery, resetCheckQueryStatus, setActiveDeKey, setActiveQueryId, resetUpdateSegmentListings, resetActiveDe, resetDeRowCount, createDeDataCall, getCreateDeDataResults, getCreateQueryDataResult, createQueryDataCall } from '../../redux/segmentation/segmentationSlice';
import SegmentationService from '../../service/SegmentationService';
import { useDispatch, useSelector } from 'react-redux';
import { emailAnalyticsQueries } from './EmailAnalyticsQueries';
import EmailAnalyticsCreateSegmentModal from './EmailAnalyticsCreateSegmentModal';
import ExpressService from '../../service/ExpressService';
import Loading from '../bootstrap-components/Loading';
import { Tooltip } from 'react-tooltip';
import { getEid, getMid } from '../../redux/meSlice';
import './IndividualEmailAnalytics.scss';
import { resetQueryFlags, setSidenavLoadingActiveDeKey, setSidenavLoadingActiveQueryId } from '../../redux/sidenav-loading/sidenavLoadingSlice';

const IndividualEmailAnalytics = ({ emailObject }) => {

    const dispatch = useDispatch();
    const queryFolder = useSelector(getFolderId);
    const queryFolderStatus = useSelector(getFolderIdStatus);
    const [hasClickedCreateAnalyticsSegment, setHasClickedCreateAnalyticsSegment] = useState(false);
    const [newSegmentName, setNewSegmentName] = useState();
    const [segmentFolderId, setSegmentFolderId] = useState();
    const createDeData = useSelector(getCreateDeDataResults);
    const [analyticsQuery, setAnalyticsQuery] = useState();
    const [analyticsSegmentType, setAnalyticsSegmentType] = useState();
    const createQueryData = useSelector(getCreateQueryDataResult);
    const [apiError, setApiError] = useState();
    const [addRowToSegmentListingsCall, setAddRowToSegmentListingsCall] = useState();
    const runQueryResult = useSelector(getRunQueryResult);
    const checkQueryStatus = useSelector(getCheckQueryStatus);
    const checkQueryResult = useSelector(getCheckQueryResult);
    const [showCreateSegmentModal, setShowCreateSegmentModal] = useState(false);
    const [conversionDataRes, setConversionDataRes] = useState();
    const isSegmentRunning = checkQueryStatus === 'pending' || checkQueryResult?.isRunning === true ? true : false;
    const isSegmentRunningClass = isSegmentRunning ? 'email-analytics-not-allowed' : 'email-analytics-pointer';
    const isSegmentRunningToolTipMsg = isSegmentRunning ? 'Disabled - Segment Currently Running' : 'Create Segment';
    const eid = useSelector(getEid);
    const mid = useSelector(getMid);
    const [openedAnalyticsSegment, setOpenedAnalyticsSegment] = useState(null);

    const deliveredPercentage = (parseFloat((Number(emailObject.NumberDelivered) / Number(emailObject.NumberSent)) * 100).toFixed(2) + "%");
    const errorPercentage =  (parseFloat((Number(emailObject.NumberErrored) / Number(emailObject.NumberSent)) * 100).toFixed(2) + "%");
    const excludedPercentage = (parseFloat((Number(emailObject.NumberExcluded) / Number(emailObject.NumberSent)) * 100).toFixed(2) + "%");
    const softBouncePercentage = (parseFloat((Number(emailObject.SoftBounces) / Number(emailObject.NumberSent)) * 100).toFixed(2) + "%");
    const hardBouncePercentage = (parseFloat((Number(emailObject.HardBounces) / Number(emailObject.NumberSent)) * 100).toFixed(2) + "%");
    const otherBouncePercentage = (parseFloat((Number(emailObject.OtherBounces) / Number(emailObject.NumberSent)) * 100).toFixed(2) + "%");
    const unsubPercentage = (parseFloat((Number(emailObject.Unsubscribes) / Number(emailObject.NumberSent)) * 100).toFixed(2) + "%");


    useEffect(() => {
        if (emailObject.ID) {
            getConversionData();
        }
    },[emailObject.ID])

    useEffect(() => {
        if (!queryFolder && queryFolderStatus === 'idle') {
            dispatch(fetchFolderId());
        }
    },[queryFolderStatus, dispatch, queryFolder])

    useEffect(() => {
        if(hasClickedCreateAnalyticsSegment === true && analyticsQuery) {
            dispatch(setActiveDeKey(newSegmentName));
            // reset side nav loading flags
            dispatch(resetQueryFlags());
            dispatch(setSidenavLoadingActiveDeKey(newSegmentName));
            if(segmentFolderId === null || segmentFolderId === undefined) {
                getDeFolderId();
            }
        }
    },[hasClickedCreateAnalyticsSegment,analyticsQuery])

    useEffect(() => {
        if(typeof segmentFolderId === "number") {
            startCreateAnalyticsSegmentDe();
        }
    },[segmentFolderId])

    useEffect(() => {
        if (createDeData && createDeData.status === "OK") {
            startCreateQueryCall();
        } else {
            setApiError('There was an issue creating the Data Extension')
        }
    },[createDeData])

    useEffect(() => {
        if (createQueryData && createQueryData.status === "201-Created") {
            startAddRowToSegmentListings();
        } else {
            setApiError('There was an issue creating the query')
        }
    },[createQueryData])

    useEffect(() => {
        if (addRowToSegmentListingsCall && addRowToSegmentListingsCall.status === "200-OK") {
            dispatch(setSidenavLoadingActiveQueryId(createQueryData.data.queryDefinitionId));
            dispatch(setActiveQueryId(createQueryData.data.queryDefinitionId));
            dispatch(runQuery(createQueryData.data.queryDefinitionId));
        } else {
            setApiError('There was an issue with adding segment to Segment Listings')
        }
    },[addRowToSegmentListingsCall])

    useEffect(() => {
        if (runQueryResult && runQueryResult === "OK" && (hasClickedCreateAnalyticsSegment)) {
            runCheckQuery();
        }
    },[runQueryResult])

    useEffect(() => {
        if (newSegmentName && newSegmentName.length > 36) {
            let trimmedString = newSegmentName.substring(0, 36);
            setNewSegmentName(trimmedString);
        }
    },[newSegmentName])

    async function getDeFolderId() {
        const deFolderId = await SegmentationService.getSegmentDeFolderId();
        setSegmentFolderId(deFolderId);
    }

    async function getConversionData() {
        const url = "/rest/data/v1/customobjectdata/key/RevenuePerEmailDataView/rowset?$filter=Job_ID%20eq%20'" + emailObject.ID + "'"
        const conversionDataCall = await ExpressService.callSFMC(url, 'GET', 'DE');
        setConversionDataRes(conversionDataCall);
    }

    function resetQueryRun() {
        dispatch(resetUpdateSegmentListings());
        dispatch(resetDeRowCount());
        dispatch(resetActiveDe());
        dispatch(resetCheckQueryStatus());
        dispatch(setActiveQueryId(null));
    }

    async function startCreateAnalyticsSegmentDe() {
        dispatch(createDeDataCall({ segmentName: newSegmentName, segmentFolderId, segmentType: analyticsSegmentType }))
    }

    async function startCreateQueryCall() {
        dispatch(createQueryDataCall({segmentName: newSegmentName, qFolderName: queryFolder.toString(), query: analyticsQuery, deID: createDeData.dataExtensionID}))
    }

    async function startAddRowToSegmentListings() {
        const addRowData = await SegmentationService.addRowToSegmentListingsDe(newSegmentName, createQueryData.data.queryDefinitionId, analyticsQuery, 'no rulesArray', 'emailAnalytics');
        setAddRowToSegmentListingsCall(addRowData);
    }

    function clickedAnalyticsSegment(analyticsType, analyticName, analyticNumber) {
        setHasClickedCreateAnalyticsSegment(false);
        resetQueryRun();
        setAnalyticsSegmentType(analyticsType);
        let currentDate = new Date();
        let segmentKey = emailObject.EmailName + "_" + analyticsType + currentDate.getDate() + currentDate.getMonth() + currentDate.getFullYear() + currentDate.getHours() + currentDate.getMinutes() + currentDate.getSeconds();
        setNewSegmentName(segmentKey);
        setShowCreateSegmentModal(true);
        setOpenedAnalyticsSegment({ name: analyticName, value: analyticNumber });
    }

    function startCreateSegmentProcess() {
        setHasClickedCreateAnalyticsSegment(true);
        const isParentBu = eid === mid ? true : false;
        setAnalyticsQuery(emailAnalyticsQueries(analyticsSegmentType, emailObject.ID, isParentBu));
        if (segmentFolderId) {
            startCreateAnalyticsSegmentDe();
        }
    }

    function runCheckQuery() {
        dispatch(checkQuery(createQueryData.data.queryDefinitionId));
    }

    function closeModal() {
        setShowCreateSegmentModal(false);
    }

    function displayUniqueConversions() {
        return conversionDataRes && conversionDataRes[0]
            ? conversionDataRes[0].totalusers
            : conversionDataRes && !conversionDataRes[0]
                ? "0"
                : <div className='conversions-loading'><Loading /></div>
    }

    function displayConversionRate() {
        return conversionDataRes && conversionDataRes[0]
            ?  (parseFloat((Number(conversionDataRes[0].totalusers) / Number(emailObject.NumberSent)) * 100).toFixed(2) + "%")
            : conversionDataRes && !conversionDataRes[0]
                ? "0.00%"
                : <div className='conversions-loading'><Loading /></div>
    }

    function displayRevenue() {
        return conversionDataRes && conversionDataRes[0]
            ? "$" + conversionDataRes[0].totalrevenue
            : conversionDataRes && !conversionDataRes[0]
                ? "$0"
                : <div className='conversions-loading'><Loading /></div>
    }

    function displayRevenuePerPurchaser() {
        return conversionDataRes && conversionDataRes[0]
            ? "$" + conversionDataRes[0].revenueperpurchaser
            : conversionDataRes && !conversionDataRes[0]
                ? "$0"
                : <div className='conversions-loading'><Loading /></div>
    }

    return (
       <div className="individual-email-page" >
            { showCreateSegmentModal && <EmailAnalyticsCreateSegmentModal
                                            showCreateSegmentModal={showCreateSegmentModal}
                                            closeModal={closeModal}
                                            segmentName={newSegmentName}
                                            setNewSegmentName={setNewSegmentName}
                                            startCreateSegmentProcess={startCreateSegmentProcess}
                                            hasClickedCreateAnalyticsSegment={hasClickedCreateAnalyticsSegment}
                                            checkQueryResult={checkQueryResult}
                                            checkQueryStatus={checkQueryStatus}
                                            openedAnalyticsSegment={openedAnalyticsSegment}
                                        />}
            <div className='email-funnel-container'>
                <div className={'email-analytics-module email-analytics-blue email-sent-cell email-analytics-pointer ' + isSegmentRunningClass} onClick={() => !isSegmentRunning && clickedAnalyticsSegment('sent', 'Sent', emailObject.NumberSent)} data-tooltip-id="my-tooltip-email-analytics-sent" data-tooltip-content={isSegmentRunningToolTipMsg} data-tooltip-place="left">
                    <Tooltip id="my-tooltip-email-analytics-sent" />
                    <div className='email-sent-module-container'>
                        <div className='email-sent-module'>
                            <p className='email-module-text'>Sent</p>
                            <p className='email-module-number'>{emailObject.NumberSent}</p>
                        </div>
                    </div>
                </div>
                <div className='email-sent-down-arrow arrow-down'></div>
                <div className='email-not-delivered-arrow-container'>
                    <p className='email-not-delivered-text arrow-text'>Not Delivered</p>
                    <div className='email-not-delivered-half-box'></div>
                    <div className='email-not-delivered-arrow arrow-down'></div>
                </div>
                <div className={'email-analytics-module email-analytics-blue email-delivered-cell ' + isSegmentRunningClass} onClick={() => !isSegmentRunning && clickedAnalyticsSegment('delivered', 'Delivered', emailObject.NumberDelivered)} data-tooltip-id="my-tooltip-email-analytics-delivered" data-tooltip-content={isSegmentRunningToolTipMsg} data-tooltip-place="left">
                    <Tooltip id="my-tooltip-email-analytics-delivered" />
                    <div className='email-top-totals'>
                        <p className='email-module-text'>Delivered</p>
                        <p className='email-module-number'>{emailObject.NumberDelivered}</p>
                    </div>
                    <div className='email-bottom-percentages'>
                        <p className='email-module-text'>Delivery Rate</p>
                        <p className='email-module-number'>{deliveredPercentage}</p>
                    </div>
                </div>
                <div className='email-delivered-arrow-container'>
                    <p className='email-delivered-text arrow-text'>Delivered</p>
                    <div className='email-delivered-half-box'></div>
                    <div className='email-delivered-arrow arrow-down'></div>
                </div>
                <div className={'email-analytics-module email-analytics-red email-errors-cell ' + isSegmentRunningClass} onClick={() => !isSegmentRunning && clickedAnalyticsSegment('totalErrors', 'Total Errors', emailObject.NumberErrored)} data-tooltip-id="my-tooltip-email-analytics-totalErrors" data-tooltip-content={isSegmentRunningToolTipMsg} data-tooltip-place="left">
                    <Tooltip id="my-tooltip-email-analytics-totalErrors" />
                    <div className='email-top-totals'>
                        <p className='email-module-text'>Total Errors</p>
                        <p className='email-module-number'>{emailObject.NumberErrored}</p>
                    </div>
                    <div className='email-bottom-percentages'>
                        <p className='email-module-text'>Error Rate</p>
                        <p className='email-module-number'>{errorPercentage}</p>
                    </div>
                </div>
                <div className={'email-analytics-module email-analytics-red email-excluded-cell ' + isSegmentRunningClass} onClick={() => !isSegmentRunning && clickedAnalyticsSegment('totalExcluded', 'Total Excluded', emailObject.NumberExcluded)} data-tooltip-id="my-tooltip-email-analytics-totalExcluded" data-tooltip-content={isSegmentRunningToolTipMsg} data-tooltip-place="left">
                    <Tooltip id="my-tooltip-email-analytics-totalExcluded" />
                    <div className='email-top-totals'>
                        <p className='email-module-text'>Total Excluded</p>
                        <p className='email-module-number'>{emailObject.NumberExcluded}</p>
                    </div>
                    <div className='email-bottom-percentages'>
                        <p className='email-module-text'>Excluded Rate</p>
                        <p className='email-module-number'>{excludedPercentage}</p>
                    </div>
                </div>
                <div className='email-unsub-arrow-container'>
                    <p className='email-unsub-text arrow-text'>Unsubscribed</p>
                    <div className='email-unsub-half-box'></div>
                    <div className='email-unsub-arrow arrow-down'></div>
                </div>
                <div className={'email-analytics-module email-analytics-green email-opens-cell ' + isSegmentRunningClass} onClick={() => !isSegmentRunning && clickedAnalyticsSegment('open', 'Unique Opens', emailObject.UniqueOpens)} data-tooltip-id="my-tooltip-email-analytics-open" data-tooltip-content={isSegmentRunningToolTipMsg} data-tooltip-place="left">
                    <Tooltip id="my-tooltip-email-analytics-open" />
                    <div className='email-top-totals'>
                        <p className='email-module-text'>Unique Opens</p>
                        <p className='email-module-number'>{emailObject.UniqueOpens}</p>
                    </div>
                    <div className='email-bottom-percentages'>
                        <p className='email-module-text'>Open Rate</p>
                        <p className='email-module-number'>{emailObject.OpenRate}</p>
                    </div>
                </div>
                <div className={'email-analytics-module email-analytics-red email-total-bounce-cell ' + isSegmentRunningClass} onClick={() => !isSegmentRunning && clickedAnalyticsSegment('totalBounce', 'Total Bounces', emailObject.TotalBounces)} data-tooltip-id="my-tooltip-email-analytics-totalBounce" data-tooltip-content={isSegmentRunningToolTipMsg} data-tooltip-place="left">
                    <Tooltip id="my-tooltip-email-analytics-totalBounce" />
                    <div className='email-top-totals'>
                        <p className='email-module-text'>Total Bounces</p>
                        <p className='email-module-number'>{emailObject.TotalBounces}</p>
                    </div>
                    <div className='email-bottom-percentages'>
                        <p className='email-module-text'>Bounce Rate</p>
                        <p className='email-module-number'>{emailObject.BounceRate}</p>
                    </div>
                </div>
                <div className={'email-analytics-module email-analytics-red email-soft-bounce-cell ' + isSegmentRunningClass} onClick={() => !isSegmentRunning && clickedAnalyticsSegment('softBounce', 'Soft Bounces', emailObject.SoftBounces)} data-tooltip-id="my-tooltip-email-analytics-softBounce" data-tooltip-content={isSegmentRunningToolTipMsg} data-tooltip-place="left">
                    <Tooltip id="my-tooltip-email-analytics-softBounce" />
                    <div className='email-top-totals'>
                        <p className='email-module-text'>Soft Bounces</p>
                        <p className='email-module-number'>{emailObject.SoftBounces}</p>
                    </div>
                    <div className='email-bottom-percentages'>
                        <p className='email-module-text'>Soft Bounce Rate</p>
                        <p className='email-module-number'>{softBouncePercentage}</p>
                    </div>
                </div>
                <div className={'email-analytics-module email-analytics-red email-unsubs-cell ' + isSegmentRunningClass} onClick={() => !isSegmentRunning && clickedAnalyticsSegment('unsubscribe', 'Unsubscribes', emailObject.Unsubscribes)} data-tooltip-id="my-tooltip-email-analytics-unsubscribe" data-tooltip-content={isSegmentRunningToolTipMsg} data-tooltip-place="left">
                    <Tooltip id="my-tooltip-email-analytics-unsubscribe" />
                    <div className='email-top-totals'>
                        <p className='email-module-text'>Unsubscribes</p>
                        <p className='email-module-number'>{emailObject.Unsubscribes}</p>
                    </div>
                    <div className='email-bottom-percentages'>
                        <p className='email-module-text'>Unsubscribe Rate</p>
                        <p className='email-module-number'>{unsubPercentage}</p>
                    </div>
                </div>
                <div className='email-clicks-arrow-container'>
                    <div className='email-clicks-arrow-and-line-container'>
                        <div className='email-clicks-line'></div>
                        <div className='email-clicks-arrow arrow-down'></div>
                    </div>
                    <p className='email-clicks-text arrow-text'>Clicked</p>
                </div>
                <div className={'email-analytics-module email-analytics-red email-hard-bounce-cell ' + isSegmentRunningClass} onClick={() => !isSegmentRunning && clickedAnalyticsSegment('hardBounce', 'Hard Bounces', emailObject.HardBounces)} data-tooltip-id="my-tooltip-email-analytics-hardBounce" data-tooltip-content={isSegmentRunningToolTipMsg} data-tooltip-place="left">
                    <Tooltip id="my-tooltip-email-analytics-hardBounce" />
                    <div className='email-top-totals'>
                        <p className='email-module-text'>Hard Bounces</p>
                        <p className='email-module-number'>{emailObject.HardBounces}</p>
                    </div>
                    <div className='email-bottom-percentages'>
                        <p className='email-module-text'>Hard Bounce Rate</p>
                        <p className='email-module-number'>{hardBouncePercentage}</p>
                    </div>
                </div>
                <div className={'email-analytics-module email-analytics-red email-other-bounce-cell ' + isSegmentRunningClass} onClick={() => !isSegmentRunning && clickedAnalyticsSegment('otherBounce', 'Other Bounces', emailObject.OtherBounces)} data-tooltip-id="my-tooltip-email-analytics-otherBounce" data-tooltip-content={isSegmentRunningToolTipMsg} data-tooltip-place="left">
                    <Tooltip id="my-tooltip-email-analytics-otherBounce" />
                    <div className='email-top-totals'>
                        <p className='email-module-text'>Other Bounces</p>
                        <p className='email-module-number'>{emailObject.OtherBounces}</p>
                    </div>
                    <div className='email-bottom-percentages'>
                        <p className='email-module-text'>Other Bounce Rate</p>
                        <p className='email-module-number'>{otherBouncePercentage}</p>
                    </div>
                </div>
                <div className={'email-analytics-module email-analytics-green email-clicks-cell ' + isSegmentRunningClass} onClick={() => !isSegmentRunning && clickedAnalyticsSegment('click', 'Unique Clicks', emailObject.UniqueClicks)} data-tooltip-id="my-tooltip-email-analytics-click" data-tooltip-content={isSegmentRunningToolTipMsg} data-tooltip-place="left">
                    <Tooltip id="my-tooltip-email-analytics-click" />
                    <div className='email-top-totals'>
                        <p className='email-module-text'>Unique Clicks</p>
                        <p className='email-module-number'>{emailObject.UniqueClicks}</p>
                    </div>
                    <div className='email-bottom-percentages'>
                        <p className='email-module-text'>Click Rate</p>
                        <p className='email-module-number'>{emailObject.ClickRate}</p>
                    </div>
                </div>

                <div className='email-conversion-arrow-container'>
                    <div className='email-conversion-half-box'></div>
                    <div className='email-conversion-arrow arrow-down'></div>
                </div>
                <div className='email-conversion-arrow-container'>
                    <div className='email-conversions-line'></div>
                </div>
                <div className='email-conversion-arrow-container'>
                    <p className='email-converted-text arrow-text'>Converted</p>
                </div>

                <div className={'email-analytics-module email-analytics-green email-conversions-cell ' + isSegmentRunningClass} onClick={() => !isSegmentRunning && clickedAnalyticsSegment('conversions', 'Unique Conversions', displayUniqueConversions())} data-tooltip-id="my-tooltip-email-analytics-conversions" data-tooltip-content={isSegmentRunningToolTipMsg} data-tooltip-place="left">
                    <Tooltip id="my-tooltip-email-analytics-conversions" />
                    <div className='email-conversions-half-cell'>
                        <div className='email-top-totals'>
                            <p className='email-module-text'>Unique Conversions</p>
                            <p className='email-module-number'>{displayUniqueConversions()}</p>
                        </div>
                        <div className='email-bottom-percentages'>
                            <p className='email-module-text'>Conversion Rate</p>
                            <p className='email-module-number'>
                                {displayConversionRate()}
                            </p>
                        </div>
                    </div>
                    <div className='email-conversions-half-cell'>
                        <div className='email-top-totals'>
                            <p className='email-module-text'>Revenue Generated</p>
                            <p className='email-module-number'>{displayRevenue()}</p>
                        </div>
                        <div className='email-bottom-percentages'>
                            <p className='email-module-text'>Revenue / Purchaser</p>
                            <p className='email-module-number'>{displayRevenuePerPurchaser()}</p>
                        </div>
                    </div>
                </div>
            </div>

       </div>
    )
}

export default IndividualEmailAnalytics