import React, { useState, useEffect } from 'react';

const PhraseRow = ({onPhraseChange, phrase, setHasMadeChangeToPhraseGroup, suffix, showCategory}) => {

    const [phraseValue, setPhraseValue] = useState();
    const [phraseObject, setPhraseObject] = useState();
    const [hasMadeChange, setHasMadeChange] = useState(false);

    const textboxStyle = {
        padding: "12px 15px 0px"
    }

    useEffect(() => {
        setPhraseValue(phrase.value);
    }, [])

    useEffect(() => {
        if (phraseValue) {
            let phraseObjectSetter = {
                "key": phrase.key,
                "locale": phrase.locale,
                "email": phrase.email,
                "isactive": phrase.isactive,
                "name": phrase.name,
                "value": phraseValue,
                "type": phrase.type
            }
            setPhraseObject(phraseObjectSetter);
        }
    },[phraseValue])

    useEffect(() => {
        if (phraseObject?.value) {
            //Needed to stop onPhraseChange useEffect from executing on initial load, which flips the didPhrasesChange flag
            if (hasMadeChange) {
                onPhraseChange(phraseObject);
                setHasMadeChange(false);
                setHasMadeChangeToPhraseGroup && setHasMadeChangeToPhraseGroup(false);
            }
        }
    }, [phraseObject])


    return (
        <>
            {phraseObject
                &&
                    <div className='edit-phrases-row'>
                        <div className='style-name-text'>{phrase.locale}</div>
                        <textarea class="form-text-input form-control" style={phraseValue.length > 59 ? {padding: '12px 15px 10px'} : textboxStyle}
                            type="text" id={phrase.key + (suffix || "")} name={phrase.key + (suffix || "")} value={phraseValue.replaceAll('&#34;', '"')}
                            onChange={e => {
                                setHasMadeChange(true);
                                setHasMadeChangeToPhraseGroup && setHasMadeChangeToPhraseGroup(true);
                                setPhraseValue(e.target.value.replaceAll('"', '&#34;'));
                            }}
                            oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
                        />
                        {showCategory && <div className='style-phrases-category'>Phrases</div>}
                    </div>
            }
        </>
    )
}

export default PhraseRow