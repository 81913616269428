import React, { useEffect, useState } from "react";
import "./SubscriberDetails.scss";
import { useSelector } from "react-redux";
import spacetime from "spacetime";
import { Tooltip } from 'react-tooltip';
import { getCurrentSubscriber, getCurrentSubscriberEmailEngagement, getCurrentSubscriberRevenue } from "../../redux/subscriber-history/subscriberHistorySlice";

const SubscriberDetails = () => {

    const currentSubscriber = useSelector(getCurrentSubscriber);
    const currentSubscriberRevenue = useSelector(getCurrentSubscriberRevenue);
    const currentSubscriberEmailEngagement = useSelector(getCurrentSubscriberEmailEngagement);
    // lifetime revenue states
    const [lifetimeTotalOrderQty, setLifetimeTotalOrderQty] = useState('0');
    const [lifetimeTotalOrderAmt, setLifetimeTotalOrderAmt] = useState('$0.00');
    const [lifetimeAvgPerOrder, setLifetimeAvgPerOrder] = useState('$0.00');
    // email engagement states
    const [openRate, setOpenRate] = useState();
    const [clickRate, setClickRate] = useState();
    const [bounceRate, setBounceRate] = useState();

    const subscriberIconStyle = 'subscriber-color--' + currentSubscriber.Status;


    useEffect(() => {
        if (currentSubscriberRevenue && currentSubscriberRevenue.length > 0) {
            let lifetimeTotalOrderQty = currentSubscriberRevenue.length;
            let lifetimeTotalOrderAmt = currentSubscriberRevenue.reduce((total, currentObj) => total + parseFloat(currentObj.ordertotal), 0.00).toFixed(2);
            let lifetimeAvgPerOrder = (lifetimeTotalOrderAmt / lifetimeTotalOrderQty).toFixed(2);

            setLifetimeTotalOrderQty(lifetimeTotalOrderQty);
            setLifetimeTotalOrderAmt('$' + lifetimeTotalOrderAmt);
            setLifetimeAvgPerOrder('$' + lifetimeAvgPerOrder);
        }
    }, [currentSubscriberRevenue]);

    useEffect(() => {
        if (currentSubscriberEmailEngagement && currentSubscriberEmailEngagement.length > 0) {
            setOpenRate(currentSubscriberEmailEngagement[0].openrate && parseFloat(currentSubscriberEmailEngagement[0].openrate).toFixed(2) + '%');
            setClickRate(currentSubscriberEmailEngagement[0].clickrate && parseFloat(currentSubscriberEmailEngagement[0].clickrate).toFixed(2) + '%');
            setBounceRate(currentSubscriberEmailEngagement[0].bouncerate && parseFloat(currentSubscriberEmailEngagement[0].bouncerate).toFixed(2) + '%');
        }
    },[currentSubscriberEmailEngagement]);


    return (
        <div className="d-flex card-body-gap-lg flex-wrap">
            <div className="card subscriber-details">
                <div className="card-title">Subscriber Info</div>
                <div className="card-body subscribers-card-extra-padding">
                    <div className="d-flex justify-content-center subscriber-details-header">
                        <i className={`fa fa-user fa-2xl subscriber-card-icon ${subscriberIconStyle}`}></i>
                    </div>
                    <div className="d-flex flex-column subscriber-details-row-container">
                        <div className="d-flex flex-column subscriber-details-row">
                            <span className="subscriber-label">Subscriber Key</span>
                            <span className="subscriber-value">{currentSubscriber.SubscriberKey}</span>
                        </div>
                        <div className="d-flex flex-column subscriber-details-row">
                            <span className="subscriber-label">Email Address</span>
                            <span className="subscriber-value">{currentSubscriber.EmailAddress}</span>
                        </div>
                        <div className="d-flex flex-column subscriber-details-row">
                            <span className="subscriber-label">Subscriber Since</span>
                            <span className="subscriber-value">{spacetime(currentSubscriber.CreatedDate).format(`{month} {date-ordinal}, {year}`)}</span>
                        </div>
                        <div className="d-flex flex-column subscriber-details-row">
                            <span className="subscriber-label">Status</span>
                            <span className="subscriber-value">{currentSubscriber.Status}</span>
                        </div>
                    </div>

                </div>
            </div>
            <div className="card subscriber-details">
                <div className="card-title">Subscriber Email Engagement</div>
                <div className="card-body subscribers-card-extra-padding">
                    <div className="d-flex flex-column subscriber-details-row-container">
                        <div className="d-flex flex-column subscriber-details-row">
                            <span className="subscriber-label">Open Rate</span>
                            <span className="subscriber-value" data-tooltip-id="my-tooltip-openrate" data-tooltip-content="No Open data found for this Subscriber" data-tooltip-place="right">
                                {openRate || '-'}
                                {!openRate && <Tooltip id="my-tooltip-openrate"></Tooltip>}
                            </span>
                        </div>
                        <div className="d-flex flex-column subscriber-details-row">
                            <span className="subscriber-label">Click Rate</span>
                            <span className="subscriber-value" data-tooltip-id="my-tooltip-clickrate" data-tooltip-content="No Click data found for this Subscriber" data-tooltip-place="right">
                                {clickRate || '-'}
                                {!clickRate && <Tooltip id="my-tooltip-clickrate"></Tooltip>}
                            </span>
                        </div>
                        <div className="d-flex flex-column subscriber-details-row">
                            <span className="subscriber-label">Bounce Rate</span>
                            <span className="subscriber-value" data-tooltip-id="my-tooltip-bouncerate" data-tooltip-content="No Bouce data found for this Subscriber" data-tooltip-place="right">
                                {bounceRate || '-'}
                                {!bounceRate && <Tooltip id="my-tooltip-bouncerate"></Tooltip>}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card subscriber-details">
                <div className="card-title">Subscriber Purchase Data</div>
                <div className="card-body subscribers-card-extra-padding">
                    <div className="d-flex flex-column subscriber-details-row-container">
                        <div className="d-flex flex-column subscriber-details-row">
                            <span className="subscriber-label">Lifetime Orders Placed</span>
                            <span className="subscriber-value">{lifetimeTotalOrderQty}</span>
                        </div>
                        <div className="d-flex flex-column subscriber-details-row">
                            <span className="subscriber-label">Lifetime Orders Total</span>
                            <span className="subscriber-value">{lifetimeTotalOrderAmt}</span>
                        </div>
                        <div className="d-flex flex-column subscriber-details-row">
                            <span className="subscriber-label">Dollars Spent Per Order</span>
                            <span className="subscriber-value">{lifetimeAvgPerOrder}</span>
                        </div>
                        {/* <div className="d-flex flex-column subscriber-details-row">
                            <span className="subscriber-label">Orders Placed from Email Conversions</span>
                            <span className="subscriber-value">1</span>
                        </div>
                        <div className="d-flex flex-column subscriber-details-row">
                            <span className="subscriber-label">Dollars spent from Email Conversions</span>
                            <span className="subscriber-value">$149.99</span>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriberDetails;
