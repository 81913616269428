import React, { useEffect, useState } from 'react';
import ExpressService from '../../service/ExpressService';
import RVW_Logo from '../../RVW_Logo.png';
import { useSelector } from 'react-redux';
import { getMid } from '../../redux/meSlice';
import './Logo.scss';

const Logo = ({ isCollapsed, navigateToPath, urlPath }) => {

    const [companyLogo, setCompanyLogo] = useState(null);
    const mid = useSelector(getMid);

    useEffect(() => {
        async function getCompanyLogo() {
            const companyLogoGetCall = await ExpressService.callSFMC("/rest/asset/v1/content/assets/?$filter=CustomerKey%20eq%20'brand_logo_desktop_" + mid + "'&$fields=fileProperties", "GET", undefined, undefined, true);
            if (companyLogoGetCall?.data?.count > 0 && companyLogoGetCall.status == "200-OK") {
                setCompanyLogo(companyLogoGetCall.data.items[0].fileProperties.publishedURL)
            } else {
                setCompanyLogo("error")
            }
        }
        getCompanyLogo()
    },[mid])



    return (
        <div className='logo-container'>
            {
                companyLogo == "error"
                    ? <img src={RVW_Logo} alt="Red Van Workshop" className={`logo-img${isCollapsed ? " small-logo" : ''}`} />
                    : companyLogo
                        ?
                            <div className='new-logo-container'>
                                <div className='logo-div-new' onClick={(e) => navigateToPath(e, urlPath)}>
                                    <img src={companyLogo} alt="Company Logo" className={`logo-img${isCollapsed ? " small-logo" : ''}`} />
                                </div>
                                <i class="fa-solid fa-pen-to-square edit-icon-new" onClick={(e) => navigateToPath(e, urlPath)}></i>
                            </div>
                        :
                            <div className='no-logo-div'>
                            </div>
            }
        </div>
    )
}

export default Logo;