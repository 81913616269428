import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ExpressService from '../../service/ExpressService';

const initialState = {
    value: null,
    status: 'idle',
    error: null,
    automationSegmentsFolderIdValue: null,
    automationSegmentsFolderIdStatus: 'idle',
    automationSegmentsFolderIdError: null,
    segmentListingsPaginationValue: null,
    segmentListingsPaginationStatus: 'idle',
    segmentListingsPaginationError: null
};

export const fetchSegmentListings = createAsyncThunk('segmentListings/fetchSegmentListings', async (__, {rejectWithValue}) => {
    const result = await ExpressService.callSFMC('/rest/data/v1/customobjectdata/key/segment_listings/rowset/', 'GET', 'DE');

    if (result?.name?.includes('Error') && result.message) {
        return rejectWithValue(result.message)
    };

    return result;
});

export const fetchAutomationSegmentsFolderId = createAsyncThunk('segmentListings/fetchSegmentsFolderId', async (__, {rejectWithValue}) => {
    const result = await ExpressService.callSFMC('/rest/automation/v1/folders?$filter=categorytype%20eq%20automations', 'GET');

    if (result?.name?.includes('Error') && result.message) {
        return rejectWithValue(result.message)
    };

    const folderId = result.data.items.filter((item) => item.name === "Segments")[0].categoryId;

    return folderId;
})

export const fetchSegmentListingsPagination = createAsyncThunk('segmentListings/fetchSegmentListingsPagination', async (pageNumber, {rejectWithValue}) => {
    if (pageNumber && pageNumber > 0) {
        const result = await ExpressService.callSFMC('/rest/data/v1/customobjectdata/key/segment_listings/rowset?$pagesize=20&$page=' + pageNumber + '&$orderBy=CreatedDate desc', 'GET', 'pagination DE');

        if (result?.name?.includes('Error') && result.message) {
            return rejectWithValue(result.message)
        };

        return result;

    } else {
        return rejectWithValue('error')
    }
})

const segmentListingsSlice = createSlice({
    name: 'segmentListings',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchSegmentListings.pending]: (state, action) => {
            return {...state, status: 'pending'};
        },
        [fetchSegmentListings.fulfilled]: (state, action) => {
            return {...state, status: 'fulfilled', value: action.payload};
        },
        [fetchSegmentListings.rejected]: (state, action) => {
            return {...state, status: 'rejected',  value: action.payload};
        },
        [fetchAutomationSegmentsFolderId.pending]: (state, action) => {
            return {...state, automationSegmentsFolderIdStatus: 'pending'};
        },
        [fetchAutomationSegmentsFolderId.fulfilled]: (state, action) => {
            return {...state, automationSegmentsFolderIdStatus: 'fulfilled', automationSegmentsFolderIdValue: action.payload};
        },
        [fetchAutomationSegmentsFolderId.rejected]: (state, action) => {
            return {...state, automationSegmentsFolderIdStatus: 'rejected', automationSegmentsFolderIdValue: action.payload};
        },
        [fetchSegmentListingsPagination.pending]: (state, action) => {
            return {...state, segmentListingsPaginationStatus: 'pending'};
        },
        [fetchSegmentListingsPagination.fulfilled]: (state, action) => {
            return {...state, segmentListingsPaginationStatus: 'fulfilled', segmentListingsPaginationValue: action.payload};
        },
        [fetchSegmentListingsPagination.rejected]: (state, action) => {
            return {...state, segmentListingsPaginationStatus: 'rejected', segmentListingsPaginationValue: action.payload};
        }
    }
});

export const getSegmentListings = state => state?.segmentListings?.value;

export const getSegmentListingsStatus = state => state?.segmentListings?.status;

export const getAutomationSegmentsFolderId = state => state?.segmentListings?.automationSegmentsFolderIdValue;

export const getAutomationSegmentsFolderIdStatus = state => state?.segmentListings?.automationSegmentsFolderIdStatus;

export const getSegmentListingsPagination = state => state?.segmentListings?.segmentListingsPaginationValue;

export const getSegmentListingsPaginationStatus = state => state?.segmentListings?.segmentListingsPaginationStatus;

export default segmentListingsSlice.reducer;