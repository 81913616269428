import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCheckQueryResult } from '../../redux/segmentation/segmentationSlice';

const SegmentationTimer = ({timerRef}) => {
    const checkQueryIsRunning = useSelector(getCheckQueryResult);

    useEffect(() => {
        if (checkQueryIsRunning !== null && checkQueryIsRunning !== undefined && checkQueryIsRunning.isRunning === false) {
            clearTimeout(timerRef.current);
        }
    },[checkQueryIsRunning])

    return <></>
}

export default SegmentationTimer;