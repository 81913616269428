import Loading from '../bootstrap-components/Loading';
import ColorRow from './ColorRow';
import './EditColors.scss';

const EditColors = ({unsavedColorsChanges, colors, changeColors, duplicateColorList}) => {
    return (
        <div className='half-by-half colors-input-container'>
            {(!unsavedColorsChanges || typeof unsavedColorsChanges === 'undefined') ?
                <div className='colors-message'>
                    <Loading />
                </div>

            : (typeof colors === 'string' || colors instanceof String) ?
                <p className='colors-message'>{colors}</p>

            : unsavedColorsChanges.length < 1 ?
                <p className='colors-message'>No color data was found. This could be due to recently running an Autobahn install. If this issue persists, reach out to your Red Van Workshop rep.</p>

            : unsavedColorsChanges.slice().sort((a,b) => a.key.localeCompare(b.key)).map((color) => (
                <ColorRow key={color.key} onColorChange={changeColors} isDuplicateColor={duplicateColorList && duplicateColorList.length > 1 && duplicateColorList.some((cur)=>cur.key === color.key)} {...{color}} />
            ))}
        </div>
    )
}

export default EditColors;