import React, { useEffect, useState } from 'react';
import ExpressService from '../../../service/ExpressService';
import Loading from '../../bootstrap-components/Loading';
import '../../../styles/components/_rateHeatMap.scss';

const ClickRateHeatMap = ({setApiError, setHas401Error}) => {

    const [clickRateDE, setClickRateDE] = useState();
    const [clickRateHeatMapData, setClickRateHeatMapData] = useState();

    useEffect(() => {
        async function getClickRateData() {
            const clickRateData = await ExpressService.callSFMC("/rest/data/v1/customobjectdata/key/HeatMap_Clicks/rowset/", "GET", "DE", undefined, true);
            setClickRateDE(clickRateData);
            if (clickRateData?.name?.includes("Error") && clickRateData.message) {
                setApiError(clickRateData);
                clickRateData.message.includes("401") && setHas401Error(true);
                setClickRateHeatMapData(clickRateData);
            }
        }
        getClickRateData();
    },[])

    useEffect(() => {
        if (Array.isArray(clickRateDE) && clickRateDE?.length === 0) {
            setClickRateHeatMapData([]);
        } else if (Array.isArray(clickRateDE) && clickRateDE?.length > 0) {
            const clickRateValues = clickRateDE.filter(x => x.clickratebytime !== "").map(function(x) {
                return {...x, clickratebytimeNumber: Number(x.clickratebytime)}
            })
                .sort((x,y) => y.clickratebytimeNumber - x.clickratebytimeNumber)
                .map((z, index) => {
                    if (index <= 7) {
                        return {...z, hexcode: "#6ba1d7"}
                    } else if (index <= 14) {
                        return {...z, hexcode: "#7faedc"}
                    } else if (index <= 21) {
                        return {...z, hexcode: "#93bae2"}
                    } else if (index <= 28) {
                        return {...z, hexcode: "#a8c7e6"}
                    } else if (index <= 35) {
                        return {...z, hexcode: "#a7c7e7"}
                    } else if (index <= 42) {
                        return {...z, hexcode: "#bbd4ec"}
                    } else if (index <= 49) {
                        return {...z, hexcode: "#cfe1f2"}
                    } else if (index <= 56) {
                        return {...z, hexcode: "#e3edf7"}
                    } else if (index <= 63) {
                        return {...z, hexcode: "#f7fafd"}
                    } else {
                        return {...z, hexcode: "#f7fafd"}
                    }
                });
            const finalArray = [...clickRateValues, ...clickRateDE.filter(x => x.clickratebytime === "")]
            setClickRateHeatMapData(finalArray);
        }
    }, [clickRateDE])

    function getStyle(hexcode) {
        if (hexcode) {
            return {backgroundColor: hexcode}
        } else {
            return {backgroundColor: "transparent"}
        }
    }

    const getCells = () => {
        let cellArray = [];

        for (let i = 0; i < 24; i++) {
            const monday = clickRateHeatMapData.filter(x => Number(x.hour) === i && x.weekday === "Monday")[0];
            const tuesday = clickRateHeatMapData.filter(x => Number(x.hour) === i && x.weekday === "Tuesday")[0];
            const wednesday = clickRateHeatMapData.filter(x => Number(x.hour) === i && x.weekday === "Wednesday")[0];
            const thursday = clickRateHeatMapData.filter(x => Number(x.hour) === i && x.weekday === "Thursday")[0];
            const friday = clickRateHeatMapData.filter(x => Number(x.hour) === i && x.weekday === "Friday")[0];
            const saturday = clickRateHeatMapData.filter(x => Number(x.hour) === i && x.weekday === "Saturday")[0];
            const sunday = clickRateHeatMapData.filter(x => Number(x.hour) === i && x.weekday === "Sunday")[0];

            cellArray.push(<div className='heatmap-grid-cell' style={getStyle(monday.hexcode)}><div>{monday.clickratebytime ? ((Number(monday.clickratebytime)) * 100).toFixed(2) + '%' : "-"}</div></div>)
            cellArray.push(<div className='heatmap-grid-cell' style={getStyle(tuesday.hexcode)}><div>{tuesday.clickratebytime ? ((Number(tuesday.clickratebytime)) * 100).toFixed(2) + '%' : "-"}</div></div>)
            cellArray.push(<div className='heatmap-grid-cell' style={getStyle(wednesday.hexcode)}><div>{wednesday.clickratebytime ? ((Number(wednesday.clickratebytime)) * 100).toFixed(2) + '%' : "-"}</div></div>)
            cellArray.push(<div className='heatmap-grid-cell' style={getStyle(thursday.hexcode)}><div>{thursday.clickratebytime ? ((Number(thursday.clickratebytime)) * 100).toFixed(2) + '%' : "-"}</div></div>)
            cellArray.push(<div className='heatmap-grid-cell' style={getStyle(friday.hexcode)}><div>{friday.clickratebytime ? ((Number(friday.clickratebytime)) * 100).toFixed(2) + '%' : "-"}</div></div>)
            cellArray.push(<div className='heatmap-grid-cell' style={getStyle(saturday.hexcode)}><div>{saturday.clickratebytime ? ((Number(saturday.clickratebytime)) * 100).toFixed(2) + '%' : "-"}</div></div>)
            cellArray.push(<div className='heatmap-grid-cell' style={getStyle(sunday.hexcode)}><div>{sunday.clickratebytime ? ((Number(sunday.clickratebytime)) * 100).toFixed(2) + '%' : "-"}</div></div>)
        }

        return cellArray;
    }

    return (
        <div className='card-body'>
            {
                clickRateHeatMapData?.message?.includes("401")
                    ? <></>
                    : <h5 className='graph-title'>Click Rate by Send Time</h5>
            }

            {
                clickRateHeatMapData === undefined
                    ? <div className='graph-loader'><Loading /></div>
                    : clickRateHeatMapData.length > 0
                        ?
                            <div className='full-grid-container'>
                                <div className='weekday-grid-x'>
                                    <div></div>
                                    <div className='weekday-header'>Monday</div>
                                    <div className='weekday-header'>Tuesday</div>
                                    <div className='weekday-header'>Wednesday</div>
                                    <div className='weekday-header'>Thursday</div>
                                    <div className='weekday-header'>Friday</div>
                                    <div className='weekday-header'>Saturday</div>
                                    <div className='weekday-header'>Sunday</div>
                                </div>
                                <div className='bottom-grid-container'>
                                    <div className='time-grid-y'>
                                        <div className='time-y-row'><div>12AM</div></div>
                                        <div className='time-y-row'><div>1AM</div></div>
                                        <div className='time-y-row'><div>2AM</div></div>
                                        <div className='time-y-row'><div>3AM</div></div>
                                        <div className='time-y-row'><div>4AM</div></div>
                                        <div className='time-y-row'><div>5AM</div></div>
                                        <div className='time-y-row'><div>6AM</div></div>
                                        <div className='time-y-row'><div>7AM</div></div>
                                        <div className='time-y-row'><div>8AM</div></div>
                                        <div className='time-y-row'><div>9AM</div></div>
                                        <div className='time-y-row'><div>10AM</div></div>
                                        <div className='time-y-row'><div>11AM</div></div>
                                        <div className='time-y-row'><div>12PM</div></div>
                                        <div className='time-y-row'><div>1PM</div></div>
                                        <div className='time-y-row'><div>2PM</div></div>
                                        <div className='time-y-row'><div>3PM</div></div>
                                        <div className='time-y-row'><div>4PM</div></div>
                                        <div className='time-y-row'><div>5PM</div></div>
                                        <div className='time-y-row'><div>6PM</div></div>
                                        <div className='time-y-row'><div>7PM</div></div>
                                        <div className='time-y-row'><div>8PM</div></div>
                                        <div className='time-y-row'><div>9PM</div></div>
                                        <div className='time-y-row'><div>10PM</div></div>
                                        <div className='time-y-row'><div>11PM</div></div>
                                    </div>
                                    <div className='day-time-grid'>
                                        {
                                            clickRateHeatMapData ? getCells() : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        : clickRateHeatMapData.length === 0
                            ?
                                <div style={{marginTop: "10px"}}>
                                    No data was found in your "HeatMap_Clicks" Data Extension. This could be a result of recently running an Autobahn Install. Check back soon. If this issue persists reach out to your Red Van Workshop rep.
                                </div>
                            : clickRateHeatMapData?.message?.includes("404")
                                ?
                                    <div style={{marginTop: "10px"}}>
                                        The Data Extension was not found.
                                    </div>
                                : <></>
            }

        </div>
    )

}

export default ClickRateHeatMap