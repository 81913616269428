import { useState } from "react";
import { useSelector } from "react-redux";
import SegmentationLoading from "../segmentation/SegmentationLoading";
import './EmailAnalyticsCreateSegmentModal.scss';
import { getRunQueryStatus } from "../../redux/segmentation/segmentationSlice";

const EmailAnalyticsCreateSegmentModal = ({closeModal, segmentName, setNewSegmentName, startCreateSegmentProcess, hasClickedCreateAnalyticsSegment, checkQueryResult, checkQueryStatus, openedAnalyticsSegment}) => {

    const isEmailAnalyticsSegment = true;

    const [currentTimer, setCurrentTimer] = useState();
    const [showSpecialCharMsg, setShowSpecialCharMsg] = useState();

    const runQueryStatus = useSelector(getRunQueryStatus);

    function handleSegmentNameChange(inputValue) {
        if(inputValue.charAt(0) === "_" || inputValue.charAt(0) === "-") {
            setNewSegmentName(inputValue.substring(1).replace(/[,<\.>/\?'":;\|\\\}\]\{\[\+=\)\(\*&\^%\$#@!~`$]/g, ""));
        } else {
            setNewSegmentName(inputValue.replace(/[,<\.>/\?'":;\|\\\}\]\{\[\+=\)\(\*&\^%\$#@!~`$]/g, ""));
        }
        if(inputValue.match(/[,<\.>/\?'":;\|\\\}\]\{\[\+=\)\(\*&\^%\$#@!~`$]/g)) {
            if(currentTimer) {
                setShowSpecialCharMsg(false);
                clearTimeout(currentTimer);
            }
            setShowSpecialCharMsg(true);
            let thisCurrentTimer = setTimeout(() => {setShowSpecialCharMsg(false)}, 10000)
            setCurrentTimer(thisCurrentTimer);
        }
    }

    return(
        <>
            <div className="modal-backdrop" onClick={() => closeModal()}></div>
            <div className="segment-status-popup sticky-container">
                <div className='card-body card-popup sticky-content' >
                {
                    !hasClickedCreateAnalyticsSegment
                        ?
                            <>
                                <h3 className="card-title">Create New Segment: {openedAnalyticsSegment?.name} ( {openedAnalyticsSegment?.value} )</h3>
                                <div className="expand-row-controls-group">
                                    <input className="form-text-input form-control email-segment-pop-up-input" type="text" id="segment-name-input" name="segment-name-input" maxlength="36" value={segmentName} onChange={e => {handleSegmentNameChange(e.target.value)}} />
                                    {showSpecialCharMsg && <div className='special-char-message align-self-start'>Special characters not allowed</div>}
                                    <div className='expand-row-controls'>
                                        <button className='btn-primary' onClick={() => startCreateSegmentProcess()}>Create</button>
                                        <button className='btn-secondary' onClick={() => closeModal()}>Cancel</button>
                                    </div>
                                </div>
                            </>
                        :
                            <>
                                <SegmentationLoading {...{segmentName, checkQueryResult, checkQueryStatus, runQueryStatus, isEmailAnalyticsSegment}}/>
                            </>
                }
                </div>
            </div>
        </>
    )
}

export default EmailAnalyticsCreateSegmentModal;