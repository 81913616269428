import { useEffect, useState } from 'react';
import Loading from '../bootstrap-components/Loading';
import { getCheckQueryStatus, getCheckQueryResult, getActiveDeKey, getSegmentListingsPutResult, resetUpdateSegmentListings, updateSegmentListingsDE, fetchDeRowCount, getDeRowCountResults, checkQuery, getActiveQueryId } from '../../redux/segmentation/segmentationSlice';
import { useDispatch, useSelector } from 'react-redux';
import './SidenavLoading.scss';
import "../../styles/_common.scss";
import { Tooltip } from 'react-tooltip';
import { getShowSidenavLoading, getSidenavLoadingActiveDeKey, getSidenavLoadingActiveQueryId, setShowSidenavLoading, setIsQueryComplete, getIsQueryComplete } from '../../redux/sidenav-loading/sidenavLoadingSlice';


const SidenavLoading = () => {

    const dispatch = useDispatch();
    const checkQueryStatus = useSelector(getCheckQueryStatus);
    const isCheckQueryStatusPending = checkQueryStatus === 'pending';
    const isCheckQueryStatusFulfilled = checkQueryStatus === 'fulfilled';
    const isCheckQueryStatusErrored = checkQueryStatus === 'rejected';
    const checkQueryResult = useSelector(getCheckQueryResult);
    const updateSegmentListingsResult = useSelector(getSegmentListingsPutResult);
    const dERowCount = useSelector(getDeRowCountResults);

    const isShowSidenavLoading = useSelector(getShowSidenavLoading);
    const isQueryComplete = useSelector(getIsQueryComplete);
    const activeDeKeyRunningSegment = useSelector(getSidenavLoadingActiveDeKey)
    const activeQueryIdRunningSegment = useSelector(getSidenavLoadingActiveQueryId);
    const [resetId, setResetId] = useState();

    const queryIsRunning = (isCheckQueryStatusPending || (isCheckQueryStatusFulfilled && checkQueryResult?.isRunning === true));
    const queryErrored = isCheckQueryStatusErrored;
    const queryComplete = isQueryComplete || (isCheckQueryStatusFulfilled && checkQueryResult?.isRunning === false) || (queryErrored);
    const loadingContent = "Segment '" + activeDeKeyRunningSegment + (queryComplete ? "' Complete" : queryIsRunning ? "' Loading" : null);
    const errorContent = "Process Failed";
    const errorDetail = "Something went wrong with segment '" + activeDeKeyRunningSegment + "'. Please try again";
    const loadingMessageClass = "sidenav-loading-message" + (queryComplete ? " text--success" : queryIsRunning ? " text--brand-primary" : null);

    const reset = () => {
        dispatch(setShowSidenavLoading(false));
        dispatch(setIsQueryComplete(null));
    }

    useEffect(() => {
        if (isShowSidenavLoading && checkQueryResult && checkQueryResult.isRunning === true) {
            setTimeout(() => runCheckQuery(), 5000);
        }
    },[isShowSidenavLoading,checkQueryResult])

    useEffect(() => {
        if (isCheckQueryStatusPending) {
            clearTimeout(resetId);
            dispatch(setShowSidenavLoading(true));
        } else if (!updateSegmentListingsResult && isCheckQueryStatusFulfilled && checkQueryResult?.isRunning === false) {
            // runGetRowCount();
        } else if ((updateSegmentListingsResult && (isCheckQueryStatusFulfilled && checkQueryResult?.isRunning === false)) || isCheckQueryStatusErrored) {
            dispatch(resetUpdateSegmentListings());
            setResetId(setTimeout(() => reset(), 20000));

            if (isCheckQueryStatusErrored) {
                dispatch(setIsQueryComplete(true));
            }
        }
    }, [isCheckQueryStatusPending, isCheckQueryStatusFulfilled, isCheckQueryStatusErrored, updateSegmentListingsResult]);

    useEffect(() => {
        if (checkQueryResult?.isRunning === false) {
            runGetRowCount();
        }
    },[isCheckQueryStatusFulfilled])

    function runCheckQuery() {
        dispatch(checkQuery(activeQueryIdRunningSegment));
    }

    async function runGetRowCount() {
        dispatch(fetchDeRowCount(activeDeKeyRunningSegment));
    }

    async function updateSegmentListings() {
        const updateItems = [
            {
                "keys": {
                    "QueryID": checkQueryResult.queryDefinitionId
                },
                "values": {
                    "SegmentCount": dERowCount,
                    "Status": "done",
                    "modifiedDate": new Date().toISOString()
                }
            }
        ]
        await dispatch(updateSegmentListingsDE(updateItems))
    }

    useEffect(() => {
        if(dERowCount !== null && dERowCount !== undefined && isCheckQueryStatusFulfilled && checkQueryResult?.isRunning === false) {
            updateSegmentListings();
        }
    },[dERowCount])


    return (
        (isShowSidenavLoading && (queryIsRunning || queryComplete)) && (
            <div className="sidenav-loading-container">
                <div className="sidenav-loading-row-container d-flex align-items-center" key={"segment-loading-" + activeDeKeyRunningSegment} data-tooltip-id={"my-tooltip-segment-loading-" + activeDeKeyRunningSegment} data-tooltip-content={queryIsRunning || (queryComplete && !queryErrored) ? loadingContent : errorDetail} data-tooltip-place="left">
                    <div className="fa-loading-container">
                        {
                            queryIsRunning
                                ? <Loading loadingColor={'brandPrimary'} />
                                : queryComplete && !queryErrored
                                    ? <i class="fa-solid fa-check title-check-mark" />
                                    : queryErrored ? <i className='fa-solid fa-circle-exclamation text--danger'></i> : <></>
                        }
                    </div>
                    <div className={queryIsRunning || (queryComplete && !queryErrored) ? loadingMessageClass : queryErrored ? "sidenav-loading-message text--danger" : ""}>
                        { queryIsRunning || (queryComplete && !queryErrored) ? loadingContent : queryErrored ? errorContent : null }
                    </div>
                    { queryComplete && queryErrored ? <a className='sidenav-loading-message text-decoration-underline text--brand-primary w-auto' href='#'>Details</a> : <></>}
                    <div className="sidenav-loading-tooltip"><Tooltip id={"my-tooltip-segment-loading-" + activeDeKeyRunningSegment} /></div>
                </div>
            </div>
        )
    )

}

export default SidenavLoading;