import Navigation from './components/layout/Navigation';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/login/Login';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Protected from './components/protected/Protected';
import ProtectedInstall from './components/protected/ProtectedInstall';
import Modal from './components/bootstrap-components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMe, getMeStatus, getIsLoggedIn, getUserExpTime, refreshMe, getMid } from './redux/meSlice';
import Overview from './components/overview-page/Overview';
import SfmcInstall from './components/sfmc-install/SfmcInstall';
import ExpressService from './service/ExpressService';
import Loading from './components/bootstrap-components/Loading';
import TriggeredSends from './components/triggered-sends/TriggeredSends';
import IndividualEmailAnalytics from './components/campaigns/IndividualEmailAnalytics';
import SegmentListings from './components/segmentation/SegmentListings';
import SegmentationTimer from './components/segmentation/SegmentationTimer';
import Segmentation from './components/segmentation/Segmentation';
import UploadLogo from './components/upload-logo/UploadLogo';
import ReactGA from "react-ga4";
import BrandPage from './components/brand/BrandPage';
import Campaigns from './components/campaigns/Campaigns';
import EditConfigs from './components/edit-config/EditConfigs';
import Alerts from './components/bootstrap-components/Alerts';
import SubscriberHistoryPage from './components/subscriber-history/SubscriberHistoryPage';

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const meMid = useSelector(getMid);
  const meStatus = useSelector(getMeStatus);
  const userExpTime = useSelector(getUserExpTime);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [hasShownTimeoutModal, setHasShownTimeoutModal] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const localStorageItems = {
    mcAuto_Timer: 'mcAuto_Timer',
    mcAuto_APICallInWarningPeriod: 'mcAuto_APICallInWarningPeriod',
    is_installed: 'is_installed'
  }
  const [isInstalled, setIsInstalled] = useState();
  const [currentTimer, setCurrentTimer] = useState(localStorage.getItem(localStorageItems.mcAuto_Timer) ? parseInt(localStorage.getItem(localStorageItems.mcAuto_Timer)) : 0);
  const modalText = {
    modalHeader: 'Upcoming Session Time Out',
    modalBody: 'Your session will end in 5 minutes. Clicking Continue to extend your session. Clicking close will resume your current session, but it will time out after 5 minutes.',
    modalClose: 'Close',
    modalPrimary: 'Continue'
  };
  const { modalHeader, modalBody, modalClose, modalPrimary } = modalText;
  const timeCheckpoints = {
    logoutWarning: 900,
    logout: 1200
  }

  const closeModalAction = () => {setShowTimeoutModal(false)};
  const continueAction = () => {
    dispatch(refreshMe());
    closeModalAction();
    resetFlags();
  };

  const startTimer = useCallback(
    () => {
      const interval = setInterval(() => {
        setCurrentTimer(seconds => {
          const addedTime = seconds + 5;
          localStorage.setItem(localStorageItems.mcAuto_Timer, addedTime);
          return addedTime;
        });
      }, 5000);
      return () => clearInterval(interval);
    }, [localStorageItems.mcAuto_Timer]
  );

  const resetFlags = useCallback(
    () => {
      setCurrentTimer(0);
      setHasShownTimeoutModal(false);
      localStorage.setItem(localStorageItems.mcAuto_Timer, 0);
      localStorage.setItem(localStorageItems.mcAuto_APICallInWarningPeriod, false);
    }, [localStorageItems.mcAuto_APICallInWarningPeriod, localStorageItems.mcAuto_Timer]
  )

  useEffect(() => {
    setGoogleAnalyticsEnvironment();
  },[])

  async function setGoogleAnalyticsEnvironment(){
    const envCall = await fetch('/version');
    const envData = await envCall.json();
    if (envData.environment === "production") {
      ReactGA.initialize("G-152MDZR6CV", {alwaysSendToDefaultTracker: false});
    } else {
      ReactGA.initialize("G-JWP7NZ4QPL", {alwaysSendToDefaultTracker: false});
    }
  }

  function isLocalStorageMidInstalled() {
    const localIsInstalledArray = localStorage.getItem(localStorageItems.is_installed) ? JSON.parse(localStorage.getItem(localStorageItems.is_installed)) : null;
    if (localIsInstalledArray) {
      const installedMidCheck = localIsInstalledArray.findIndex(_element => _element === meMid);
      return installedMidCheck > -1;
    } else {
      return false;
    }
  }

  async function checkConfigData() {
    const rvwConfigDEdata =  await ExpressService.callSFMC('/rest/data/v1/customobjectdata/key/rvw_conf/rowset/', 'GET', 'DE');
    if (rvwConfigDEdata && Array.isArray(rvwConfigDEdata) && rvwConfigDEdata.length > 0) {
      const isInstalledData = rvwConfigDEdata.filter(item => item.key === 'is_installed')[0].value;
      if (isInstalledData === 'installed') {
        // when config DE is present and DE is_installed value is 'installed'
        setIsInstalled(true);
        const localStorageInstalledArray = JSON.parse(localStorage.getItem(localStorageItems.is_installed)) ? JSON.parse(localStorage.getItem(localStorageItems.is_installed)) : [];
        localStorage.setItem(localStorageItems.is_installed, JSON.stringify([...localStorageInstalledArray, meMid]));
      } else {
        // when config DE is present but is_installed is not 'installed'
        setIsInstalled(false);
      }
    } else {
      // when config DE is NOT present
      setIsInstalled(false);
    }
  }

  useEffect(() => {
    if (!isLoggedIn && meStatus === 'idle') {
      dispatch(fetchMe());
    }
  }, [dispatch, isLoggedIn, meStatus])

  useEffect(() => {
    if (isLoggedIn && isInstalled === undefined) {
      if (isLocalStorageMidInstalled()) {
        // when is_installed for the session MID matches local storage
        setIsInstalled(true);
      } else {
        // when is_installed for the session MID is not in local storage or does not match any in local storage array
        // we need to check SFMC for is_installed value in config DE.
        checkConfigData();
      }
    }
  },[isInstalled, isLoggedIn])

  useEffect(() => {
    if (isLoggedIn && !timerStarted && (!currentTimer || (currentTimer && parseInt(currentTimer) < timeCheckpoints.logout))) {
      setTimerStarted(true);
      startTimer();
    }
  }, [currentTimer, isLoggedIn, startTimer, timeCheckpoints.logout, timerStarted]);

  useEffect(() => {
    if (isLoggedIn) {
      const logout = () => {
        window.location.href='/logout';
        resetFlags();
      };

      const userExpTimeNumber = userExpTime && Number(userExpTime);

      if (userExpTimeNumber) {
        const currentDateTime = new Date();
        const userExpTimeCovertedTime = new Date(userExpTimeNumber * 1000);

        // Check to see if the current time is still before the expire time
        if(userExpTimeCovertedTime.getTime() > currentDateTime.getTime()){

          if (currentTimer >= timeCheckpoints.logoutWarning && !hasShownTimeoutModal) {
            // If client has made an API before the timeout warning period, refresh tokens
            if (localStorage.getItem(localStorageItems.mcAuto_APICallInWarningPeriod) === 'true') {
              resetFlags();
              dispatch(refreshMe());
            } else {
            // If there has been no API calls within the timeout warning period, show timeout modal
              setShowTimeoutModal(true);
              setHasShownTimeoutModal(true);
            }
          } else if (currentTimer >= timeCheckpoints.logout) {
            logout();
          }
        } else {
          logout();
        }
      } else {
        logout();
      }
    }
  }, [currentTimer, dispatch, hasShownTimeoutModal, isLoggedIn, localStorageItems.mcAuto_APICallInWarningPeriod, resetFlags, showTimeoutModal, timeCheckpoints.logout, timeCheckpoints.logoutWarning, userExpTime]);

  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [pathToNavigateTo, setPathToNavigateTo] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const timerRef = useRef(null);

  return (
    <BrowserRouter>
      <div className="App-Container">
        <Navigation {...{isLoggedIn, isInstalled, setShowUnsavedChangesModal, setPathToNavigateTo, hasChanges}} />
        <div className="App">
          <Modal showModal={showTimeoutModal} secondaryAction={() => closeModalAction()} primaryAction={() => continueAction()}
            {...{modalHeader, modalBody, modalClose, modalPrimary}}/>
          <SegmentationTimer {...{ timerRef }} />
          <Alerts />
          <Routes>
            <Route path='/' element={
              isLoggedIn === undefined || (isLoggedIn && isInstalled === undefined) ? (<div style={{marginTop: "48px"}}><Loading /></div>)
                : isLoggedIn && isInstalled ? (<Navigate to="/overview" replace />)
                  : isLoggedIn === false && isInstalled === undefined ? (<Login {...{isLoggedIn, resetFlags}} />)
                      : isLoggedIn && isInstalled === false ? (<Navigate to="/install" replace />)
                        : (<Login {...{isLoggedIn, resetFlags}} />)
            } />
            <Route path='/overview'
              element={
                <Protected {...{isLoggedIn}}>
                  <ProtectedInstall {...{isInstalled}} >
                    <Overview {...{resetFlags}}/>
                  </ProtectedInstall>
                </Protected>
              }
            />
            <Route path='/brand'
              element={
                <Protected {...{isLoggedIn}}>
                  <ProtectedInstall {...{isInstalled}}>
                    <BrandPage {...{showUnsavedChangesModal, setShowUnsavedChangesModal, pathToNavigateTo, hasChanges, setHasChanges, resetFlags}}/>
                  </ProtectedInstall>
                </Protected>
              }
            />
            <Route path='/install'
              element={
                <Protected {...{isLoggedIn}}>
                  <SfmcInstall />
                </Protected>
              }
            />
            <Route path='/config'
              element={
                <Protected {...{isLoggedIn}}>
                  <ProtectedInstall {...{isInstalled}}>
                    <EditConfigs {...{showUnsavedChangesModal, setShowUnsavedChangesModal, pathToNavigateTo, setHasChanges, resetFlags}}/>
                  </ProtectedInstall>
                </Protected>
              }
            />
            <Route path='/triggers'
              element={
                <Protected {...{isLoggedIn}}>
                  <ProtectedInstall {...{isInstalled}}>
                    <TriggeredSends {...{showUnsavedChangesModal, setShowUnsavedChangesModal, pathToNavigateTo, setHasChanges, resetFlags}}/>
                  </ProtectedInstall>
                </Protected>
              }
            />
            <Route path='/campaigns'
              element={
                <Protected {...{isLoggedIn}}>
                  <ProtectedInstall {...{isInstalled}}>
                    <Campaigns {...{resetFlags}}/>
                  </ProtectedInstall>
                </Protected>
              }
            />
            <Route path='/email'
              element={
                <Protected {...{isLoggedIn}}>
                  <ProtectedInstall {...{isInstalled}}>
                    <IndividualEmailAnalytics />
                  </ProtectedInstall>
                </Protected>
              }
            />
            <Route path='/segmentlistings'
              element={
                <Protected {...{isLoggedIn}}>
                  <ProtectedInstall {...{isInstalled}}>
                    <SegmentListings {...{timerRef}}/>
                  </ProtectedInstall>
                </Protected>
              }
            />
            <Route path='/segmentation'
              element={
                <Protected {...{isLoggedIn}}>
                  <ProtectedInstall {...{isInstalled}}>
                    <Segmentation {...{timerRef}}/>
                  </ProtectedInstall>
                </Protected>
              }
            />
            <Route path='/subscribers'
              element={
                <Protected {...{isLoggedIn}}>
                  <ProtectedInstall {...{isInstalled}}>
                    <SubscriberHistoryPage {...{timerRef}}/>
                  </ProtectedInstall>
                </Protected>
              }
            />
            <Route path='/uploadlogo'
              element={
                <Protected {...{isLoggedIn}}>
                  <ProtectedInstall {...{isInstalled}}>
                    <UploadLogo />
                  </ProtectedInstall>
                </Protected>
              }
            />
            <Route path='/logout'
              element={
                <Protected {...{isLoggedIn}}>
                  <Login {...{isLoggedIn, resetFlags}} />
                </Protected>
              }
            />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
