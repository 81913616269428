import React, { useState, useEffect } from 'react';
import PhraseGroup from './PhraseGroup';

const EditPhrases = ({onPhrasesChange, phrases}) => {
    
    const [phrasesGrouped, setPhrasesGrouped] = useState([]);
    const [unsavedPhrasesChanges, setUnsavedPhrasesChanges] = useState([]);

    const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {});

    useEffect(() => {
        if (phrases) {
            const getGroupByKey = groupByKey(phrases, "key")
            var phrasesArray = Object.values(getGroupByKey);
            setPhrasesGrouped(phrasesArray);
        }
    }, [])

    useEffect(() => {
        if(unsavedPhrasesChanges && unsavedPhrasesChanges.length > 0) {
            const getGroupByKey = groupByKey(unsavedPhrasesChanges, "key")
            var phrasesArray = Object.values(getGroupByKey);
            setPhrasesGrouped(phrasesArray);
            onPhrasesChange(unsavedPhrasesChanges);
        }
    }, [unsavedPhrasesChanges])

    function updatePhrases(phraseObject) {
        if (phraseObject && phrases) {
            if (phrases.findIndex(_element => _element.key === phraseObject.key) > -1) {
                let phrasesTemp = unsavedPhrasesChanges.length > 0 ? unsavedPhrasesChanges : phrases;
                const newArr = phrasesTemp.map(obj => {
                    if (obj.key === phraseObject.key && obj.locale === phraseObject.locale) {
                        return {...obj, value: phraseObject.value};
                    }
                    return obj;
                })
                setUnsavedPhrasesChanges(newArr);
            }
        }
    }

    return (
        <div>
            <div class="accordion accordion-flush" id="accordionFlushExample">
                {(() => {
                    if (!phrasesGrouped || typeof phrasesGrouped === 'undefined') {
                        return(
                            null
                        )
                    } else if (phrasesGrouped.length < 1) {
                        return(
                            <p style={{padding: "12px"}} className='colors-message'>No phrases data was found. This could be due to recently running an Autobahn install. If this issue persists, reach out to your Red Van Workshop rep.</p>
                        )
                    } else if (typeof phrasesGrouped === 'string' || phrasesGrouped instanceof String) {
                        return (
                            null
                        )
                    } else {
                        return(
                                phrasesGrouped.sort((a,b) => a[0].name.localeCompare(b[0].name)).map((phraseGroup, index) => {
                                    return (
                                        <PhraseGroup key={index} phrases={phraseGroup} phraseIndex={index} onPhraseChange={updatePhrases} />
                                    )
                                })
                        )
                    }
                })()}
            </div>
        </div>
    )
}


export default EditPhrases