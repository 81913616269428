import React, { useEffect, useRef, useState } from 'react';
import SegmentFieldModal from './SegmentFieldModal';
import './SegmentationRule.scss';

const SegmentationRule = ({rule, onRuleChange, fields, deleteRule, initRule, hasChanges}) => {

    const [userInput, setUserInput] = useState(rule.userInput);
    const [currentField, setCurrentField] = useState(rule.field);
    const [currentOperator, setCurrentOperator] = useState(rule.selectedOperator);
    const hideUserInput = currentField?.usereditortype === "" || currentOperator?.operator === "IS NULL" || currentOperator?.operator === "IS NOT NULL";
    const [showSegmentFieldsModal, setShowSegmentFieldsModal] = useState(false);
    const isInitialiLoading = useRef(true);

    useEffect(() => {
        if (initRule) {
            if (!hasChanges && (userInput !== initRule.userInput || currentField.field !== initRule.field.field || currentOperator !== initRule.selectedOperator)) {
                setUserInput(rule.userInput);
                setCurrentField(rule.field);
                setCurrentOperator(rule.selectedOperator);
            }
        }
    }, [rule]);

    useEffect(() => {
        let tempUserInput = null;

        if(currentField && currentOperator && (hideUserInput || userInput)) {
            if (currentOperator.userDisplay === "contains") {
                tempUserInput = "\'%" + userInput + "%\'";
            } else if (currentOperator.userDisplay === "begins with") {
                tempUserInput = "\'" + userInput + "%\'";
            } else if (currentOperator.userDisplay === "ends with") {
                tempUserInput = "\'%" + userInput + "\'";
            } else if (currentField.usereditortype === "date" || ((currentOperator.userDisplay === "equals" || currentOperator.userDisplay === "does not equal") && (currentField.usereditortype === "text"))) {
                tempUserInput = "\'" + userInput + "\'";
            } else if ((currentOperator.userDisplay === "less than" || currentOperator.userDisplay === "greater than" || currentOperator.userDisplay === "less than or equal to" || currentOperator.userDisplay === "greater than or equal to") && currentField.usereditortype === "date") {
                tempUserInput = "\'" + userInput + "\'";
            } else {
                tempUserInput = userInput;
            }
        } else if (!hideUserInput || userInput) {
            tempUserInput = userInput;
        }

        onRuleChange({
            field: currentField,
            uniqueId: rule.uniqueId,
            userInput: userInput,
            whereClauseString:
                currentField && currentOperator && (hideUserInput || userInput)
                    ? currentField.customlogic
                        ? currentField.customlogic + tempUserInput + currentField.endparentheses
                        : !hideUserInput
                            ? currentField.field + " " + currentOperator?.operator + " " + tempUserInput
                            : currentField.field + " " + currentOperator?.operator + " "
                    : null,
            selectedOperator: currentOperator
        }, isInitialiLoading.current)

        if (isInitialiLoading.current) {
            isInitialiLoading.current = false;
        }
    },[currentField, userInput, currentOperator])


    function onDropdownSelection(field) {
        setCurrentField(field);
        setCurrentOperator();
    }

    function onOperatorDropdownSelection(operator) {
        setCurrentOperator(operator);
        hideUserInput && setUserInput();
    }

    return (
        <>
            <div className='rule-name form-control-dropdown-container'>
                <button className="form-control form-control-dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {currentField ? currentField.name : "Select Rule"}
                </button>
                <div className="dropdown-icon-container d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="dropdown-icon"></div>
                </div>
                <SegmentFieldModal fields={fields} onDropdownSelection={onDropdownSelection} />
            </div>
            <div className='rule-operator form-control-dropdown-container'>
                <button className="form-control form-control-dropdown" type="button" disabled={!currentField} data-bs-toggle="dropdown" aria-expanded="false">
                    {currentOperator ? currentOperator.userDisplay : "Select Operator"}
                </button>
                <div className="dropdown-icon-container d-flex align-items-center" disabled={!currentField} data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="dropdown-icon"></div>
                </div>
                <div className="dropdown-menu">
                    {
                        currentField?.operatorarray?.slice().map((operator) => (
                            <div className='dropdown-item' onClick={() => onOperatorDropdownSelection(operator)}>
                                {operator.userDisplay}
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                currentField?.usereditortype === "money"
                    ?
                        <div className='input-money-div rule-value'>
                            <input className='form-text-input form-control' disabled={!currentField} type='number' step="0.01" id={"inputId" + rule.uniqueId} name={"inputId" + rule.uniqueId} value={userInput} onChange={e => {setUserInput(e.target.value)}} />
                            <i>$</i>
                        </div>
                    :
                        <div className={`rule-value${hideUserInput ? ' d-none' : ''}`}>
                            {!hideUserInput && <input disabled={!currentField} className={currentField?.usereditortype === "text" ? "form-text-input form-control" : "form-text-input form-control"} type={currentField?.usereditortype === "date" ? "date" : "text"} id={"inputId" + rule.uniqueId} name={"inputId" + rule.uniqueId} value={userInput} onChange={e => {setUserInput(e.target.value)}} />}
                        </div>
            }
            <i class="delete-rule fa-solid fa-xmark align-self-center" onClick={() => deleteRule(rule)} />
        </>
    )
}

export default SegmentationRule;