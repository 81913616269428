import React, { useEffect, useState } from 'react';
import './EmailPreviewSettings.scss';

const EmailPreviewSettingsRow = ({triggeredSettingsRowData, updateTriggerSettingsArray}) => {

    const [isDisplayedInPreviewDropdown, setIsDisplayedInPreviewDropdown] = useState();
    const [dataExtensionExternalKey, setDataExtensionExternalKey] = useState();
    const [dataExtensionPreviewRow, setDataExtensionPreviewRow] = useState();
    const [hasMadeChange, setHasMadeChange] = useState(false);
    const [showSpecialCharMsg, setShowSpecialCharMsg] = useState(false);
    const [currentTimer, setCurrentTimer] = useState();

    useEffect(() => {
        setIsDisplayedInPreviewDropdown(triggeredSettingsRowData.values.isdisplayedinpreviewdropdown);
        setDataExtensionExternalKey(triggeredSettingsRowData.values.dataextensionexternalkey);
        setDataExtensionPreviewRow(triggeredSettingsRowData.values.dataextensionrow);
    },[])

    useEffect(() => {
        if (hasMadeChange) {
            let triggeredSettingsObjectSetter = {
                "keys": {
                    "triggeredsendexternalkey": triggeredSettingsRowData.keys.triggeredsendexternalkey
                },
                "values": {
                    "dataextensionexternalkey": dataExtensionExternalKey,
                    "dataextensionrow": dataExtensionPreviewRow,
                    "email_id": triggeredSettingsRowData.values.email_id,
                    "isdisplayedinoverviewtable": triggeredSettingsRowData.values.isdisplayedinoverviewtable,
                    "isdisplayedinpreviewdropdown": isDisplayedInPreviewDropdown,
                    "name": triggeredSettingsRowData.values.name,
                    "preferredname": triggeredSettingsRowData.values.preferredname,
                    "status": triggeredSettingsRowData.values.status
                }
            }
            updateTriggerSettingsArray(triggeredSettingsObjectSetter);
            setHasMadeChange(false);
        }
    },[dataExtensionExternalKey, dataExtensionPreviewRow, isDisplayedInPreviewDropdown])

    function handleDataExtensionKeyChange(inputValue) {
        if(inputValue.charAt(0) === "_" || inputValue.charAt(0) === "-") {
            setDataExtensionExternalKey(inputValue.substring(1).replace(/[,<\.>/\?'":;\|\\\}\]\{\[\+=\)\(\*&\^%\$#@!~`$]/g, ""));
            setHasMadeChange(true);
        } else {
            setDataExtensionExternalKey(inputValue.replace(/[,<\.>/\?'":;\|\\\}\]\{\[\+=\)\(\*&\^%\$#@!~`$]/g, ""));
            setHasMadeChange(true);
        }

        if(inputValue.match(/[,<\.>/\?'":;\|\\\}\]\{\[\+=\)\(\*&\^%\$#@!~`$]/g)) {
            if(currentTimer) {
                setShowSpecialCharMsg(false);
                clearTimeout(currentTimer);
            }
            setShowSpecialCharMsg(true);
            let thisCurrentTimer = setTimeout(() => {setShowSpecialCharMsg(false)}, 10000);
            setCurrentTimer(thisCurrentTimer);
        }
    }


    return (
        <div className='email-preview-settings-data-columns'>
            {
                isDisplayedInPreviewDropdown === "True" ? 
                    <i class="fa-solid fa-eye eye-icon icon-blue" onClick={() => {
                        setIsDisplayedInPreviewDropdown("False");
                        setHasMadeChange(true);
                    }}></i>
                    : <i class="fa-solid fa-eye-slash eye-icon icon-gray" onClick={() => {
                        setIsDisplayedInPreviewDropdown("True");
                        setHasMadeChange(true);
                    }}></i>
            }
            <div className='email-preview-settings-row-details'>
                <div className='email-preview-settings-row-details-name'>{triggeredSettingsRowData.values.name}</div>
                <hr className='email-preview-divider'/>
                <div className='email-preview-settings-row-details-name-data'>
                    <div>
                        <label className='email-preview-input-label'>DATA EXTENSION EXTERNAL KEY</label>
                        <input className='form-control' maxlength="200" value={dataExtensionExternalKey} onChange={e => {
                            handleDataExtensionKeyChange(e.target.value);
                            setHasMadeChange(true);
                        }}/> 
                        {showSpecialCharMsg && <div className='special-char-message'>Special characters not allowed</div>}
                    </div>
                    <div>
                        <div className='position-relative'>
                            <label className='email-preview-input-label'>PREVIEW ROW</label>
                            <input className='form-control' type="number" min="1" value={dataExtensionPreviewRow} 
                                onChange={e => {
                                    if(Number(e.target.value) > 0) {
                                        setDataExtensionPreviewRow(Number(e.target.value));
                                        setHasMadeChange(true);
                                    }
                                }}
                            /> 
                            <div className='input-number-toggles-container'>
                                <div className='input-number-toggles' 
                                    onClick={() => {
                                            setDataExtensionPreviewRow(Number(dataExtensionPreviewRow) + 1);
                                            setHasMadeChange(true);
                                        }}>
                                    <i className="fa-solid fa-caret-up input-number-toggles-icon" height="15"></i>
                                </div>
                                <div className='input-number-toggles' 
                                    onClick={() => {
                                            if (Number(dataExtensionPreviewRow) > 1) {
                                                setHasMadeChange(true);
                                                setDataExtensionPreviewRow(Number(dataExtensionPreviewRow) - 1); 
                                            }
                                        }}>
                                    <i className="fa-solid fa-caret-down input-number-toggles-icon" height="15"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default EmailPreviewSettingsRow