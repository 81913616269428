import React, { useState } from 'react';
import TopLevelError from '../errors/TopLevelError';
import SendEmailPerformance from './trigger-components/SendEmailPerformance';
import TriggeredSettings from './trigger-components/TriggeredSettings';
import './TriggeredSends.scss';
import Title from '../layout/Title';
import SendEmailPerformanceGraph from './trigger-components/SendEmailPerformanceGraph';

const Settings = ({showUnsavedChangesModal, setShowUnsavedChangesModal, pathToNavigateTo, setHasChanges, resetFlags}) => {

    const [apiError, setApiError] = useState(null);
    const [has401Error, setHas401Error] = useState(null);
    const [refreshTriggerAnalytics, setRefreshTriggerAnalytics] = useState();
    const [refreshTriggerAnalyticsData, setRefreshTriggerAnalyticsData] = useState();
    const [isGraphOpened, setIsGraphOpened] = useState(false);
    const [graphData, setGraphData] = useState(null);
    const [keyName, setKeyName] = useState(null);

    const loadGraphCard = (kName, gData) => {
        setIsGraphOpened(true);
        setKeyName(kName);
        setGraphData(gData);
    }

    return (
        <div className='page'>
            <Title title={'Triggered Sends'} buttonText={null} saveAction={null} />


            {apiError && <div style={{width: "70%"}}><TopLevelError {...{apiError, has401Error, resetFlags}}/></div>}
            {
                    has401Error
                        ? <></>
                            :
                                <div className={`page-content${isGraphOpened ? ' six-by-four' : ''}`}>
                                    <SendEmailPerformance {...{setApiError, setHas401Error, refreshTriggerAnalytics, setRefreshTriggerAnalytics, refreshTriggerAnalyticsData, loadGraphCard, activeRowKey: keyName, isGraphOpened}}/>
                                    {
                                        isGraphOpened ? <SendEmailPerformanceGraph graphData={graphData} cardName={keyName} /> : <></>
                                    }
                                </div>
            }
        </div>
    )
}

export default Settings;