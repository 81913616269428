import "../../styles/_common.scss"

const Loading = ({loadingColor}) => {

    const loadingClass = "fa fa-spinner fa-spin" + (loadingColor === 'brandPrimary' ? " text--brand-primary" : "")

    return (
        <div className={loadingClass} role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export default Loading