import React, { useEffect } from 'react';
import Modal from '../../components/bootstrap-components/Modal';
import { useNavigate } from "react-router-dom";

const UnsavedChangesModal = ({showUnsavedChangesModal, setShowUnsavedChangesModal, pathToNavigateTo, resetUnsavedChangesFlags, hasChanges}) => {

    const navigate = useNavigate();

    const cancelAction = () => {
        setShowUnsavedChangesModal(false);
    }

    const continueAction = () => {
        navigate(pathToNavigateTo);
        resetUnsavedChangesFlags();
        setShowUnsavedChangesModal(false);
    }

    return (
        <Modal showModal={hasChanges && showUnsavedChangesModal} secondaryAction={() => cancelAction()} primaryAction={() => continueAction()}
            modalHeader='Unsaved Changes' modalBody='You have unsaved changes, are you sure you want to leave?'
            modalClose='Close' modalPrimary='Continue'
        />
    )
};

export default UnsavedChangesModal;