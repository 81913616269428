import { useEffect } from 'react';
import './Alerts.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getToastArray, removeToastFromArray } from '../../redux/toasts/toastsSlice';
import { ALERT_TYPE } from '../../redux/toasts/toastHelpers';

const Alerts = () => {

    const dispatch = useDispatch();
    // array contains of object with properties as { key: string, color: string, msg: string }
    const toastArray = useSelector(getToastArray);

    const getColorClass = (alertColor) => {
        if (alertColor === ALERT_TYPE.SUCCESS) {
            return "alert-success"
        } else if (alertColor === ALERT_TYPE.WARNING) {
            return "alert-warning"
        } else if (alertColor === ALERT_TYPE.DANGER) {
            return "alert-danger"
        }
    }

    const removeAlert = (key) => {
        dispatch(removeToastFromArray(key))
    }

    return (
        <>
            {
                toastArray.length ?
                    <div className='alert-container'>
                        {
                            toastArray.map((toastObj) => {
                                return <div class={"alert alert-dismissible fade show " + getColorClass(toastObj.color)} role="alert">
                                    {toastObj.msg}
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={() => removeAlert(toastObj.key)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            })
                        }
                    </div>
                    : <></>
            }
        </>
    )
}

export default Alerts