import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../bootstrap-components/Loading';
import './Campaigns.scss';
import CampaignsRow from './CampaignsRow';
import Title from '../layout/Title';

const Campaigns = ({resetFlags}) => {

    const [sendObjectResult, setSendObjectResult] = useState();
    const [emailAnalytics, setEmailAnalytics] = useState();
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState(null);
    const [searchObjectResult, setSearchObjectResult] = useState();
    const [isSearch, setIsSearch] = useState(false);
    const [searchEmailAnalytics, setSearchEmailAnalytics] = useState();

    const newDate = new Date()
    const todayMinusThirty = new Date(newDate.setDate(newDate.getDate() - 30));
    const requestBody = {
        object: 'Send',
        fields: [
            'ID',
            'Status',
            'Subject',
            'EmailName',
            'SendDate',
            'NumberErrored',
            'NumberExcluded',
            'NumberSent',
            'NumberTargeted',
            'NumberDelivered',
            'HardBounces',
            'OtherBounces',
            'SoftBounces',
            'UniqueClicks',
            'UniqueOpens',
            'Unsubscribes'
        ],
        filter: {
            field: 'SendDate',
            operator: 'greaterThan',
            value: todayMinusThirty
        }
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
    }
    const tableHeaders = [
        'Job ID',
        'Send Date',
        'Sent',
        'Open Rate',
        'Click Rate',
        'Bounce Rate',
    ];

    useEffect(() => {
        async function getSendData() {
            var res = await fetch('/soap/retrieve', requestOptions);

            if (!res.ok) {
                const message = `An error has occured: ${res.status} - ${res.statusText}`;
                setSendObjectResult(message);
            }

            const data = await res.json();

            if (data.results) {
                setSendObjectResult(data.results);
            } else if (!data.results && data.status === "OK") {
                setSendObjectResult("No data was found");
            } else {
                //tech debt: need to come back and figure out error handling. Errors will not be shown to users currently
                setSendObjectResult("error");
            }

        }
        getSendData();
    },[])

    useEffect(() => {
        if (sendObjectResult) {
            if (typeof sendObjectResult === 'string' || sendObjectResult instanceof String) {
                setEmailAnalytics(sendObjectResult);
            } else if (Array.isArray(sendObjectResult)){
                const newSendArry = sendObjectResult.slice().filter(z => Number(z.NumberSent) > 0).sort((a,b) => Date.parse(b.SendDate) - Date.parse(a.SendDate)).map(x => (
                    {
                        ...x,
                        TotalBounces: Number(x.NumberSent) - Number(x.NumberDelivered),
                        BounceRate: (parseFloat(((Number(x.NumberSent) - Number(x.NumberDelivered)) / Number(x.NumberSent) * 100)).toFixed(2) + "%"),
                        OpenRate: (parseFloat((Number(x.UniqueOpens) / Number(x.NumberSent)) * 100).toFixed(2) + "%"),
                        ClickRate: (parseFloat((Number(x.UniqueClicks) / Number(x.NumberSent)) * 100).toFixed(2) + "%")
                    }
                ))
                setEmailAnalytics(newSendArry);
            } else if (typeof sendObjectResult === 'object') {
                let tempArray = [];
                tempArray.push(sendObjectResult);
                const newSendArry = tempArray.slice().filter(z => Number(z.NumberSent) > 0).sort((a,b) => Date.parse(b.SendDate) - Date.parse(a.SendDate)).map(x => (
                    {
                        ...x,
                        TotalBounces: Number(x.NumberSent) - Number(x.NumberDelivered),
                        BounceRate: (parseFloat(((Number(x.NumberSent) - Number(x.NumberDelivered)) / Number(x.NumberSent) * 100)).toFixed(2) + "%"),
                        OpenRate: (parseFloat((Number(x.UniqueOpens) / Number(x.NumberSent)) * 100).toFixed(2) + "%"),
                        ClickRate: (parseFloat((Number(x.UniqueClicks) / Number(x.NumberSent)) * 100).toFixed(2) + "%")
                    }
                ))
                setEmailAnalytics(newSendArry);
            }
        }
    },[sendObjectResult])

    useEffect(() => {
        if (searchObjectResult) {
            if (typeof searchObjectResult === 'string' || searchObjectResult instanceof String) {
                setSearchEmailAnalytics(searchObjectResult);
            } else if (Array.isArray(searchObjectResult)) {
                const newSendArry = searchObjectResult.slice().filter(z => Number(z.NumberSent) > 0).sort((a,b) => Date.parse(b.SendDate) - Date.parse(a.SendDate)).map(x => (
                    {
                        ...x,
                        TotalBounces: Number(x.NumberSent) - Number(x.NumberDelivered),
                        BounceRate: (parseFloat(((Number(x.NumberSent) - Number(x.NumberDelivered)) / Number(x.NumberSent) * 100)).toFixed(2) + "%"),
                        OpenRate: (parseFloat((Number(x.UniqueOpens) / Number(x.NumberSent)) * 100).toFixed(2) + "%"),
                        ClickRate: (parseFloat((Number(x.UniqueClicks) / Number(x.NumberSent)) * 100).toFixed(2) + "%")
                    }
                ))
                setSearchEmailAnalytics(newSendArry);
            } else if (typeof searchObjectResult === "object") {
                let tempArray = [];
                tempArray.push(searchObjectResult);
                const newSendArry = tempArray.slice().filter(z => Number(z.NumberSent) > 0).sort((a,b) => Date.parse(b.SendDate) - Date.parse(a.SendDate)).map(x => (
                    {
                        ...x,
                        TotalBounces: Number(x.NumberSent) - Number(x.NumberDelivered),
                        BounceRate: (parseFloat(((Number(x.NumberSent) - Number(x.NumberDelivered)) / Number(x.NumberSent) * 100)).toFixed(2) + "%"),
                        OpenRate: (parseFloat((Number(x.UniqueOpens) / Number(x.NumberSent)) * 100).toFixed(2) + "%"),
                        ClickRate: (parseFloat((Number(x.UniqueClicks) / Number(x.NumberSent)) * 100).toFixed(2) + "%")
                    }
                ))
                setSearchEmailAnalytics(newSendArry);
            }
        }
    },[searchObjectResult])

    useEffect(() => {
        if (searchValue === '') {
            setIsSearch(false);
            setSearchObjectResult(null);
            setSearchEmailAnalytics(null);
        }
    },[searchValue])

    const toIndividualEmail = (email) => {
        navigate('/email',
            {
                state: email
            }
        )
    }

    function submitOnEnterKey(event) {
        if (event.key === 'Enter') {
            performSearch();
        }
    }

    function performSearch() {
        const searchRequestBody = {
            object: 'Send',
            fields: [
                'ID',
                'Status',
                'Subject',
                'EmailName',
                'SendDate',
                'NumberErrored',
                'NumberExcluded',
                'NumberSent',
                'NumberTargeted',
                'NumberDelivered',
                'HardBounces',
                'OtherBounces',
                'SoftBounces',
                'UniqueClicks',
                'UniqueOpens',
                'Unsubscribes'
            ],
            filter: {
                field: 'EmailName',
                operator: 'like',
                value: searchValue
            }
        }
        const searchRequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(searchRequestBody)
        }

        async function getSearchData() {
            var res = await fetch('/soap/retrieve', searchRequestOptions);

            if (!res.ok) {
                const message = `An error has occured: ${res.status} - ${res.statusText}`;
                setSendObjectResult(message);
            }

            const data = await res.json();

            if (data.results) {
                setSearchObjectResult(data.results);
            } else if (!data.results && data.status === "OK") {
                setSearchObjectResult("No data was found");
            } else {
                setSearchObjectResult("error");
            }
        }
        getSearchData();
        setIsSearch(true);
    }


    const renderTable = () => {
        if (isSearch === true) {
            return (
                searchEmailAnalytics === undefined || searchEmailAnalytics === null
                    ? <tr><td colSpan={7}><div className='my-3'><Loading /></div></td></tr>
                    : searchEmailAnalytics && Array.isArray(searchEmailAnalytics)
                        ?
                            searchEmailAnalytics.slice().map((emailSend, index) => (
                                <CampaignsRow key={emailSend.ID} emailSend={emailSend} rowNum={index} />
                            ))
                        : <tr><td colSpan={7}><div className='my-3'>No data found</div></td></tr>
            )
        } else {
            return (
                emailAnalytics === undefined || emailAnalytics === null
                    ? <tr><td colSpan={7}><div className='my-3'><Loading /></div></td></tr>
                    : emailAnalytics && Array.isArray(emailAnalytics)
                        ?
                            emailAnalytics.slice().map((emailSend, index) => (
                                <CampaignsRow key={emailSend.ID} emailSend={emailSend} rowNum={index} />
                            ))
                        :
                        <tr><td colSpan={7}><div className='my-3'>No data found</div></td></tr>
            )
        }
    }

    return (
        <div className='page campaign-page'>
            <Title title={'Campaigns'} buttonText={null} saveAction={null} />
            <div className='card'>
                <h2 className='card-title'>Recent Email Sends</h2>
                <div className='card-body'>
                    <table className='table table-borderless table-hover left-align-first-column expand-row-shadow'>
                        <thead>
                            <tr>
                                <th cope="col">
                                    <div>
                                        <input type="search" class="form-control-sm" placeholder="Name search ..." arialabel="Name Search" ariaDescribedby="Email Name Search" value={searchValue} onChange={e => setSearchValue(e.target.value)} onKeyUp={submitOnEnterKey}/>
                                    </div>
                                </th>
                                {tableHeaders.map((headerName) =>
                                    <th cope="col">
                                        <div> {headerName} </div>
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {renderTable()}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default Campaigns