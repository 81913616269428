import React, { useEffect } from "react";
import Title from "../layout/Title";
import styles from './SubscriberHistoryPage.module.scss';
import SubscriberHistorySearch from "./SubscriberHistorySearch";
import SubscriberHistory from "./SubscriberHistory";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentSubscriber, resetCurrentSubscriber, getCurrentSubscriberRevenueStatus, getCurrentSubscriberEmailEngagementStatus, getSubscriberSendEvents, getSubscriberSendEventsStatus, getSubscriberOpenEvents, getSubscriberOpenEventsStatus, getSubscriberClickEvents, getSubscriberClickEventsStatus, getSubscriberBounceEvents, getSubscriberBounceEventsStatus, getSubscriberNotSentEvents, getSubscriberNotSentEventsStatus } from "../../redux/subscriber-history/subscriberHistorySlice";
import Loading from "../bootstrap-components/Loading";

const SubscriberHistoryPage = () => {
    const dispatch = useDispatch();

    const currentSubscriber = useSelector(getCurrentSubscriber);
    const currentSubscriberRevenueStatus = useSelector(getCurrentSubscriberRevenueStatus);
    const currentSubscriberEmailEngagementStatus = useSelector(getCurrentSubscriberEmailEngagementStatus);

    const isRevenueComplete = currentSubscriberRevenueStatus === 'rejected' || currentSubscriberRevenueStatus === 'fulfilled';
    const isRevenuePending = currentSubscriberRevenueStatus === 'pending';
    const isEmailEngagementComplete = currentSubscriberEmailEngagementStatus === 'rejected' || currentSubscriberEmailEngagementStatus === 'fulfilled';
    const isEmailEngagementPending = currentSubscriberEmailEngagementStatus === 'pending';

    // const currentSubscriberSendEvents = useSelector(getSubscriberSendEvents);
    const currentSubscriberSendEventsStatus = useSelector(getSubscriberSendEventsStatus);
    // const currentSubscriberOpenEvents = useSelector(getSubscriberOpenEvents);
    const currentSubscriberOpenEventsStatus = useSelector(getSubscriberOpenEventsStatus);
    // const currentSubscriberClickEvents = useSelector(getSubscriberClickEvents);
    const currentSubscriberClickEventsStatus = useSelector(getSubscriberClickEventsStatus);
    // const currentSubscriberBounceEvents = useSelector(getSubscriberBounceEvents);
    const currentSubscriberBounceEventsStatus = useSelector(getSubscriberBounceEventsStatus);
    // const currentSubscriberNotSentEvents = useSelector(getSubscriberNotSentEvents);
    const currentSubscriberNotSentEventsStatus = useSelector(getSubscriberNotSentEventsStatus);

    const isSoapEventCallsRunning =
        currentSubscriberSendEventsStatus === 'idle' || currentSubscriberSendEventsStatus === 'pending' ||
        currentSubscriberOpenEventsStatus === 'idle' || currentSubscriberOpenEventsStatus === 'pending' ||
        currentSubscriberClickEventsStatus === 'idle' || currentSubscriberClickEventsStatus === 'pending' ||
        currentSubscriberBounceEventsStatus === 'idle' || currentSubscriberBounceEventsStatus === 'pending' ||
        currentSubscriberNotSentEventsStatus === 'idle' || currentSubscriberNotSentEventsStatus === 'pending';

    const isSoapEventCallsComplete =
        (currentSubscriberSendEventsStatus === 'rejected' || currentSubscriberSendEventsStatus === 'fulfilled') &&
        (currentSubscriberOpenEventsStatus === 'rejected' || currentSubscriberOpenEventsStatus === 'fulfilled') &&
        (currentSubscriberClickEventsStatus === 'rejected' || currentSubscriberClickEventsStatus === 'fulfilled') &&
        (currentSubscriberBounceEventsStatus === 'rejected' || currentSubscriberBounceEventsStatus === 'fulfilled') &&
        currentSubscriberNotSentEventsStatus === 'rejected' || currentSubscriberNotSentEventsStatus === 'fulfilled';

    useEffect(() => {
        return () => dispatch(resetCurrentSubscriber());
    }, []);


    return (
        <div className='page'>
            <Title title={"Subscribers"} buttonText={null} saveAction={null} />
            <div className={'card ' + styles.card}>
                {
                    currentSubscriber && isRevenueComplete && isEmailEngagementComplete && isSoapEventCallsComplete ? <SubscriberHistory />
                        : currentSubscriber && (isRevenuePending || isEmailEngagementPending || isSoapEventCallsRunning) ? <div><Loading /></div>
                            : <SubscriberHistorySearch />
                }
            </div>
        </div>
    );
};

export default SubscriberHistoryPage;