import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import Loading from '../../bootstrap-components/Loading';
import './SendEmailPerformanceGraph.scss';

const SendEmailPerformanceGraph = ({ graphData, cardName }) => {

    const options = {
        backgroundColor: "transparent",
        legend: "none",
        hAxis: {
            baselineColor: "transparent",
            gridlines: {
                color: "transparent"
            },
            textPosition: 'none'
        },
        vAxis: {
            baselineColor: "transparent",
            gridlines: {
                color: "transparent"
            },
            textPosition: 'none'
        },
        chartArea: {
            height: '100%',
            width: '100%',
            top: 10,
            bottom: 10
        },
        height: '100%',
        width: '100%',
        colors: ['black'],
        tooltip: {
            isHtml: true
        },
        tooltipFontSize: 16
    };

    const graphNames = [
        { name: 'Sent', gDataWeeklyKeyName: 'sentdifftotal', gDataTotalKeyName: 'totalsent' },
        { name: 'Error', gDataWeeklyKeyName: 'errordifftotal', gDataTotalKeyName: 'totalnotsentduetoerror' },
        { name: 'Unsent', gDataWeeklyKeyName: 'notsentdifftotal', gDataTotalKeyName: 'totalothernotsent' }
    ]

    const renderGraph = (graphNameObj, index) => {
        const weeklyCountArray = graphData?.weeklyData && graphData.weeklyData[graphNameObj.gDataWeeklyKeyName];
        const totalCountObj = graphData?.totalData;

        return (
            weeklyCountArray === undefined
                ? <div className='graph-loader'><Loading /></div>
                : weeklyCountArray.length > 0
                    ? weeklyCountArray?.length > 0 &&
                        <>
                            {
                                index === 0 ?
                                    <div className='multi-graph-label-top'>
                                        <div>&lt; 4 weeks</div>
                                        <div>This week</div>
                                    </div> : <></>
                            }
                            <div className='graph-container'>
                                <div className='graph-label-container'>
                                    <div className='label-total-name'>{ graphNameObj.name }</div>
                                    <div className='label-total-count'>{ totalCountObj ? totalCountObj[graphNameObj.gDataTotalKeyName] : '-' }</div>
                                </div>
                                <div className="graph-wrapper">
                                    <Chart
                                    chartType="LineChart"
                                    data={weeklyCountArray}
                                    options={options}
                                    />
                                </div>
                            </div>
                        </> : <div>No data was found.</div>
        )
    }

    return (
        <div className='card multi-graphs-card'>
            <div className='card-body'>
                <div className="card-title">{ cardName }</div>
                <div className='multi-graphs-container'>
                    { graphNames.map((gNameObj, index) => renderGraph(gNameObj, index)) }
                </div>
            </div>
        </div>
    )
}

export default SendEmailPerformanceGraph;