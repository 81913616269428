import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import Loading from '../bootstrap-components/Loading';
import EmailPreview from '../email-preview/EmailPreview';
import { useDispatch, useSelector } from 'react-redux';
import { fetchColors, getColors, getColorsStatus, updateColors, getColorsPutResult, getColorsPutStatus, resetColorsPutResults } from '../../redux/edit-brand/colorsSlice';
import { fetchStyles, getStyles, getStylesStatus, updateStyles, getStylesPutResult, getStylesPutStatus, resetStylesPutResults } from '../../redux/edit-brand/stylesSlice';
import { getPhrasesPutResult, updatePhrases, fetchPhrases, getPhrases, getPhrasesStatus, getPhrasesPutStatus, resetPhrasesPutResults } from '../../redux/edit-phrases/phrasesSlice';
import UnsavedChangesModal from '../layout/UnsavedChangesModal';
import EditColors from './EditColors';
import TopLevelError from '../errors/TopLevelError';
import GoogleAnalyticsService from '../../service/GoogleAnalyticsService';
import './BrandPage.scss';
import EditPhrases from './EditPhrases';
import Title from '../layout/Title';
import '../../styles/components/_card.scss';
import AddColor from './AddColor';
import StyleRow from './StyleRow';
import StyleRowColorDropdown from './StyleRow_ColorDrop';
import StyleRowFontDropdown from './StyleRow_FontDropdown';
import { ALERT_TYPE, showAlert } from '../../redux/toasts/toastHelpers';
import { Tooltip } from 'react-tooltip';
import { setSubNavDisabled } from '../../redux/commonSlice';

function BrandPage({showUnsavedChangesModal, setShowUnsavedChangesModal, pathToNavigateTo, hasChanges, setHasChanges, resetFlags}) {

    const dispatch = useDispatch();
    const colors = useSelector(getColors);
    const colorsStatus = useSelector(getColorsStatus);
    const [unsavedColorsChanges, setUnsavedColorsChanges] = useState();
    const colorsPutResult = useSelector(getColorsPutResult);
    const colorsPutStatus = useSelector(getColorsPutStatus);
    const styles = useSelector(getStyles);
    const stylesStatus = useSelector(getStylesStatus);
    const [unsavedStylesChanges, setUnsavedStylesChanges] = useState();
    const stylesPutResult = useSelector(getStylesPutResult);
    const stylesPutStatus = useSelector(getStylesPutStatus);
    const phrases = useSelector(getPhrases);
    const phrasesStatus = useSelector(getPhrasesStatus);
    const [unsavedPhrasesChanges, setUnsavedPhrasesChanges] = useState();
    const phrasesPutResult = useSelector(getPhrasesPutResult);
    const phrasesPutStatus = useSelector(getPhrasesPutStatus);
    const [reloadEmailPreview, setReloadEmailPreview] = useState(0);
    const [didColorsChange, setDidColorsChange] = useState(false);
    const [didStylesChange, setDidStylesChange] = useState(false);
    const [didPhrasesChange, setDidPhrasesChange] = useState(false);
    const [emailError, setEmailError] = useState(null)
    const [brandFontOptionsArray, setBrandFontOptionsArray] = useState([]);
    const [duplicateColorList, setDuplicateColorList] = useState([]);
    const [isScrollDisabled, setIsScrollDisabled] = useState([]);
    const _ = require("lodash");

    const hasError = colorsStatus === 'rejected' || stylesStatus === 'rejected' || phrasesStatus === 'rejected' || emailError;
    const hasColors401Error = hasError && typeof colors === 'string' && colors?.includes('401');
    const hasStyles401Error = hasError && typeof styles === 'string' && styles?.includes('401');
    const hasPhrases401Error = hasError && typeof phrases === 'string' && phrases?.includes('401');
    const has401Error = hasColors401Error || hasStyles401Error || hasPhrases401Error;

    const [allArraysOriginal, setAllArraysOriginal] = useState();
    const [allArraysChanges, setAllArraysChanges] = useState();

    useEffect(() => {
        if (unsavedStylesChanges) {
            let brandFontSetter = unsavedStylesChanges.filter((x) => x.input_type === 'brand font');
            setBrandFontOptionsArray(brandFontSetter);
        }
    },[unsavedStylesChanges])

    useEffect(() => {
        if (!colors && colorsStatus === 'idle') {
            dispatch(fetchColors());
            dispatch(setSubNavDisabled(true));
            setIsScrollDisabled(true);
        } else if (colorsStatus === 'fulfilled' || colorsStatus === 'rejected') {
            // refresh scrollspy after elements in the spied container in dom are loaded,
            // by using a 0.5sec settimeout instead of tracking all the elements
            setTimeout(() => {
                window.$('[data-spy="scroll"]').each(function () {
                    window.$(this).scrollspy('refresh');
                });
                dispatch(setSubNavDisabled(false));
                setIsScrollDisabled(false);
            }, 500);
        }
    }, [colors, colorsStatus, dispatch])

    useEffect(() => {
        if (!styles && stylesStatus === 'idle') {
            dispatch(fetchStyles());
        }
    }, [dispatch, styles, stylesStatus])

    useEffect(() => {
        if (!phrases && phrasesStatus === 'idle') {
            dispatch(fetchPhrases());
        }
    }, [dispatch, phrases, phrasesStatus])

    useEffect(() => {
        setUnsavedColorsChanges(colors);
    },[colors])

    useEffect(() => {
        setUnsavedStylesChanges(styles);
    },[styles])

    useEffect(() => {
        setUnsavedPhrasesChanges(phrases);
    },[phrases])

    //Results messaging display
    useEffect(() => {
        if (colorsPutResult || phrasesPutResult || stylesPutResult) {
            if (colorsPutResult && colorsPutResult.status !== '202-Accepted') {
                dispatch(showAlert(ALERT_TYPE.DANGER, colorsPutResult.message));
            } else if (stylesPutResult && stylesPutResult.status !== '202-Accepted') {
                dispatch(showAlert(ALERT_TYPE.DANGER, stylesPutResult.message));
            } else if (phrasesPutResult && phrasesPutResult.status !== '202-Accepted') {
                dispatch(showAlert(ALERT_TYPE.DANGER, phrasesPutResult.message));
            } else {
                dispatch(showAlert(ALERT_TYPE.SUCCESS, 'Success'));
            }
            dispatch(resetColorsPutResults());
            dispatch(resetStylesPutResults());
            dispatch(resetPhrasesPutResults());
        }
    }, [colorsPutResult, phrasesPutResult, stylesPutResult])

    useEffect(() => {
        if (didColorsChange === true || didPhrasesChange === true || didStylesChange === true) {
            setHasChanges(true);
        }
    },[didColorsChange, didPhrasesChange, didStylesChange])

    useEffect(() => {
        if (styles && Array.isArray(styles) && colors && Array.isArray(colors) && phrases && Array.isArray(phrases)) {
            setAllArraysOriginal([...styles, colors.slice().sort((x, y) => x.key.localeCompare(y.key)), phrases]);
        }
    },[styles, colors, phrases])

    useEffect(() => {
        if (unsavedStylesChanges && unsavedColorsChanges && unsavedPhrasesChanges) {
            setAllArraysChanges([...unsavedStylesChanges, unsavedColorsChanges.slice().sort((x, y) => x.key.localeCompare(y.key)), unsavedPhrasesChanges]);
        }
    },[unsavedStylesChanges, unsavedColorsChanges, unsavedPhrasesChanges])

    useEffect(() => {
        // Hacky 'debounce' to work with React state (link below). Need to do 2 things:
        // 1. Check allArraysOriginal and allArraysChanges for diff using lodash. To limit processing power on constant diff checks, we want to dobounce this.
        // 2. To track changes to allArraysChanges we want to use Hooks like this UseEffect. However, tracking every state change can create
        //    duplicate 'debounce' functions using Lodash's debounce, essentially making it not useful as a debounce.
        //    hence the need for this hacky custom solution.
        // https://adevnadia.medium.com/how-to-debounce-and-throttle-in-react-without-losing-your-mind-4de6193a587
        debouncedCallback();
    },[unsavedColorsChanges, unsavedPhrasesChanges, unsavedStylesChanges])

    const checkDiff = () => {
        if(hasChanges === true) {
            const changesDiffCheck = _(allArraysChanges).differenceWith(allArraysOriginal, _.isEqual).isEmpty();
            if (changesDiffCheck === true) {
                setDidColorsChange(false);
                setDidStylesChange(false);
                setDidPhrasesChange(false);
                setHasChanges(false);
                setDuplicateColorList([]);
            }
        }
    }

    // creating ref and initializing it with the sendRequest function
    const ref = useRef(checkDiff);

    useEffect(() => {
        // updating ref when state changes
        // now, ref.current will have the latest allArraysChanges and allArraysOriginal with access to the latest state
        ref.current = checkDiff;
    },[allArraysChanges, allArraysOriginal])

    // creating debounced callback only once - on mount
    const debouncedCallback = useMemo(() => {
        // func will be created only once - on mount
        const func = () => {
            // ref is mutable! ref.current is a reference to the latest sendRequest
            ref.current?.();
        };
        // debounce the func that was created once, but has access to the latest sendRequest
        return _.debounce(func, 1000);
    }, []);

    function changeColors(e) {
        if (e) {
            let colorsTemp = unsavedColorsChanges.length > 0 ? unsavedColorsChanges : colors;
            const addOrReplace = [...colorsTemp.filter((x) => x.key !== e.key), {...e}];
            setUnsavedColorsChanges(addOrReplace);
            setDidColorsChange(true);
        }
    }

    function changeStyles(e) {
        if (e) {
            if (styles.findIndex(_element => _element.key === e.key) > -1) {
                let stylesTemp = unsavedStylesChanges.length > 0 ? unsavedStylesChanges : styles;
                const addOrReplaceStyles = [...stylesTemp.filter((x) => x.key !== e.key), {...e}];
                setUnsavedStylesChanges(addOrReplaceStyles);
            }
            setDidStylesChange(true);
        }
    }

    function changePhrases(phrasesObject) {
        setUnsavedPhrasesChanges(phrasesObject);
        setDidPhrasesChange(true);
    }

    async function updateDE() {
        if (didColorsChange === true) {
            // retrieves a copy of the duplicate color objects and prevents server-side update if there's any duplicate hexcode
            if (Array.isArray(unsavedColorsChanges)) {
                const duplicateHexcodeCountArr = unsavedColorsChanges.reduce((acc, cur) => {
                    acc[cur.hexcode] = ++acc[cur.hexcode] || 0;
                    return acc;
                }, {});

                const duplicatesArr = unsavedColorsChanges.filter((cur) => duplicateHexcodeCountArr[cur.hexcode]);
                setDuplicateColorList(duplicatesArr);

                if (duplicatesArr.length > 1) {
                    dispatch(showAlert(ALERT_TYPE.DANGER, 'Colors cannot be saved if they are duplicates. Please modify'));
                    return;
                }
            }

            await dispatch(updateColors(unsavedColorsChanges));
            setDidColorsChange(false);
            setHasChanges(false);
        }
        if (didStylesChange === true) {
            await dispatch(updateStyles(unsavedStylesChanges));
            setDidStylesChange(false);
            setHasChanges(false);
        }
        if (didPhrasesChange === true) {
            await dispatch(updatePhrases(unsavedPhrasesChanges));
            setDidPhrasesChange(false);
            setHasChanges(false);
        }
        GoogleAnalyticsService.triggerEvent("sfmc_action", "saved_brandStyles");
        updateReloadEmailPreview();
    }

    function updateReloadEmailPreview() {
        let tempReloadEmailPreviewValue = reloadEmailPreview;
        let newReloadEmailPreviewValue = tempReloadEmailPreviewValue + 1;
        setReloadEmailPreview(newReloadEmailPreviewValue);
    }

    const resetUnsavedChangesFlags = () => {
        setDidColorsChange(false);
        setDidStylesChange(false);
        setDidPhrasesChange(false);
        setHasChanges(false);
        setDuplicateColorList([]);
    }


    if (!colors) {
        return <div style={{marginTop: "20px"}}><Loading /></div>
    } else {
        return (
            <div className={`brand-page-container ${hasError ? 'flex-wrap' : ''}`}>
                <UnsavedChangesModal {...{showUnsavedChangesModal, setShowUnsavedChangesModal, pathToNavigateTo, resetUnsavedChangesFlags, hasChanges}} />
                {hasError
                    && <TopLevelError apiError={emailError} {...{has401Error, resetFlags}}/>
                }
                <div className='brand-styles-editor-container'>
                    <div className='brand-save-btn d-flex justify-content-center align-items-center sticky-container'>
                        {
                            (colorsPutStatus === 'pending' || stylesPutStatus === 'pending' || phrasesPutStatus === 'pending')
                                ? <div className="spinner-border segment-row-loading sticky-content" role="status"><span className="sr-only">Loading...</span></div>
                                :
                                    <>
                                        <div data-tooltip-id={"my-tooltip-brand-save"} data-tooltip-content={"Disabled - No Changes Made"} data-tooltip-place="left"><button className='btn-primary sticky-content' onClick={() => updateDE()} disabled={!hasChanges}>Save</button></div>
                                        { !hasChanges && <div><Tooltip id={"my-tooltip-brand-save"}/></div> }
                                    </>
                        }
                    </div>
                    <div className='brand-categories-container' data-spy="scroll" data-target=".subnav-brand" style={{overflow: isScrollDisabled ? 'hidden' : 'auto'}}>
                        <div id="colors">
                            <div className='brand-title'>
                                <Title title={'Colors'} />
                            </div>
                            <div className='card d-flex flex-column card-body-gap-lg'>
                                <div>
                                    <div className='card-title'>Names and Values</div>
                                    <div className='card-body d-flex flex-column'>
                                        <EditColors {...{unsavedColorsChanges, colors, changeColors, duplicateColorList}}/>
                                        <AddColor onColorAdd={changeColors} colorList={unsavedColorsChanges?.length > 0 ? unsavedColorsChanges : colors} />
                                    </div>
                                </div>
                                <div>
                                    <div className='card-title'>Backgrounds</div>
                                    <div className='card-body d-flex flex-column gap-1'>
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'colors' && obj.sub_category === 'backgrounds'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRowColorDropdown onStyleChange={changeStyles} style={style} colors={colors}/>
                                        )) }
                                    </div>
                                </div>
                                <div>
                                    <div className='card-title'>Text</div>
                                    <div className='card-body d-flex flex-column gap-1'>
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'colors' && obj.sub_category === 'text'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRowColorDropdown onStyleChange={changeStyles} style={style} colors={colors}/>
                                        )) }
                                    </div>
                                </div>
                                <div>
                                    <div className='card-title'>Header</div>
                                    <div className='card-body d-flex flex-column gap-1'>
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'colors' && obj.sub_category === 'header'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRowColorDropdown onStyleChange={changeStyles} style={style} colors={colors}/>
                                        )) }
                                    </div>
                                </div>
                                <div>
                                    <div className='card-title'>Footer</div>
                                    <div className='card-body d-flex flex-column gap-1'>
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'colors' && obj.sub_category === 'footer'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRowColorDropdown onStyleChange={changeStyles} style={style} colors={colors}/>
                                        )) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="logo" className='brand-group-top-spacer'>
                            <div className='brand-title'>
                                <Title title={'Logo'} />
                            </div>
                            <div className='card d-flex flex-column card-body-gap-lg'>
                                <div>
                                    <div className='card-title'>Image Settings</div>
                                    <div className='card-body d-flex flex-column gap-1'>
                                        {/* Both Text and Size Inputs */}
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'logo' && obj.sub_category === 'image settings'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRow onStyleChange={changeStyles} style={style} />
                                        )) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="typography" className='brand-group-top-spacer'>
                            <div className='brand-title'>
                                <Title title={'Typography'} />
                            </div>
                            <div className='card d-flex flex-column card-body-gap-lg'>
                                <div>
                                    <div className='card-title'>Font Family</div>
                                    <div className='card-body d-flex flex-column gap-1'>
                                        {/* Font Family Text Inputs (Needs to be udpated in the future to safe font families for email dropdown) */}
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'typography' && obj.sub_category === 'font family'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRowFontDropdown onStyleChange={changeStyles} style={style} dropdownType={"brandFont"}/>
                                        )) }
                                    </div>
                                </div>
                                <div>
                                    <div className='card-title'>Headers (Desktop)</div>
                                    <div className='card-body d-flex flex-column gap-1'>
                                        <div className='brand-headers-size-grid'>
                                            {/* H1 - H6 Font Sizes */}
                                            { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'typography' && obj.sub_category === 'headers (desktop)' && obj.input_type === 'size'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                                <StyleRow onStyleChange={changeStyles} style={style} hasFontSizeStyle={true}/>
                                            )) }
                                        </div>
                                        {/* Font Dropdown */}
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'typography' && obj.sub_category === 'headers (desktop)' && obj.input_type !== 'size'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRowFontDropdown onStyleChange={changeStyles} style={style} brandFontArray={brandFontOptionsArray} dropdownType={"normalFont"}/>
                                        )) }
                                    </div>
                                </div>
                                <div>
                                    <div className='card-title'>Headers (Mobile)</div>
                                    <div className='card-body d-flex flex-column gap-1'>
                                        <div className='brand-headers-size-grid'>
                                            {/* H1 - H6 Font Sizes */}
                                            { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'typography' && obj.sub_category === 'headers (mobile)' && obj.input_type === 'size'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                                <StyleRow onStyleChange={changeStyles} style={style} hasFontSizeStyle={true}/>
                                            )) }
                                        </div>
                                        {/* Font Dropdown */}
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'typography' && obj.sub_category === 'headers (mobile)' && obj.input_type !== 'size'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRowFontDropdown onStyleChange={changeStyles} style={style} brandFontArray={brandFontOptionsArray} dropdownType={"normalFont"}/>
                                        )) }
                                    </div>
                                </div>
                                <div>
                                    <div className='card-title'>Text (Desktop)</div>
                                    <div className='card-body d-flex flex-column gap-1'>
                                        {/* Font Size */}
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'typography' && obj.sub_category === 'text (desktop)' && obj.input_type !== 'font'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRow onStyleChange={changeStyles} style={style}/>
                                        )) }
                                        {/* Font Dropdown */}
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'typography' && obj.sub_category === 'text (desktop)' && obj.input_type === 'font'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRowFontDropdown onStyleChange={changeStyles} style={style} brandFontArray={brandFontOptionsArray} dropdownType={"normalFont"}/>
                                        )) }
                                    </div>
                                </div>
                                <div>
                                    <div className='card-title'>Text (Mobile)</div>
                                    <div className='card-body d-flex flex-column gap-1'>
                                        {/* Font Size */}
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'typography' && obj.sub_category === 'text (mobile)' && obj.input_type !== 'font'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRow onStyleChange={changeStyles} style={style}/>
                                        )) }
                                        {/* Font Dropdown */}
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'typography' && obj.sub_category === 'text (mobile)' && obj.input_type === 'font'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRowFontDropdown onStyleChange={changeStyles} style={style} brandFontArray={brandFontOptionsArray} dropdownType={"normalFont"}/>
                                        )) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="buttons" className='brand-group-top-spacer'>
                            <div className='brand-title'>
                                <Title title={'Buttons'} />
                            </div>
                            <div className='card d-flex flex-column card-body-gap-lg'>
                                <div>
                                    <div className='card-title'>Primary Button</div>
                                    <div className='card-body d-flex flex-column gap-1'>
                                        {/* Size Inputs */}
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'buttons' && obj.sub_category === 'primary button' && (obj.input_type === 'text' || obj.input_type === 'size')}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRow onStyleChange={changeStyles} style={style} />
                                        )) }
                                        {/* Text Inputs */}
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'buttons' && obj.sub_category === 'primary button' && obj.input_type === 'font'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                           <StyleRowFontDropdown onStyleChange={changeStyles} style={style} brandFontArray={brandFontOptionsArray} dropdownType={"normalFont"}/>
                                        )) }
                                        {/* Color Inputs */}
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'buttons' && obj.sub_category === 'primary button' && obj.input_type === 'color'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRowColorDropdown onStyleChange={changeStyles} style={style} colors={colors} />
                                        )) }
                                    </div>
                                </div>
                                <div>
                                    <div className='card-title'>Secondary Button</div>
                                    <div className='card-body d-flex flex-column gap-1'>
                                        {/* Size Inputs */}
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'buttons' && obj.sub_category === 'secondary button' && (obj.input_type === 'text' || obj.input_type === 'size')}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRow onStyleChange={changeStyles} style={style} />
                                        )) }
                                        {/* Text Inputs */}
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'buttons' && obj.sub_category === 'secondary button' && obj.input_type === 'font'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRowFontDropdown onStyleChange={changeStyles} style={style} brandFontArray={brandFontOptionsArray} dropdownType={"normalFont"}/>
                                        )) }
                                        {/* Color Inputs */}
                                        { unsavedStylesChanges && unsavedStylesChanges.slice().filter(obj => {return obj.category === 'buttons' && obj.sub_category === 'secondary button' && obj.input_type === 'color'}).sort((a,b) => a.key.localeCompare(b.key)).map((style) => (
                                            <StyleRowColorDropdown onStyleChange={changeStyles} style={style} colors={colors} />
                                        )) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="language" className='brand-group-top-spacer'>
                            <div className='brand-title'>
                                <Title title={'Language'} />
                            </div>
                            <div className='card d-flex flex-column card-body-gap-lg'>
                                <div>
                                    <div className='card-title'>Snippets</div>
                                    <div className='card-body d-flex flex-column gap-1'>
                                    {unsavedPhrasesChanges && <EditPhrases onPhrasesChange={changePhrases} phrases={unsavedPhrasesChanges.slice()} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Email Preview Component */}
                {!emailError &&
                    <div className='email-preview-container'>
                        <EmailPreview {...{reloadEmailPreview, setEmailError}}/>
                    </div>
                }

            </div>
        )
    }

}

export default BrandPage