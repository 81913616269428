import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import ExpressService from '../../../service/ExpressService';
import Loading from '../../bootstrap-components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTriggerSettingsIsDisplayed, getTriggerSettingsIsDisplayed, getTriggerSettingsIsDisplayedStatus, resetFetchTriggerSettingsIsDisplayed } from '../../../redux/triggered-settings/triggeredSettingsSlice';
import './SendEmailPerformance.scss';

const SendEmailPerformance = ({setApiError, setHas401Error, refreshTriggerAnalytics, setRefreshTriggerAnalytics, refreshTriggerAnalyticsData, loadGraphCard, activeRowKey, isGraphOpened}) => {
    const [sendEmailRatesDE, setSendEmailRatesDE] = useState();
    const [sendEmailRatesData, setSendEmailRatesData] = useState();
    const [triggeredSendErrorCountsDE, setTriggeredSendErrorCountsDE] = useState();
    const [triggeredWeeklyCountsDE, setTriggeredWeeklyCountsDE] = useState();
    const [triggeredWeeklyCountsData, setTriggeredWeeklyCountsData] = useState();
    const [allKeys, setAllKeys] = useState([]);
    const [hasSendEmailPerformanceError, setHasSendEmailPerformanceError] = useState();
    const [has401ErrorLocal, setHas401ErrorLocal] = useState();
    const [isDisplayedInOverviewTableArray, setIsDisplayedInOverviewTableArray] = useState();
    const dispatch = useDispatch();
    const fetchTriggerSettingsIsDisplayedResult = useSelector(getTriggerSettingsIsDisplayed);
    const fetchTriggerSettingsIsDisplayedStatus = useSelector(getTriggerSettingsIsDisplayedStatus);
    const [hasInitialTriggerSettingsCallError, setHasInitialTriggerSettingsCallError] = useState();
    const [isDataAvailable, setIsDataAvailable] = useState();
    const [searchValue, setSearchValue] = useState(null);
    const [isSearched, setIsSearched] = useState(false);
    const [initSendEmailRatesDE, setInitSendEmailRatesDE] = useState();
    const [initTriggeredSendErrorCountsDE, setInitTriggeredSendErrorCountsDE] = useState();
    const [initTriggeredWeeklyCountsDE, setInitTriggeredWeeklyCountsDE] = useState();

    useEffect(() => {
        if (refreshTriggerAnalytics === true && refreshTriggerAnalyticsData ) {
            setIsDataAvailable();
            setHas401Error();
            setHas401ErrorLocal();
            setHasSendEmailPerformanceError();
            setHasInitialTriggerSettingsCallError();
            setAllKeys([]);
            setSendEmailRatesDE();
            setTriggeredWeeklyCountsDE();
            setIsDisplayedInOverviewTableArray();
            setSendEmailRatesData();
            setTriggeredSendErrorCountsDE();
            setTriggeredWeeklyCountsData();
            setInitSendEmailRatesDE()
            setInitTriggeredSendErrorCountsDE()
            setInitTriggeredWeeklyCountsDE()
            setIsSearched(false)
            setSearchValue(null)
            if (refreshTriggerAnalyticsData.length > 0) {
                convertArrayToString(refreshTriggerAnalyticsData);
            } else {
                setIsDataAvailable(false);
            }
        }
    },[refreshTriggerAnalytics, refreshTriggerAnalyticsData])

    useEffect(() => {
        if (!fetchTriggerSettingsIsDisplayedResult && fetchTriggerSettingsIsDisplayedStatus === 'idle') {
            dispatch(fetchTriggerSettingsIsDisplayed());
        }
    },[fetchTriggerSettingsIsDisplayedResult, dispatch, fetchTriggerSettingsIsDisplayedStatus])

    useEffect(() => {
        if (fetchTriggerSettingsIsDisplayedResult && Array.isArray(fetchTriggerSettingsIsDisplayedResult)) {
            if (fetchTriggerSettingsIsDisplayedResult.length > 0) {
                convertArrayToString(fetchTriggerSettingsIsDisplayedResult);
            } else {
                setIsDataAvailable(false);
            }
        } else if (fetchTriggerSettingsIsDisplayedResult && !Array.isArray(fetchTriggerSettingsIsDisplayedResult)) {
            if (fetchTriggerSettingsIsDisplayedResult.toLowerCase().includes("error")) {
                setApiError(fetchTriggerSettingsIsDisplayedResult);
                setHasSendEmailPerformanceError(fetchTriggerSettingsIsDisplayedResult);
                setHasInitialTriggerSettingsCallError(true);
                if (fetchTriggerSettingsIsDisplayedResult.includes("401")) {
                    setHas401Error(true);
                    setHas401ErrorLocal(true);
                }
            }
        }

        if (fetchTriggerSettingsIsDisplayedResult) {
            setRefreshTriggerAnalytics(false);
        }
    },[fetchTriggerSettingsIsDisplayedResult])


    useEffect(() => {
        if (isDisplayedInOverviewTableArray) {
            getSendEmailRates();
            getTriggeredSendErrorCounts();
            getTriggeredWeeklyCounts();
        }
    },[isDisplayedInOverviewTableArray])

    useEffect(() => {
        if (sendEmailRatesDE) {
            if (Array.isArray(sendEmailRatesDE) && sendEmailRatesDE?.length > 0) {
                setSendEmailRatesData(
                    sendEmailRatesDE.reduce((rates, rate) => {
                        if (!rates.hasOwnProperty(rate.customerkey)) {
                            rates[rate.customerkey] = {[rate.engagementtype]: rate};
                        } else {
                            rates[rate.customerkey][rate.engagementtype] = rate;
                        }

                        return rates;
                    }, {})
                );
            } else {
                setSendEmailRatesData({});
            }
        }
    }, [sendEmailRatesDE])

    useEffect(() => {
        if (searchValue === '') {
            setHas401Error();
            setHas401ErrorLocal();
            setHasSendEmailPerformanceError();
            setHasInitialTriggerSettingsCallError();
            getSendEmailRates();
            getTriggeredSendErrorCounts();
            getTriggeredWeeklyCounts();
            setIsSearched(false);
        } else if(searchValue && isSearched) {
            getSendEmailRates();
            getTriggeredSendErrorCounts();
            getTriggeredWeeklyCounts();
            setIsSearched(false);
        }
    }, [searchValue, isSearched])

    // ASYNC FUNCTIONS
    // 1ST CALL: GET LIST OF TRIGGERED SENDS TO DISPLAY BASED ON ISDISPLAYED TOGGLE
    function convertArrayToString(array) {
        let listOfTriggeredSendsToDisplayString = '';
            for (let i = 0; i < array.length; i++) {
                if (i === array.length - 1) {
                    listOfTriggeredSendsToDisplayString += "'" + array[i].triggeredsendexternalkey + "'";
                } else {
                    listOfTriggeredSendsToDisplayString += "'" + array[i].triggeredsendexternalkey + "',";
                }
            }
            setIsDisplayedInOverviewTableArray(listOfTriggeredSendsToDisplayString);
    }

    // GET THE % RATES FOR ALL ISDISPLAYED TRIGGERED SENDS
    async function getSendEmailRates() {
        let sendDEData;

        if (isSearched && searchValue) {
            sendDEData = initSendEmailRatesDE?.filter(val => val.customerkey.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
        } else if (initSendEmailRatesDE) {
            sendDEData = initSendEmailRatesDE;
        } else {
            sendDEData = await ExpressService.callSFMC("/rest/data/v1/customobjectdata/key/TriggeredSendEngagementCounts/rowset?$filter=CustomerKey%20in%20(" + isDisplayedInOverviewTableArray + ")", "GET", "DE", undefined, true);
            setInitSendEmailRatesDE(sendDEData);
        }

        setSendEmailRatesDE(sendDEData);

        if (sendDEData?.name?.includes("Error") && sendDEData.message) {
            setApiError(sendDEData);
            setHasSendEmailPerformanceError(sendDEData);
            if (sendDEData.message.includes("401")) {
                setHas401Error(true);
                setHas401ErrorLocal(true);
            }
        }
    }

    // GET TOTAL ERROR COUNTS FOR ALL ISDISPLAYED TRIGGERED SENDS
    async function getTriggeredSendErrorCounts() {
        if (isSearched && searchValue) {
            let countsDEData = {};

            for (const key in initTriggeredSendErrorCountsDE) {
                if (key.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                    countsDEData[key] = initTriggeredSendErrorCountsDE[key];
                }
            }

            if (Object.keys(countsDEData).length > 0) {
                setTriggeredSendErrorCountsDE(countsDEData);
            } else {
                setTriggeredSendErrorCountsDE({});
            }
        } else if (initTriggeredSendErrorCountsDE) {
            setTriggeredSendErrorCountsDE(initTriggeredSendErrorCountsDE)
         } else {
            const countsDEData = await ExpressService.callSFMC("/rest/data/v1/customobjectdata/key/TriggeredSendErrorCounts/rowset?$filter=CustomerKey%20in%20(" + isDisplayedInOverviewTableArray + ")", "GET", "DE", undefined, true);

            if (countsDEData?.name?.includes("Error") && countsDEData.message) {
                setApiError(countsDEData);
                setHasSendEmailPerformanceError(countsDEData);
                if (countsDEData.message.includes("401")) {
                    setHas401Error(true);
                    setHas401ErrorLocal(true);
                }
            }

            const filteredCountsDEData = Array.isArray(countsDEData) && countsDEData?.length > 0 ? countsDEData : false;

            if (filteredCountsDEData) {
                let reducedfilteredCountsDEData = filteredCountsDEData.reduce((obj, item) => {
                    obj[item.customerkey] = item;
                    return obj;
                }, {});

                setInitTriggeredSendErrorCountsDE(reducedfilteredCountsDEData);
                setTriggeredSendErrorCountsDE(reducedfilteredCountsDEData);
            } else {
                setInitTriggeredSendErrorCountsDE({});
                setTriggeredSendErrorCountsDE({});
            }
        }
    }

    // GET LINE GRAPH DATA FOR ALL ISDISPLAYED TRIGGERED SENDS
    async function getTriggeredWeeklyCounts() {
        if (isSearched && searchValue) {
            let triggeredWeeklyDEData = {};

            for (const key in initTriggeredWeeklyCountsDE) {
                if (key.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                    triggeredWeeklyDEData[key] = initTriggeredWeeklyCountsDE[key];
                }
            }

            if (Object.keys(triggeredWeeklyDEData).length > 0) {
                setTriggeredWeeklyCountsDE(triggeredWeeklyDEData);
            } else {
                setTriggeredWeeklyCountsDE({});
            }
        } else if (initTriggeredWeeklyCountsDE) {
            setTriggeredWeeklyCountsDE(initTriggeredWeeklyCountsDE);
        } else {
            const triggeredWeeklyDEData = await ExpressService.callSFMC("/rest/data/v1/customobjectdata/key/TriggeredSendDiffCountByWeek/rowset?$filter=CustomerKey%20in%20(" + isDisplayedInOverviewTableArray + ")", "GET", "DE", undefined, true);

            if (triggeredWeeklyDEData?.name?.includes("Error") && triggeredWeeklyDEData.message) {
                setApiError(triggeredWeeklyDEData);
                setHasSendEmailPerformanceError(triggeredWeeklyDEData);
                if(triggeredWeeklyDEData.message.includes("401")) {
                    setHas401Error(true);
                    setHas401ErrorLocal(true);
                }
            }

            if (Array.isArray(triggeredWeeklyDEData) && triggeredWeeklyDEData?.length > 0) {
                let reducedTriggeredWeeklyDEData = triggeredWeeklyDEData.reduce((weeklyCounts, count) => {
                    if (!weeklyCounts.hasOwnProperty(count.customerkey)) {
                        weeklyCounts[count.customerkey] = [count];
                    } else {
                        weeklyCounts[count.customerkey].push(count);
                    }

                    for (let property in weeklyCounts) {
                        weeklyCounts[property] = weeklyCounts[property].sort((a,b) => Number(b.weeknumber) - Number(a.weeknumber));
                    }

                    return weeklyCounts;
                }, {})

                setInitTriggeredWeeklyCountsDE(reducedTriggeredWeeklyDEData)
                setTriggeredWeeklyCountsDE(reducedTriggeredWeeklyDEData);
            } else {
                setInitTriggeredWeeklyCountsDE({});
                setTriggeredWeeklyCountsDE({});
            }
        }
    }
    // END ASYNC FUNCTIONS


    const typeKeys = ["sentdifftotal", "errordifftotal", "notsentdifftotal"];

    /*  Creating the data objects that will feed into each chart of the passed in confirmation key
        It needs to get set into this formet:
            [
                ["", ""], //Black strings so no labels appear
                [0, 25], //First # is the index for the chart, second # is the value
                [1, 6],
                [2, 8]
            ]
    */
    const createWeeklyDataObject = (confirmationKey) => {
        return (
            typeKeys.reduce((obj, typeKey) => {
                const dataSet = triggeredWeeklyCountsDE[confirmationKey].map((count) =>
                    [count.weeknumbertext, Number(count[typeKey]) || 0]
                );
                dataSet?.length > 0 && dataSet.unshift(["", ""]);

                if (!obj.hasOwnProperty(confirmationKey)) {
                    obj[confirmationKey] = {};
                }
                obj[confirmationKey][typeKey] = dataSet;

                return obj;
            }, {})
        )
    }

    function submitOnEnterKey(event) {
        if (event.key === 'Enter') {
            setIsSearched(true);
        }
    }

    useEffect(() => {
        if(triggeredWeeklyCountsDE) {
            if (Object.keys(triggeredWeeklyCountsDE)?.length > 0) {
                let dataSet = {};
                Object.keys(triggeredWeeklyCountsDE).forEach(key => dataSet = {...dataSet, ...createWeeklyDataObject(key)})
                setTriggeredWeeklyCountsData(dataSet);
            } else {
                setTriggeredWeeklyCountsData({});
            }
        }
    }, [triggeredWeeklyCountsDE])

    useEffect(() => {
        const keys = [];

        if (sendEmailRatesData && triggeredSendErrorCountsDE && triggeredWeeklyCountsData) {
            Object.keys(sendEmailRatesData).length > 0 && keys.push(...Object.keys(sendEmailRatesData));

            Object.keys(triggeredSendErrorCountsDE).length > 0 &&
                Object.keys(triggeredSendErrorCountsDE).forEach(key => {
                    !keys.includes(key) && keys.push(key);
                })

            Object.keys(triggeredWeeklyCountsData).length > 0 &&
                Object.keys(triggeredWeeklyCountsData).forEach(key => {
                    !keys.includes(key) && keys.push(key);
                })

            setAllKeys(keys);
        }


    }, [sendEmailRatesData, triggeredSendErrorCountsDE, triggeredWeeklyCountsData])

    const headers = [
        "Sent",
        "Error",
        "Unsent"
    ];

    const breakWordHeaders = ["Open", "Click", "Bounce"];

    const renderRates = (confirmationRates) => {
        const rateOrder = ["open", "click", "bounce"];
        return confirmationRates && Object.keys(confirmationRates).length > 0
            ? rateOrder.map(rate => {
                const currentRate = confirmationRates[rate];

                return (
                    <td>
                        <div>
                            {currentRate?.engagementrate && Number(currentRate.engagementrate) ?
                                (Number(currentRate.engagementrate) * 100).toFixed(2) + "%": "-"}
                        </div>
                    </td>
                )
            }) : [...Array(3)].map(e =>
                    <td>
                        <div>
                            -
                        </div>
                    </td>
            );
    }

    const renderTable = () => {
        return allKeys.map(key =>
            <tr className={ activeRowKey === key ? 'active-row' : ''} onClick={()=> loadGraphCard(key, {weeklyData: triggeredWeeklyCountsData?.[key], totalData: triggeredSendErrorCountsDE?.[key]})}>
                <th scope="row"><div>{key}</div></th>
                <td>
                    <div className="send-email-table-data">
                        {triggeredSendErrorCountsDE?.[key]?.totalsent}
                    </div>
                </td>
                <td>
                    <div className="send-email-table-data">
                        {triggeredSendErrorCountsDE?.[key]?.totalnotsentduetoerror}
                    </div>
                </td>
                <td>
                    <div className="send-email-table-data">
                        {triggeredSendErrorCountsDE?.[key]?.totalothernotsent}
                    </div>
                </td>
                {renderRates(sendEmailRatesData?.[key])}
            </tr>
        )
    }

    return (
        <div className='card'>
            {
                isDataAvailable === false
                    ? <div style={{marginTop: "10px"}}>
                        No Triggered Email was selected to display on Trigger Analytics Table. If you wish to see data displayed here make sure to toggle the desired Triggered Sends on the Triggered Send Settings table below.
                    </div>
                    :
                    !hasInitialTriggerSettingsCallError && (sendEmailRatesData === undefined || triggeredSendErrorCountsDE === undefined || triggeredWeeklyCountsData === undefined)
                        ? <div className="card-body" style={{ marginTop: "20px" }}><Loading /></div>
                        : (sendEmailRatesData !== undefined && triggeredSendErrorCountsDE !== undefined && triggeredWeeklyCountsData !== undefined)
                            ? <div className='card-body'>
                                <table class={`trigger-sends-table table table-borderless table-hover${isGraphOpened ? ' table-sm' : ''}`}>
                                        <thead>
                                            <tr>
                                                <th cope="col">
                                                    <div>
                                                        <input type="search" class="form-control-sm" placeholder="Trigger search ..." arialabel="Trigger name search" value={searchValue} onChange={e => setSearchValue(e.target.value)} onKeyUp={submitOnEnterKey} />
                                                    </div>
                                                </th>
                                                {headers.map(header =>
                                                    <th cope="col">
                                                        <div> {header} </div>
                                                    </th>
                                                )}
                                                {breakWordHeaders.map(header =>
                                                    <th cope="col">
                                                        <div className='th-break-word'>
                                                            % <br /> {header}
                                                        </div>
                                                    </th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (sendEmailRatesData !== undefined || triggeredSendErrorCountsDE !== undefined || triggeredWeeklyCountsData !== undefined) && (Object.keys(sendEmailRatesData).length > 0 && Object.keys(triggeredSendErrorCountsDE).length > 0 && Object.keys(triggeredWeeklyCountsData).length > 0) && !hasSendEmailPerformanceError?.message?.includes("404")
                                                    ? renderTable()
                                                        : (sendEmailRatesData !== undefined || triggeredSendErrorCountsDE !== undefined || triggeredWeeklyCountsData !== undefined) && (Object.keys(sendEmailRatesData).length === 0 || Object.keys(triggeredSendErrorCountsDE).length === 0 || Object.keys(triggeredWeeklyCountsData).length === 0) && !hasSendEmailPerformanceError?.message?.includes("404")
                                                            ? (isSearched && searchValue
                                                                ? <tr><td colSpan={7}><div className='my-3'>No data found</div></td></tr>
                                                                    : <tr><td colSpan={7}><div className='my-3'>No data was found in your Data Extension. This could be a result of recently running an Autobahn Install. Check back soon. If this issue persists reach out to your Red Van Workshop rep.</div></td></tr>
                                                                    ) : hasSendEmailPerformanceError?.message?.includes("404")
                                                                        ?
                                                                            <div className='card-body'>
                                                                                <tr><td colSpan={7}><div className='my-3'>The Data Extension was not found.</div></td></tr>
                                                                            </div>
                                                                        : <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                : <></>
            }
        </div>
    )
}

export default SendEmailPerformance;