export const emailAnalyticsDeFields = {
    sent: [
        {
            "Name": "Id",
            "FieldType": "Text",
            "IsRequired": true,
            "MaxLength": 254
        },
        {
            "Name": "EmailAddress",
            "FieldType": "EmailAddress",
            "IsRequired": false
        },
        {
            "Name": "EmailName",
            "FieldType": "Text",
            "IsRequired": false,
            "MaxLength": 1000
        },
        {
            "Name": "SentDate",
            "FieldType": "Date",
            "IsRequired": false,
        }
    ],
    open: [
        {
            "Name": "Id",
            "FieldType": "Text",
            "IsRequired": true,
            "MaxLength": 254
        },
        {
            "Name": "EmailAddress",
            "FieldType": "EmailAddress",
            "IsRequired": false
        },
        {
            "Name": "EmailName",
            "FieldType": "Text",
            "IsRequired": false,
            "MaxLength": 1000
        },
        {
            "Name": "OpenDate",
            "FieldType": "Date",
            "IsRequired": false,
        }
    ],
    click: [
        {
            "Name": "Id",
            "FieldType": "Text",
            "IsRequired": true,
            "MaxLength": 254
        },
        {
            "Name": "EmailAddress",
            "FieldType": "EmailAddress",
            "IsRequired": false
        },
        {
            "Name": "EmailName",
            "FieldType": "Text",
            "IsRequired": false,
            "MaxLength": 1000
        },
        {
            "Name": "ClickDate",
            "FieldType": "Date",
            "IsRequired": false,
        }
    ],
    unsubscribe: [
        {
            "Name": "Id",
            "FieldType": "Text",
            "IsRequired": true,
            "MaxLength": 254
        },
        {
            "Name": "EmailAddress",
            "FieldType": "EmailAddress",
            "IsRequired": false
        },
        {
            "Name": "EmailName",
            "FieldType": "Text",
            "IsRequired": false,
            "MaxLength": 1000
        },
        {
            "Name": "UnsubscribeDate",
            "FieldType": "Date",
            "IsRequired": false,
        }
    ],
    bounce: [
        {
            "Name": "Id",
            "FieldType": "Text",
            "IsRequired": true,
            "MaxLength": 254
        },
        {
            "Name": "EmailAddress",
            "FieldType": "EmailAddress",
            "IsRequired": false
        },
        {
            "Name": "EmailName",
            "FieldType": "Text",
            "IsRequired": false,
            "MaxLength": 1000
        },
        {
            "Name": "BounceDate",
            "FieldType": "Date",
            "IsRequired": false,
        },
        {
            "Name": "BounceCategory",
            "FieldType": "Text",
            "IsRequired": false,
            "MaxLength": 50
        },
        {
            "Name": "BounceSubcategory",
            "FieldType": "Text",
            "IsRequired": false,
            "MaxLength": 50
        },
        {
            "Name": "BounceType",
            "FieldType": "Text",
            "IsRequired": false,
            "MaxLength": 50
        }
    ],
    totalError: [
        {
            "Name": "Id",
            "FieldType": "Text",
            "IsRequired": true,
            "MaxLength": 254
        },
        {
            "Name": "EmailAddress",
            "FieldType": "EmailAddress",
            "IsRequired": false
        },
        {
            "Name": "EmailName",
            "FieldType": "Text",
            "IsRequired": false,
            "MaxLength": 1000
        },
        {
            "Name": "EventDate",
            "FieldType": "Date",
            "IsRequired": true,
        },
        {
            "Name": "EventType",
            "FieldType": "Text",
            "IsRequired": false,
            "MaxLength": 254
        },
        {
            "Name": "Reason",
            "FieldType": "Text",
            "IsRequired": false,
            "MaxLength": 1000
        }
    ],
    totalExcluded: [
        {
            "Name": "Id",
            "FieldType": "Text",
            "IsRequired": true,
            "MaxLength": 254
        },
        {
            "Name": "EmailAddress",
            "FieldType": "EmailAddress",
            "IsRequired": false
        },
        {
            "Name": "EmailName",
            "FieldType": "Text",
            "IsRequired": false,
            "MaxLength": 1000
        },
        {
            "Name": "SentDate",
            "FieldType": "Date",
            "IsRequired": false,
        },
        {
            "Name": "NotDeliveredType",
            "FieldType": "Text",
            "IsRequired": false,
            "MaxLength": 254
        },
        {
            "Name": "BounceSubcategory",
            "FieldType": "Text",
            "IsRequired": false,
            "MaxLength": 254
        },
        {
            "Name": "ErrorReason",
            "FieldType": "Text",
            "IsRequired": false,
            "MaxLength": 1000
        }
    ],
    conversions: [
        {
            "Name": "Id",
            "FieldType": "Text",
            "IsRequired": true,
            "MaxLength": 254
        },
        {
            "Name": "EmailAddress",
            "FieldType": "EmailAddress",
            "IsRequired": false
        },
        {
            "Name": "EmailName",
            "FieldType": "EmailAddress",
            "IsRequired": false,
            "MaxLength": 254
        },
        {
            "Name": "TotalPurchases",
            "FieldType": "Decimal",
            "IsRequired": false,
            "MaxLength": 18,
            "Scale": 2
        }
    ]
}

