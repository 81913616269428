import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: 0,
    isSubNavDisabled: null,
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setNavDisabled(state, param) {
            const { payload } = param;
            return {...state, value: payload};
        },
        setSubNavDisabled(state, param) {
            return {...state, isSubNavDisabled : param.payload}
        },
    },
});

export const { setNavDisabled, setSubNavDisabled } = commonSlice.actions;

export const getIsNavDisabled = state => state?.common?.value;
export const getIsSubNavDisabled = state => state?.common?.isSubNavDisabled;

export default commonSlice.reducer