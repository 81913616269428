import React, { useEffect, useState } from "react";
import "./SubscriberHistory.scss";
import SubscriberEvents from "./SubscriberEvents";
import SubscriberDetails from "./SubscriberDetails";
import { useDispatch } from "react-redux";
import { resetCurrentSubscriber } from "../../redux/subscriber-history/subscriberHistorySlice";

const SubscriberHistory = () => {
  const dispatch = useDispatch();

  function goBackToLandingPage() {
    dispatch(resetCurrentSubscriber());
  }

  return (
    <>
      <div className="subscribers-search-container">
        <button className="btn-tertiary text-decoration-none" onClick={() => goBackToLandingPage()}>
          <i className="fa-solid fa-arrow-left mr-1"></i>Go back
        </button>
      </div>
      <div className="subscribers-info">
        <SubscriberDetails />
        <SubscriberEvents />
      </div>
    </>
  );
};

export default SubscriberHistory;
