import React, { useState, useEffect } from 'react';
import Loading from '../bootstrap-components/Loading';
import './Login.scss';

const Login = ({isLoggedIn, resetFlags}) => {

    const [loading, setLoading] = useState();

    useEffect(() => {
        setLoading(false);
    }, [])

    function goToLogin() {
        setLoading(true);
        window.location.href='/login';
        resetFlags();
    }

    function goToLogout() {
        setLoading(true);
        window.location.href='/logout';
        resetFlags();
    }

    return (
        <div>
            {
                (isLoggedIn && !loading) ?
                    <>
                        <h3 className='login-title'>Would you like to log out?</h3>
                        <button className='btn-primary' onClick={goToLogout}>Logout</button>
                    </>
                : (!isLoggedIn && !loading) ?
                    <>
                        <h3 className='login-title'>Please Login</h3>
                        <button className='btn-primary' onClick={goToLogin}>Login</button>
                    </>
                : <div className='mt-5'>
                    <Loading />
                </div>
            }
        </div>
    )
}

export default Login