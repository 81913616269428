import React, { useState } from 'react';
import SubscriberStatus from './overview-charts/SubscriberStatus';
import SubscriberEngagement from './overview-charts/SubscriberEngagement';
import SendVolume from './overview-charts/SendVolume';
import TopLevelError from '../errors/TopLevelError';
import ClickRateHeatMap from './overview-charts/ClickRateHeatMap';
import OpenRateHeatMap from './overview-charts/OpenRateHeatMap';
import './Overview.scss';

const Overview = ({resetFlags}) => {

    const [apiError, setApiError] = useState(null);
    const [has401Error, setHas401Error] = useState(null);

    return (
        <div className='page-body'>
            {apiError && <div style={{width: "70%"}}><TopLevelError {...{apiError, has401Error, resetFlags}}/></div>}
            <div className='overview-page-container'>

                {
                    has401Error
                        ? <></>
                        :
                            <>
                                <div className='chart-module'>
                                    <SubscriberStatus {...{setApiError, setHas401Error}}/>
                                </div>
                                <div className='chart-module'>
                                    <SubscriberEngagement {...{setApiError, setHas401Error}}/>
                                </div>
                                <div className='heatmap-module'>
                                    <OpenRateHeatMap {...{setApiError, setHas401Error}} />
                                </div>
                                <div className='heatmap-module'>
                                    <ClickRateHeatMap {...{setApiError, setHas401Error}}/>
                                </div>
                                <div className='wide-module'>
                                    <SendVolume {...{setApiError, setHas401Error}}/>
                                </div>
                            </>
                }
            </div>
        </div>

    )
}

export default Overview