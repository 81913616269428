const ExpressService = {
    callSFMC: async function(endpoint, requestType, dataType, body, ignoreAPIFlagSwitch) {
        if (body && dataType === "DE") {
            body = {
                "items": body
            }
        }
        try {
            var res = await fetch(endpoint, {
                method: requestType,
                body: JSON.stringify(body)
            });

            if (!ignoreAPIFlagSwitch) {
                localStorage.setItem("mcAuto_APICallInWarningPeriod", true);
            }

            if (!res.ok) {
                const message = `An error has occured: ${res.status} - ${res.statusText}`;
                throw new Error(message);
            }

            const data = await res.json();
            const result = {
                status: res.status + "-" + res.statusText,
                headers: { "Content-Type": res.headers.get("Content-Type") },
                data: data,
            };
            if ((dataType === "DE" || dataType === "pagination DE") && requestType === "GET") {
                let deArray = [];
                if (result.data.items) {
                    for (let i = 0; i < result.data.items.length; i++) {
                        let deItem = {};
                        if (result.data.items[i].keys) {
                            for (const rowKey in result.data.items[i].keys) {
                                deItem[rowKey] = result.data.items[i].keys[rowKey];
                            }
                        }
                        if (result.data.items[i].values) {
                            for (const rowKeyValues in result.data.items[i].values) {
                                deItem[rowKeyValues] = result.data.items[i].values[rowKeyValues];
                            }
                        }
                        deArray.push(deItem);
                    }
                }
                if (dataType === "DE") {
                    return deArray
                } else if (dataType === "pagination DE") {

                    let numberOfPagesMath = Math.ceil(result.data.count / result.data.pageSize); 

                    let paginationObject = {
                        dEdata: deArray, 
                        totalPages: numberOfPagesMath, 
                        currentPage: result.data.page
                    }

                    return paginationObject; 
                }
                
            } else if (dataType === "sync DE" && requestType === "GET") {
                return result.data.items;
            } else {
                return result
            }
        } catch (err) {
            return err
        }
    }
}

export default ExpressService;