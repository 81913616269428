import React, { useEffect, useState, useRef } from 'react';
import { ChromePicker } from 'react-color';

const ColorRow = ({onColorChange, color, suffix, showCategory, isDuplicateColor}) => {

    const hexId = "input-hex-" + color.key + (suffix || "");
    const nameId = "input-name-" + color.key + (suffix || "");
    const divId = "color-swatch-" + color.key + (suffix || "");

    const colorSwatchRef = useRef();

    const [hexcode, setHexcode] = useState('');
    const [nameValue, setNamevalue] = useState('');
    const [colorSwatch, setColorSwatch] = useState({});
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [pickerStyles, setPickerStyles] = useState({});
    const [hasMadeChange, setHasMadeChange] = useState(false);

    useEffect(() => {
        setHexcode(color.hexcode);
        setNamevalue(color.name);
        let colorSwatchSetter = {
            background: color.hexcode
        }
        setColorSwatch(colorSwatchSetter);
    }, [])

    const [colorObject, setColorObject] = useState('');

    useEffect(() => {
        let colorObjectSetter = {
            "key": color.key,
            "name": nameValue,
            "hexcode": hexcode,
            "type": color.type
        }
        setColorObject(colorObjectSetter);
        let colorSwatchSetter = {
            background: hexcode
        }
        setColorSwatch(colorSwatchSetter);
    }, [hexcode, nameValue])

    useEffect(() => {
        if (colorObject?.key && colorObject.name && colorObject.hexcode) {
            //Needed to stop onColorChange useEffect from executing on initial load, which flips the didColorsChange flag
            if (hasMadeChange) {
                onColorChange(colorObject);
                setHasMadeChange(false);
            }
        }
    }, [colorObject])

    const showColorPicker = () => {
        setDisplayColorPicker(true);
    }

    const closeColorPicker = () => {
        setDisplayColorPicker(false);
    }

    function setMousePos(event) {
        let xCoordinate = event.clientX + 15;
        let distanceFromBottom = window.innerHeight - event.clientY;
        if (distanceFromBottom < 255) {
            var yCoordinate = window.innerHeight - 260;
        } else {
            var yCoordinate = event.clientY - 30;
        }
        let pickerStylesSetter = {
            left: xCoordinate,
            top: yCoordinate
        }
        setPickerStyles(pickerStylesSetter);
        showColorPicker();
    };

    return (
        <>
            <div div className = "d-flex flex-row m-auto" >
                    <div id={divId} className="color-swatch" style={colorSwatch} onClick={setMousePos} ref={colorSwatchRef}></div>
                    {displayColorPicker
                        ?   <div className='color-picker-container' style={pickerStyles}>
                                <div className='color-picker-cover' onClick={closeColorPicker} />
                                <ChromePicker color={hexcode} onChange={(color) => {setHasMadeChange(true); setHexcode(color.hex);}} />
                            </div>
                        : null
                    }
                <input className={"form-text-input w-100 form-control" + (isDuplicateColor  ? ' is-invalid' : '')} type="text" id={nameId} name={nameId} value={nameValue.replaceAll('&#34;', '"')} onChange={e => {setHasMadeChange(true); setNamevalue(e.target.value.replaceAll('"', '&#34;'))}} />
            </div>
            {showCategory && <div className='color-category'>Colors</div>}
        </>
    )
}

export default ColorRow