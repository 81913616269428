import React, { useState, useEffect } from 'react';
import Loading from '../bootstrap-components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConfigs, getConfigs, getConfigsStatus, updateConfigs, getConfigsPutResult, resetPutResults } from '../../redux/edit-config/configsSlice';
import { showAlert, ALERT_TYPE } from '../../redux/toasts/toastHelpers';
import UnsavedChangesModal from '../layout/UnsavedChangesModal';
import TopLevelError from '../errors/TopLevelError';
import GoogleAnalyticsService from '../../service/GoogleAnalyticsService';
import ConfigRow from './ConfigRow';
import Title from '../layout/Title';
import "./EditConfigs.scss";

function EditConfigs({showUnsavedChangesModal, setShowUnsavedChangesModal, pathToNavigateTo, setHasChanges, resetFlags}) {

    const dispatch = useDispatch();
    const rvwConfigDE = useSelector(getConfigs);
    const configsStatus = useSelector(getConfigsStatus);
    const [unsavedConfigsChanges, setUnsavedConfigsChanges] = useState([]);
    const configPutResult = useSelector(getConfigsPutResult);
    const [didConfigsChange, setDidConfigsChange] = useState(false);
    const has401Error = configsStatus === 'rejected' && typeof rvwConfigDE === 'string' && rvwConfigDE.includes('401');

    useEffect(() => {
        if (!rvwConfigDE && configsStatus === 'idle') {
            dispatch(fetchConfigs());
        }
    }, [configsStatus, dispatch, rvwConfigDE])

    useEffect(() => {
        if (configPutResult) {
            if (configPutResult.status === '202-Accepted') {
                dispatch(showAlert(ALERT_TYPE.SUCCESS, 'Success'));
            } else {
                dispatch(showAlert(ALERT_TYPE.DANGER, configPutResult.message));
            }
            dispatch(resetPutResults());
        }
    }, [configPutResult]);

    function changeConfig(e) {
        if (e) {
            if (rvwConfigDE.findIndex(_element => _element.key === e.key) > -1) {
                let configsTemp = unsavedConfigsChanges.length > 0 ? unsavedConfigsChanges : rvwConfigDE;
                const addOrReplaceConfigs = [...configsTemp.filter((x) => x.key !== e.key), {...e}];
                setUnsavedConfigsChanges(addOrReplaceConfigs);
            }
            setDidConfigsChange(true);
            setHasChanges(true);
        }
    }

    async function updateConfigsDE() {
        if (unsavedConfigsChanges.length) {
            GoogleAnalyticsService.triggerEvent("sfmc_action", "saved_configs");
            await dispatch(updateConfigs(unsavedConfigsChanges));
            resetUnsavedChangesFlags();
        }
    }

    const hasChanges = didConfigsChange;

    const resetUnsavedChangesFlags = () => {
        setDidConfigsChange(false);
        setHasChanges(false);
    }

    return (
        <div className='styles-page'>
            <Title title={'Configuration'} buttonText={'Save'} saveAction={updateConfigsDE} />
            <UnsavedChangesModal {...{showUnsavedChangesModal, setShowUnsavedChangesModal, pathToNavigateTo, resetUnsavedChangesFlags, hasChanges}} />
            {configsStatus === 'rejected'
                ? <TopLevelError {...{has401Error, resetFlags}}/>
                : <div className='card'>
                    <div className='card-body'>
                        <div className='edit-de-input-container'>
                            {
                                (!rvwConfigDE || rvwConfigDE.length < 1 || typeof rvwConfigDE === 'undefined') ?
                                    <Loading className='loading-icon' />
                                : (typeof rvwConfigDE === 'string' || rvwConfigDE instanceof String) ?
                                    <p>{rvwConfigDE}</p>
                                : rvwConfigDE.slice().sort((a,b) => a.key.localeCompare(b.key)).map((config) => (
                                    <ConfigRow key={config.key} config={config} onConfigChange={changeConfig} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}

export default EditConfigs