import Loading from "../bootstrap-components/Loading";
import './SegmentationLoading.scss';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNavDisabled } from "../../redux/commonSlice";
import { getCreateDeDataResults, getCreateQueryDataResult, getIsCreateCompleteError, getIsCreateCompleteSuccess, getIsCreatePending, resetCreateFlags, setIsCreateCompleteError, setIsCreateCompleteSuccess, setIsCreatePending, setIsRunQueryCompleteError } from "../../redux/segmentation/segmentationSlice";

const SegmentationLoading = ({segmentName, checkQueryResult, checkQueryStatus, runQueryStatus, isEmailAnalyticsSegment }) => {

    const dispatch = useDispatch();
    const isCreatePending = useSelector(getIsCreatePending);
    const isCreateCompleteSuccess = useSelector(getIsCreateCompleteSuccess);
    const isCreateCompleteError = useSelector(getIsCreateCompleteError);
    const createDeData = useSelector(getCreateDeDataResults);
    const createQueryData = useSelector(getCreateQueryDataResult);

    const afterCompleteLink = <a href="/segmentlistings" style={{ marginLeft: '10px' }}>View Segment</a>;

    useEffect(() => {
        dispatch(resetCreateFlags());
    },[]);

    useEffect(() => {
        const isResponsesBack = (createDeData && (createQueryData || createDeData.status !== "OK"));
        dispatch(setIsCreatePending(!isResponsesBack));

        if ((createDeData && createDeData.status !== "OK") || (createQueryData && createQueryData.status !== "201-Created")) {
            dispatch(setIsCreateCompleteError(true));
        }
    }, [createDeData, createQueryData]);

    useEffect(() => {
        dispatch(setNavDisabled(isCreatePending));
    }, [isCreatePending]);

    useEffect(() => {
        if (checkQueryStatus === 'fulfilled' && checkQueryResult?.isRunning === false) {
            dispatch(setIsCreateCompleteSuccess(true));
        } else if (runQueryStatus === 'rejected' || checkQueryStatus === 'rejected' || (checkQueryStatus !== 'idle' && checkQueryStatus !== 'pending' && checkQueryResult?.isRunning === false)) {
            dispatch(setIsCreateCompleteError(true));
            if (runQueryStatus === 'rejected') {
                dispatch(setIsRunQueryCompleteError(true));
            }
        }
    }, [runQueryStatus, checkQueryStatus, checkQueryResult]);


    return (
        <>
            <h5 className='card-title'>
                {isCreateCompleteSuccess && isEmailAnalyticsSegment ? <>New Segment Created. {afterCompleteLink}</>
                    : isCreatePending ? "Creating Segment — Please do not navigate away"
                        : !isEmailAnalyticsSegment && isCreateCompleteError ? <>Create Segment Error</>
                        : (checkQueryStatus === 'idle' || checkQueryStatus === 'pending' || (checkQueryStatus === 'fulfilled' && checkQueryResult?.isRunning === true))
                            ? "Creating Segment — You're free to leave this page" : ''}
            </h5>
            <div className={'segment-status-group' + (isCreateCompleteSuccess ? ' d-none' : '')}>
                <div className='segment-status-item'>
                    {
                        createDeData && createDeData.status === "OK"
                            ?
                                <>
                                    <i class="fa-solid fa-check title-check-mark" />
                                    <div>Data Extension named "{segmentName}" created successfully</div>
                                </>
                            : createDeData && createDeData.status !== "OK"
                                ?
                                    <>
                                        <i class="fa-solid fa-xmark segment-error-icon" />
                                        <div>There was an error creating Data Extension named "{segmentName}"</div>
                                    </>
                                :
                                    <>
                                        <div className="fa-loading-container">
                                            <Loading />
                                        </div>
                                        <div>Data Extension named "{segmentName}" is being created</div>
                                    </>
                    }

                </div>
                <div className='segment-status-item'>
                    {
                        createDeData && createDeData.status !== "OK"
                        ? <></>
                        : createQueryData && createQueryData.status === "201-Created"
                            ?
                                <>
                                    <i class="fa-solid fa-check title-check-mark" />
                                    <div>Query named "{segmentName}" created successfully</div>
                                </>
                            : createQueryData && createQueryData.status !== "201-Created"
                                ?
                                    <>
                                        <i class="fa-solid fa-xmark segment-error-icon" />
                                        <div>There was an error creating query named "{segmentName}"</div>
                                    </>
                                :
                                    <>
                                        <div className="fa-loading-container">
                                            <Loading />
                                        </div>
                                        <div>Query named "{segmentName}" is being created</div>
                                    </>
                    }

                </div>
                <div className='segment-status-item'>
                    {
                        (createDeData && createDeData.status !== "OK") || (createQueryData && createQueryData.status !== "201-Created")
                            ? <></>
                            : runQueryStatus !== 'rejected' && (runQueryStatus === 'pending' || checkQueryStatus === 'idle' || checkQueryStatus === 'pending' || (checkQueryStatus === 'fulfilled' && checkQueryResult?.isRunning === true))
                                ?
                                    <>
                                        <div className="fa-loading-container">
                                            <Loading />
                                        </div>
                                        <div>Segment data processing</div>
                                    </>
                                : runQueryStatus === 'fulfilled' || (checkQueryStatus === 'fulfilled' && checkQueryResult?.isRunning === false)
                                    ?
                                        <>
                                            <i class="fa-solid fa-check title-check-mark" />
                                            <div>Segment data processing complete</div>
                                        </>
                                    :
                                        <>
                                            <i class="fa-solid fa-xmark segment-error-icon" />
                                            <div>Error processing segment data</div>
                                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default SegmentationLoading