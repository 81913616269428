import React, { useEffect, useState } from 'react';
import './StyleRow.scss';

const StyleRow = ({onStyleChange, style, hasFontSizeStyle}) => {

    const [valueValue, setValueValue] = useState('');
    const [displayNumericalValue, setDisplayNumericalValue] = useState(0);
    const [hasMadeChange, setHasMadeChange] = useState(false);
    const hasPx = style.value.includes('px');
    const hasPercentage = style.value.includes('%');
    const hasPxOrPercentage = hasPx || hasPercentage;

    const fontSizeStyle = {
        fontSize: valueValue
    }

    useEffect(() => {
        setValueValue(style.value);

        if (style.input_type === 'size') {
            //remove the 'px' from the string
            hasPx && setDisplayNumericalValue(style.value.slice(0,-2));
            //remove the '%' from the string
            hasPercentage && setDisplayNumericalValue(style.value.slice(0,-1));
        }
    }, [])

    useEffect(() => {
        if (hasPx) {
            setValueValue(displayNumericalValue + 'px');
        } else if (hasPercentage) {
            setValueValue(displayNumericalValue + '%');
        }
    },[displayNumericalValue])

    const [styleObject, setStyleObject] = useState(null);

    useEffect(() => {
        let styleObjectSetter = {
            "key": style.key,
            "value": valueValue,
            "name": style.name,
            "isactive": style.isactive,
            "category": style.category,
            "sub_category": style.sub_category,
            "input_identifier": style.input_identifier,
            "input_type": style.input_type
        }
        setStyleObject(styleObjectSetter);
    }, [valueValue])

    useEffect(() => {
        if (styleObject?.value) {
            //Needed to stop onStyleChange useEffect from executing on initial load, which flips the didStylesChange flag
            if (hasMadeChange) {
                onStyleChange(styleObject);
                setHasMadeChange(false);
            }
        }
    }, [styleObject])

    function renderStyleType(styleType) {
        if (styleType === "text") {
            return (
                <input type="text" class="form-control color-form-control" value={valueValue.replaceAll('&#34;', '"')} onChange={e => {setHasMadeChange(true); setValueValue(e.target.value.replaceAll('"', '&#34;'))}}/>
            )
        } else if (styleType === "size") {
            return (
                <>
                    <input className="form-control" type="text" value={hasPxOrPercentage ? displayNumericalValue : valueValue}
                        onChange={e => {
                            if ((hasPx && Number(e.target.value) > 0) || (hasPercentage && Number(e.target.value) >= 0 && e.target.value !== "") || (hasPx && style.category !== 'font') && Number(e.target.value) >= 0) {
                                const valueToSet = e.target.value + (hasPx ? 'px' : hasPercentage ? '%' : '');
                                setValueValue(valueToSet);
                                setDisplayNumericalValue(e.target.value);
                                setHasMadeChange(true);
                            }
                        }} />
                    <div className='input-number-toggles-container'>
                        <div className='input-number-toggles' 
                            onClick={() => {
                                setDisplayNumericalValue(Number(displayNumericalValue) + 1);
                                setHasMadeChange(true);
                                }}>
                            <i class="fa-solid fa-caret-up input-number-toggles-icon" height="15"></i>
                        </div>
                        <div className='input-number-toggles' 
                            onClick={() => {
                                        if (Number(displayNumericalValue) > 1) {
                                            setDisplayNumericalValue(Number(displayNumericalValue) - 1); 
                                            setHasMadeChange(true);
                                        }
                                    }}>
                            <i class="fa-solid fa-caret-down input-number-toggles-icon" height="15"></i>
                        </div>
                    </div>
                    
                </>
            )
        }
    }



    return (
            <div className='style-color-container'>
                <div style={hasFontSizeStyle && fontSizeStyle}>{style.input_identifier}</div>
                <div className='d-flex flex-row align-items-center color-swatch-gap'>
                    <div class="style-color-input-container">
                        { renderStyleType(style.input_type) }
                    </div>
                </div>
            </div>
    )
}

export default StyleRow