import React, { useEffect, useState } from 'react';
import "./EditConfigs.scss";

const ConfigRow = ({onConfigChange,config}) => {

    const inputId = "input-name-" + config.key;
    const divId = "config-div-" + config.key;

    const [valueValue, setValueValue] = useState('');
    const [hasMadeChange, setHasMadeChange] = useState(false);

    useEffect(() => {
        setValueValue(config.value);
    }, [])

    const [configObject, setConfigObject] = useState('');

    useEffect(() => {
        let configObjectSetter = {
            "key": config.key,
            "value": valueValue,
            "name": config.name,
            "isactive": config.isactive,
            "isoverride": config.isoverride
        }
        setConfigObject(configObjectSetter);
    }, [valueValue])

    useEffect(() => {
        if (configObject && configObject.value) {
             //Needed to stop onConfigChange useEffect from executing on initial load, which flips the didConfigsChange flag
            if (hasMadeChange) {
                onConfigChange(configObject);
                setHasMadeChange(false);
            }
        }
    }, [configObject])

    return (
      <div className="config-row">
        <div className="config-name" id={divId}>
          {config.name}
        </div>
        <div className="config-value">
          <input
            className="form-text-input form-control"
            type="text"
            id={inputId}
            name={inputId}
            value={valueValue}
            onChange={(e) => {
              setHasMadeChange(true);
              setValueValue(e.target.value);
            }}
          />
        </div>
      </div>
    );
}

export default ConfigRow