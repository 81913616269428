import React, { useEffect, useRef, useState } from 'react';
import SegmentationRule from './SegmentationRule';
import { useDispatch, useSelector } from 'react-redux';
import { checkQuery, fetchFolderId, getCheckQueryResult, getCheckQueryStatus, getFolderId, getFolderIdStatus, getRunQueryResult, runQuery, resetCheckQueryStatus, setActiveDeKey, setActiveQueryId, resetUpdateSegmentListings, resetActiveDe, resetDeRowCount, getCreateDeDataResults, resetCreateFlags, getCreateQueryDataResult, createDeDataCall, createQueryDataCall, getIsCreateCompleteSuccess, getRunQueryStatus, getIsCreateCompleteError, getIsRunQueryCompleteError, setIsShowNewSegmentationEditorRow, setIsShowSegmentationEditorRow, setOpenedRowSegmentName } from '../../redux/segmentation/segmentationSlice';
import SegmentationService from '../../service/SegmentationService';
import GoogleAnalyticsService from '../../service/GoogleAnalyticsService';
import ExpressService from '../../service/ExpressService';
import SegmentationLoading from './SegmentationLoading';
import SegmentationUpdateLoading from './SegmentationUpdateLoading';
import { Tooltip } from 'react-tooltip';
import './Segmentation.scss';
import { resetQueryFlags, setSidenavLoadingActiveDeKey, setSidenavLoadingActiveQueryId } from '../../redux/sidenav-loading/sidenavLoadingSlice';
import { ALERT_TYPE, showAlert } from '../../redux/toasts/toastHelpers';

const Segmentation = ({segmentationEditorRowId, segmentRowData, timerRef, allowReopenEditorRow}) => {

    const [rulesArray, setRulesArray] = useState([]);
    const [fieldsArray, setFieldsArray] = useState([]);
    const [whereClause, setWhereClause] = useState('');
    const [finalQuery, setFinalQuery] = useState();
    const dispatch = useDispatch();
    const queryFolder = useSelector(getFolderId);
    const queryFolderStatus = useSelector(getFolderIdStatus);
    const runQueryResult = useSelector(getRunQueryResult);
    const runQueryStatus = useSelector(getRunQueryStatus);
    const [hasClickedCreateSegment, setHasClickedCreateSegment] = useState(false);
    const [hasClickedUpdateSegment, setHasClickedUpdateSegment] = useState(false);
    const [segmentName, setSegmentName] = useState('');
    const createDeData = useSelector(getCreateDeDataResults);
    const createQueryData = useSelector(getCreateQueryDataResult);
    const [addRowToSegmentListingsCall, setAddRowToSegmentListingsCall] = useState();
    const [apiError, setApiError] = useState();
    const checkQueryStatus = useSelector(getCheckQueryStatus);
    const checkQueryResult = useSelector(getCheckQueryResult);
    const [nameValidationError, setNameValidationError] = useState(false);
    const [ruleValidationError, setRuleValidationError] = useState(false);
    const [segmentFolderId, setSegmentFolderId] = useState();
    const [rvwSegments, setRvwSegments] = useState();
    const [updateQueryCall, setUpdateQueryCall] = useState();
    const [updateSegmentListingsUpdateSegmentCall, setUpdateSegmentListingsUpdateSegmentCall] = useState();
    const isSegmentRunning = checkQueryStatus === 'pending' || checkQueryResult?.isRunning === true ? true : false;
    const isSegmentRunningClass = isSegmentRunning ? ' create-segment-not-allowed' : '';
    const isSegmentRunningToolTipMsg = isSegmentRunning ? 'Disabled - Segment Currently Running' : '';
    const [showSpecialCharMsg, setShowSpecialCharMsg] = useState(false);
    const [currentTimer, setCurrentTimer] = useState();
    const [hasChanges, setHasChanges] = useState(false);
    const isCreateCompleteSuccess = useSelector(getIsCreateCompleteSuccess);
    const isCreateCompleteError = useSelector(getIsCreateCompleteError);
    const isCreateComplete = isCreateCompleteSuccess || isCreateCompleteError;
    const isRunQueryCompleteError = useSelector(getIsRunQueryCompleteError);

    const editSegmentData = segmentRowData;
    const initSegmentRulesObjectRef = useRef(JSON.parse(editSegmentData?.segmentobject || null));
    const isInitialLoading = useRef(true);

    const clearNewSegmentEditor = () => {
        !isSegmentRunning && resetAll();
    };

    useEffect(() => {
        if (isCreateComplete && isRunQueryCompleteError && hasClickedCreateSegment) {
            dispatch(setIsShowNewSegmentationEditorRow(false));
        }
    }, [isCreateComplete]);

    useEffect(() => {
        if (isCreateCompleteSuccess) {
            dispatch(showAlert(ALERT_TYPE.SUCCESS, `New Segment ${segmentName} Created`))
        }
    }, [isCreateCompleteSuccess]);

    useEffect(() => {
        // when isCreateCompleteError is set, isRunQueryCompleteError is also set in the same execution,
        // so this useEffect hook is only relying on isCreateCompleteError to prevent double dispatching error toast
        if (isRunQueryCompleteError) {
            dispatch(showAlert(ALERT_TYPE.DANGER, `Error when running query for created Segment ${segmentName}`))
        } else if (isCreateCompleteError) {
            dispatch(showAlert(ALERT_TYPE.DANGER, `Error when creating Segment ${segmentName}`))
        }
    },[isCreateCompleteError]);

    useEffect(() => {
        async function getRvwSegmentsData() {
            const rvwSegmentsData = await ExpressService.callSFMC('/rest/data/v1/customobjectdata/key/rvw_segment_fields/rowset/', 'GET', 'DE');
            setRvwSegments(rvwSegmentsData);
        }
        getRvwSegmentsData();
        async function getDeFolderId() {
            const deFolderId = await SegmentationService.getSegmentDeFolderId();
            setSegmentFolderId(deFolderId);
        }
        getDeFolderId();
        resetRulesArray();
    }, [])

    useEffect(() => {
        allowReopenEditorRow && clearNewSegmentEditor();
    }, [allowReopenEditorRow]);

    useEffect(() => {
        if (rvwSegments) {
            const newRvwSegments = rvwSegments.map((field) => {
                field['joins'] = JSON.parse(field['joins']);
                field['operatorarray'] = JSON.parse(field['operatorarray']);
                return field;
            })
            setFieldsArray(newRvwSegments);

        }
    },[rvwSegments])

    useEffect(() => {
        if (rulesArray.length > 0) {
            if(rulesArray.length > 1) {
                let totalWhereClauseString;
                for (var i = 0; i < rulesArray.length; i++) {
                    if (i === 0) {
                        totalWhereClauseString = rulesArray[i].whereClauseString + ' AND ';
                    } else if (i === rulesArray.length - 1) {
                        totalWhereClauseString = totalWhereClauseString + rulesArray[i].whereClauseString;
                    } else {
                        totalWhereClauseString = totalWhereClauseString + rulesArray[i].whereClauseString + ' AND ';
                    }
                }
                setWhereClause(totalWhereClauseString);
            } else {
                let totalWhereClauseString = rulesArray[0].whereClauseString;
                setWhereClause(totalWhereClauseString);
            }
        } else {
            setWhereClause('');
        }
    },[rulesArray])

    useEffect(() => {
        if (whereClause && whereClause.length > 0 && !whereClause.includes("null")) {
            let joinsArray = [];
            for (var i = 0; i < rulesArray.length; i++) {
                for (var x = 0; x < rulesArray[i].field.joins.length; x++) {
                    if (joinsArray.indexOf(rulesArray[i].field.joins[x]) === -1) {
                        if (rulesArray[i].field.joins[x].includes("m.Id")) {
                            joinsArray.unshift(rulesArray[i].field.joins[x]);
                        } else {
                            joinsArray.push(rulesArray[i].field.joins[x])
                        }
                    }
                }
            }
            let joinsString;
            for (var j = 0; j < joinsArray.length; j++) {
                joinsString = joinsString ? joinsString + joinsArray[j] + " \n" : joinsArray[j] + " \n";
            }
            const finalQuery = "SELECT DISTINCT m.* \nFROM Contact_Master m \n" + joinsString + "WHERE " + whereClause;
            setFinalQuery(finalQuery);
        }
    },[whereClause])

    useEffect(() => {
        if (!queryFolder && queryFolderStatus === 'idle') {
            dispatch(fetchFolderId());
        }
    },[queryFolderStatus, dispatch, queryFolder])

    useEffect(() => {
        if (runQueryResult && runQueryResult === "OK" && (hasClickedCreateSegment || hasClickedUpdateSegment)) {
            if(hasClickedUpdateSegment) {
                dispatch(checkQuery(editSegmentData.queryid));
            } else {
                dispatch(checkQuery(createQueryData.data.queryDefinitionId));
            }
        }
    },[runQueryResult])

    useEffect(() => {
        if (createDeData && createDeData.status === "OK") {
            dispatch(createQueryDataCall({ segmentName, qFolderName: queryFolder.toString(), query: finalQuery, deID: createDeData.dataExtensionID }));
        } else {
            setApiError({
                errorType: "createDe",
                errorMessage: "There was an error when creating a Data Extension for this Segment. Make sure your Segment Name is a unique Data Extension Name and External Key. If this issue persists, reach out to your Red Van Workshop rep."
            })
        }
    },[createDeData])

    useEffect(() => {
        if (createQueryData && createQueryData.status === "201-Created") {
            async function postAddRowListings() {
                const addRowData = await SegmentationService.addRowToSegmentListingsDe(segmentName, createQueryData.data.queryDefinitionId, finalQuery, rulesArray, "custom");
                setAddRowToSegmentListingsCall(addRowData);
            }
            postAddRowListings();
        } else {
            setApiError({
                errorType: "createQuery",
                errorMessage: "There was an error when creating a Query for this Segment. Make sure your Segment Name is a unique Query Activity name. If this issue persists, reach out to your Red Van Workshop rep."
            })
        }
    },[createQueryData])

    useEffect(() => {
        if (addRowToSegmentListingsCall && addRowToSegmentListingsCall.status === "200-OK") {
            dispatch(setSidenavLoadingActiveQueryId(createQueryData.data.queryDefinitionId));

            dispatch(setActiveQueryId(createQueryData.data.queryDefinitionId));
            dispatch(runQuery(createQueryData.data.queryDefinitionId));
        } else {
            setApiError({
                errorType: "addToDe",
                errorMessage: "There was an error when adding data to the segment_listings Data Extension. Reach out to your Red Van Workshop rep for assistance."
            })
        }
    },[addRowToSegmentListingsCall])

    useEffect(() => {
        if(editSegmentData) {
            const segmentRulesObject = JSON.parse(editSegmentData.segmentobject);
            setRulesArray(segmentRulesObject);
            setSegmentName(editSegmentData.segmentname);
        }
    },[editSegmentData])

    useEffect(() => {
        if (updateQueryCall === "200-OK") {
            updateSegmentListingsUpdateQuery(editSegmentData.queryid, finalQuery, rulesArray);
        }
    },[updateQueryCall])

    useEffect(() => {
        if (updateSegmentListingsUpdateSegmentCall) {
            // reset side nav loading flags
            dispatch(resetQueryFlags());
            dispatch(setSidenavLoadingActiveDeKey(editSegmentData.dataextensionexternalkey));
            dispatch(setSidenavLoadingActiveQueryId(editSegmentData.queryid));

            dispatch(setActiveDeKey(editSegmentData.dataextensionexternalkey));
            dispatch(setActiveQueryId(editSegmentData.queryid));
            dispatch(runQuery(editSegmentData.queryid));
        }
    },[updateSegmentListingsUpdateSegmentCall])

    useEffect(() => {
        if (segmentName && segmentName.length > 36) {
            let trimmedString = segmentName.substring(0, 36);
            setSegmentName(trimmedString);
        }
    },[segmentName])

    useEffect(() => {
        if (showSpecialCharMsg) {
            setTimeout(() => {setShowSpecialCharMsg(false)}, 5000);
        }
    }, [showSpecialCharMsg])

    useEffect(() => {
        if (!hasChanges && !isInitialLoading.current) {
            if (editSegmentData) {
                resetAll(editSegmentData);
            }
        }
        if (isInitialLoading.current) {
            isInitialLoading.current = false;
        }
    }, [hasChanges]);

    function updateRule(rule, isInitialLoading) {
        const ruleToUpdateIndex = rulesArray.findIndex((x) => x.uniqueId === rule.uniqueId);
        const updatedRulesArray = [...rulesArray];
        updatedRulesArray[ruleToUpdateIndex] = rule;
        setRulesArray(updatedRulesArray);
        !isInitialLoading && markFormDirty();
    }

    function addRule() {
        setRulesArray([
            ...rulesArray,
            {
                field: null,
                uniqueId: Math.floor(Math.random() * 9999999),
                userInput: '',
                whereClauseString: null
            }
        ])
        markFormDirty();
    }

    function deleteRule(rule) {
        const updatedRulesArray = [...rulesArray.filter((x) => x.uniqueId !== rule.uniqueId)];
        setRulesArray(updatedRulesArray);
        markFormDirty();
    }

    function createSegment() {
        setNameValidationError(false);
        setRuleValidationError(false);

        if (!segmentName) {
            setNameValidationError(true);
            return;
        }

        if (rulesArray.filter(rule => !rule.whereClauseString).length > 0) {
            setRuleValidationError(true);
            return;
        }

        if (whereClause) {
            GoogleAnalyticsService.triggerEvent("sfmc_action", "created_segment")
            resetQueryRun();
            setHasClickedCreateSegment(true);
            dispatch(createDeDataCall({ segmentName, segmentFolderId, segmentType: "customSegment" }));
        }
    }

    async function updateSegment() {
        setNameValidationError(false);
        setRuleValidationError(false);

        if (!segmentName) {
            setNameValidationError(true);
            return;
        }

        if (rulesArray.filter(rule => !rule.whereClauseString).length > 0) {
            setRuleValidationError(true);
            return;
        }

        if (whereClause) {
            GoogleAnalyticsService.triggerEvent("sfmc_action", "edited_segment")
            resetQueryRun();
            setHasClickedUpdateSegment(true);
            const updateQuery = await SegmentationService.updateQuery(editSegmentData.queryid, finalQuery);
            setUpdateQueryCall(updateQuery.status);
        }
    }

    function resetQueryRun() {
        dispatch(resetUpdateSegmentListings());
        dispatch(resetDeRowCount());
        dispatch(resetActiveDe());
        dispatch(resetCheckQueryStatus());
        dispatch(setActiveDeKey(segmentName));
        dispatch(setActiveQueryId(null));
        // reset side nav loading flags
        dispatch(resetQueryFlags());
        dispatch(setSidenavLoadingActiveDeKey(segmentName));
        dispatch(setSidenavLoadingActiveQueryId(null));
    }

    async function updateSegmentListingsUpdateQuery(queryId, sql, segmentObject) {
        const updateSegmentListingPayload = [{
            "QueryID": queryId,
            "SQL": sql,
            "SegmentObject": JSON.stringify(segmentObject)
        }]
        const updateSegmentListings = await ExpressService.callSFMC('/rest/data/v1/async/dataextensions/key:segment_listings/rows', 'PUT', 'DE', updateSegmentListingPayload)
        setUpdateSegmentListingsUpdateSegmentCall(updateSegmentListings);
    }

    function resetAll(initiData) {
        setApiError(null);
        if (initiData) {
            setRulesArray([...initSegmentRulesObjectRef.current]);
            setSegmentName(initiData.segmentname);
        } else {
            dispatch(resetCreateFlags());
            setAddRowToSegmentListingsCall(null);
            setHasClickedCreateSegment(false);
            resetRulesArray();
            setWhereClause('');
            setFinalQuery(null);
            setSegmentName('');
        }
    }

    function resetRulesArray() {
        const tempRulesArray = [
            {
                field: null,
                uniqueId: 0,
                userInput: '',
                whereClauseString: null
            }
        ]
        setRulesArray(tempRulesArray);
    }

    function handleSegmentNameChange(inputValue) {
        if(inputValue.charAt(0) === "_" || inputValue.charAt(0) === "-") {
            setSegmentName(inputValue.substring(1).replace(/[,<\.>/\?'":;\|\\\}\]\{\[\+=\)\(\*&\^%\$#@!~`$]/g, ""));
        } else {
            setSegmentName(inputValue.replace(/[,<\.>/\?'":;\|\\\}\]\{\[\+=\)\(\*&\^%\$#@!~`$]/g, ""));
        }
        if(inputValue.match(/[,<\.>/\?'":;\|\\\}\]\{\[\+=\)\(\*&\^%\$#@!~`$]/g)) {
            if(currentTimer) {
                setShowSpecialCharMsg(false);
                clearTimeout(currentTimer);
            }
            setShowSpecialCharMsg(true);
            let thisCurrentTimer = setTimeout(() => {setShowSpecialCharMsg(false)}, 10000);
            setCurrentTimer(thisCurrentTimer);
        }

        markFormDirty()
    }

    function markFormDirty(isDirty = true) {
        setHasChanges(!!isDirty);
    };


    return (
        <div className='segment-page'>
            {
                hasClickedCreateSegment || hasClickedUpdateSegment ? <></>
                :
                <>
                    <div className='expand-row-header'>
                        <div className='expand-row-controls-group'>
                            <input className="form-text-input form-control" type="text" id="segment-name-input" name="segment-name-input" maxlength="36" value={segmentName} placeholder='Segment Name' onChange={e => {handleSegmentNameChange(e.target.value)}} readOnly={editSegmentData ? true : false} />
                                {showSpecialCharMsg && <div className='special-char-message align-self-start'>Special characters not allowed</div>}

                            <div className='expand-row-controls'>
                                {
                                    /* after the essential calls have been made, we can offer the user to create another segment */
                                    (hasClickedCreateSegment || hasClickedUpdateSegment) && checkQueryStatus === 'fulfilled' && checkQueryResult?.isRunning === false ?
                                        <>
                                            {!isSegmentRunning && <button className={'segment-create-another btn-secondary edit-de-btn' + isSegmentRunningClass} onClick={() => !isSegmentRunning && resetAll}>Create Another Segment +</button>}
                                        </>
                                        :
                                            <>
                                                <button className='btn-primary' onClick={() => !isSegmentRunning && (editSegmentData ? updateSegment() : createSegment())} data-tooltip-id="my-tooltip-create-segment" data-tooltip-content={isSegmentRunningToolTipMsg} data-tooltip-place="left" disabled={isSegmentRunning}>{editSegmentData ? "Update" : "Create"}</button>
                                                {isSegmentRunning && <Tooltip id="my-tooltip-create-segment" />}
                                            </>
                                    }
                                    {/* timer for 0.35sec (animation is 0.35sec) to avoid it changing before the row fully collapses */}
                                    <button className='btn-secondary' onClick={() => { setTimeout(() => { markFormDirty(false); dispatch(setIsShowNewSegmentationEditorRow(false)); dispatch(setIsShowSegmentationEditorRow(false)); dispatch(setOpenedRowSegmentName(null)) }, 350); }} data-bs-toggle="collapse" data-bs-target={`#${segmentationEditorRowId}`}>Cancel</button>
                            </div>
                        </div>
                        {nameValidationError && <div>Please fill in a segment name</div>}
                        {ruleValidationError && <div>Please ensure your segment rules are completed</div>}
                    </div>
                    <div className='segment-rules-container'>
                        <h5 className='card-title'>Segment Rules</h5>
                        <div className='rules-container'>
                            {
                                rulesArray.slice().map((rule, index) => (
                                    <SegmentationRule key={rule.uniqueId} onRuleChange={updateRule} fields={fieldsArray} initRule={initSegmentRulesObjectRef?.current?.at(index)} {...{rule, deleteRule, hasChanges}} />
                                ))
                            }
                        </div>
                        <div>
                            <button className='btn-secondary edit-de-btn' onClick={addRule}>Add Rule</button>
                        </div>
                    </div>
                </>
            }

            {
                hasClickedCreateSegment && !(isCreateCompleteSuccess || isRunQueryCompleteError)?
                    <>
                        <div className='segment-rules-container segment-status-popup'>
                            <div className="card-body">
                                <SegmentationLoading {...{segmentName, checkQueryResult, checkQueryStatus, runQueryStatus}} />
                            </div>
                        </div>
                    </>
                : <></>
            }

            {
                hasClickedUpdateSegment && !isRunQueryCompleteError ?
                    <>
                        <div className='segment-rules-container segment-status-popup'>
                            <div className="card-body">
                                <SegmentationUpdateLoading {...{segmentName, updateQueryCall, checkQueryResult, checkQueryStatus, runQueryStatus}} />
                                </div>
                        </div>
                    </>
                : <></>
            }
        </div>
    )
}

export default Segmentation;