import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ExpressService from '../../service/ExpressService';

const initialState = {
    value: null,
    status: 'idle',
    error: null,
    updatePutResult: null,
    updatePutStatus: 'idle',
    updatePutError: null,
    isDisplayedStatus: 'idle',
    isDisplayedValue: null
};

export const fetchTriggerSettings = createAsyncThunk('triggerSettings/fetchTriggerSettings', async (__, {rejectWithValue}) => {
    const result = await ExpressService.callSFMC('/rest/data/v1/customobjectdata/key/TriggeredSendSettings/rowset?$filter=Status%20eq%20\'Active\'', 'GET', 'DE');

    if (result?.name?.includes("Error") && result.message) {
        return rejectWithValue(result.message)
    };

    return result;
});

export const updateTriggerSettings = createAsyncThunk('triggerSettings/updateTriggerSettings', async (unsavedTriggerSettings) => {
    return await ExpressService.callSFMC('/rest/data/v1/async/dataextensions/key:TriggeredSendSettings/rows', 'PUT', 'DE', unsavedTriggerSettings);
});

export const fetchTriggerSettingsIsDisplayed = createAsyncThunk('triggerSettings/fetchTriggerSettingsIsDisplayed', async (__, {rejectWithValue}) => {
    const listOfTriggeredSendsToDisplayCall = await ExpressService.callSFMC('/rest/data/v1/customobjectdata/key/TriggeredSendSettings/rowset?$filter=isDisplayedInOverviewTable%20eq%201', "GET", "DE", undefined, true);

    if (listOfTriggeredSendsToDisplayCall?.name?.includes("Error") && listOfTriggeredSendsToDisplayCall.message) {
        return rejectWithValue(listOfTriggeredSendsToDisplayCall.message)
    };

    return listOfTriggeredSendsToDisplayCall;
});

const triggerSettingsSlice = createSlice({
    name: 'triggerSettings',
    initialState,
    reducers: {
        resetFetchTriggerSettingsIsDisplayed(state) {
            return {...state, isDisplayedStatus: 'idle', isDisplayedValue: null}
        }
    },
    extraReducers: {
        [fetchTriggerSettings.pending]: (state, action) => {
            return {...state, status: 'pending'};
        },
        [fetchTriggerSettings.fulfilled]: (state, action) => {
            return {...state, status: 'fulfilled', value: action.payload};
        },
        [fetchTriggerSettings.rejected]: (state, action) => {
            return {...state, status: 'rejected', value: action.payload};
        },
        [updateTriggerSettings.pending]: (state, action) => {
            return {...state,
                updatePutStatus: 'pending',
                updatePutResult: null,
                updatePutError: null
            };
        },
        [updateTriggerSettings.fulfilled]: (state, action) => {
            if (action?.payload?.status === '202-Accepted') {
                return {...state,
                    updatePutStatus: 'fulfilled',
                    updatePutResult: action.payload,
                    value: action.meta.arg
                };
            } else {
                return {...state,
                    updatePutStatus: 'fulfilled',
                    updatePutResult: action.payload
                };
            }
        },
        [updateTriggerSettings.rejected]: (state, action) => {
            return {...state, updatePutStatus: 'rejected'};
        },
        [fetchTriggerSettingsIsDisplayed.pending]: (state, action) => {
            return {...state, isDisplayedStatus: 'pending'};
        },
        [fetchTriggerSettingsIsDisplayed.fulfilled]: (state, action) => {
            return {...state, isDisplayedStatus: 'fulfilled', isDisplayedValue: action.payload};
        },
        [fetchTriggerSettingsIsDisplayed.rejected]: (state, action) => {
            return {...state, isDisplayedStatus: 'rejected', isDisplayedValue: action.payload};
        }
    }
});

export const getTriggerSettings = state => state?.triggerSettings?.value;

export const getTriggerSettingsStatus = state => state?.triggerSettings?.status;

export const getTriggerSettingsPutResult = state => state?.triggerSettings?.updatePutResult;

export const getTriggerSettingsIsDisplayed = state => state?.triggerSettings?.isDisplayedValue;

export const getTriggerSettingsIsDisplayedStatus = state => state?.triggerSettings?.isDisplayedStatus;

export const { resetFetchTriggerSettingsIsDisplayed } = triggerSettingsSlice.actions;

export default triggerSettingsSlice.reducer;