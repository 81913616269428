import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import Logo from './Logo'
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from "react-ga4";
import './Navigation.scss';
import { getIsNavDisabled, getIsSubNavDisabled } from '../../redux/commonSlice';
import SidenavLoading from './SidenavLoading';
import { resetCreateFlags } from '../../redux/segmentation/segmentationSlice';
import { Tooltip } from 'react-tooltip';

const Navigation = ({isLoggedIn, isInstalled, setPathToNavigateTo, setShowUnsavedChangesModal, hasChanges}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isBrandActive, setIsBrandActive] = useState();
    const prevPath = useRef(null);
    const isNavDisabled = useSelector(getIsNavDisabled);
    const isSubNavDisabled = useSelector(getIsSubNavDisabled);

    const paths = {
        UPLOADLOGO: { url: '/uploadlogo', title: 'Upload Logo' },
        HOME: { url: '/', title: 'Home' },
        BRAND: { url: '/brand', title: 'Brand' },
        CONFIG: { url: '/config', title: 'Configuration' },
        OVERVIEW: { url: '/overview', title: 'Overview' },
        INSTALL: { url: '/install', title: 'Install' },
        TRIGGERS: { url: '/triggers', title: 'Triggered Sends' },
        CAMPAIGNS: { url: '/campaigns', title: 'Campaigns' },
        SEGMENTLISTINGS: { url: '/segmentlistings', title: 'Segments' },
        SUBSCRIBERS: { url: '/subscribers', title: 'Subscribers' },
        LOGOUT: { url: '/logout', title: 'Logout' },
    }

    const navigateToPath = (e, path) => {
        e.preventDefault();

        // don't navigate and push the same url if user is clicking the same link as the page they are currently on
        if (location.pathname !== path) {
            if (hasChanges) {
                setPathToNavigateTo(path);
                setShowUnsavedChangesModal(true);
            } else {
                // clear create data object when navigating away from these pages
                if(location.pathname === paths.CAMPAIGNS.url || location.pathname === paths.SEGMENTLISTINGS.url) {
                    dispatch(resetCreateFlags());
                }
                navigate(path);
            }
        }
    }

    useEffect(() => {
        if (location && location.pathname === '/brand') {
            setIsBrandActive(true);
        } else if (location && location.pathname !== '/brand') {
            setIsBrandActive(false);
        }

        // only push when path is different, excluding hash value
        if (location && location.pathname !== prevPath.current ) {
            ReactGA.send({ hitType: "pageview", page: location.pathname, title: paths[Object.keys(paths).find(pathKey => paths[pathKey].url === location.pathname)].title });
            prevPath.current = location.pathname;
        }
    },[location])

    return (
        <nav className={'sidenav' + (isCollapsed ? ' sidenav-collapsed' : '')} disabled={isNavDisabled}>
            {isLoggedIn && isInstalled
                &&
                <button className='nav-expand-collapse-btn' onClick={() => setIsCollapsed(value => !value)}>
                    <i className='fa-solid fa-down-left-and-up-right-to-center'></i>
                </button>
            }
            {isLoggedIn ? <Logo {...{ isCollapsed, navigateToPath, urlPath: paths.UPLOADLOGO.url }}/> : <div className='no-logo-div'/>}
            {!isLoggedIn &&
                <NavLink to={paths.HOME.url} end className={({isActive}) => isActive ? "active-nav" : ''} onClick={(e) => navigateToPath(e, paths.HOME.url)}>
                    <i className='fa-solid fa-home'></i>Home
                </NavLink>
            }
            {isLoggedIn && isInstalled
                ?
                    <>
                    <NavLink to={paths.OVERVIEW.url} className={({ isActive }) => isActive ? "active-nav" : ''} onClick={e => navigateToPath(e, paths.OVERVIEW.url)} data-tooltip-id={"my-tooltip-nav-item-overview"} data-tooltip-content={"Overview"} data-tooltip-place="right">
                        <i className='fa-solid fa-gauge-high'></i>
                        <span className='nav-item-name'>Overview</span></NavLink>
                        { isCollapsed ? <Tooltip id={'my-tooltip-nav-item-overview'} /> : <></> }
                    <NavLink to={paths.BRAND.url} className={({ isActive }) => isActive ? "active-nav" : ''} onClick={e => navigateToPath(e, paths.BRAND.url)} data-tooltip-id={"my-tooltip-nav-item-brand"} data-tooltip-content={"Brand"} data-tooltip-place="right">
                        <i className='fa-solid fa-shield'></i>
                        <span className='nav-item-name'>Brand</span></NavLink>
                        { isCollapsed ? <Tooltip id={'my-tooltip-nav-item-brand'} /> : <></> }
                        {
                            isBrandActive &&
                                <div id="subnav-brand" class="list-group sublink-group" disabled={isSubNavDisabled}>
                                    <a class="list-group-item list-group-item-action nav-item-name sublink" href="#colors">Colors</a>
                                    <a class="list-group-item list-group-item-action nav-item-name sublink" href="#logo">Logo</a>
                                    <a class="list-group-item list-group-item-action nav-item-name sublink" href="#typography">Typography</a>
                                    <a class="list-group-item list-group-item-action nav-item-name sublink" href="#buttons">Buttons</a>
                                    <a class="list-group-item list-group-item-action nav-item-name sublink" href="#language">Language</a>
                                </div>
                        }
                    <NavLink to={paths.CONFIG.url} className={({ isActive }) => isActive ? "active-nav" : ''} onClick={e => navigateToPath(e, paths.CONFIG.url)} data-tooltip-id={"my-tooltip-nav-item-configuration"} data-tooltip-content={"Configuration"} data-tooltip-place="right">
                        <i className='fa-solid fa-gear'></i>
                        <span className='nav-item-name'>Configuration</span></NavLink>
                        { isCollapsed ? <Tooltip id={'my-tooltip-nav-item-configuration'} /> : <></> }
                    <NavLink to={paths.TRIGGERS.url} className={({ isActive }) => isActive ? "active-nav" : ''} onClick={e => navigateToPath(e, paths.TRIGGERS.url)} data-tooltip-id={"my-tooltip-nav-item-triggers"} data-tooltip-content={"Triggered Sends"} data-tooltip-place="right">
                        <i className='fa-solid fa-paper-plane'></i>
                        <span className='nav-item-name'>Triggered Sends</span></NavLink>
                        { isCollapsed ? <Tooltip id={'my-tooltip-nav-item-triggers'} /> : <></> }
                    <NavLink to={paths.CAMPAIGNS.url} className={({ isActive }) => isActive ? "active-nav" : ''} onClick={e => navigateToPath(e, paths.CAMPAIGNS.url)} data-tooltip-id={"my-tooltip-nav-item-campaigns"} data-tooltip-content={"Campaigns"} data-tooltip-place="right">
                        <i className='fa-solid fa-envelopes-bulk'></i>
                        <span className='nav-item-name'>Campaigns</span></NavLink>
                        { isCollapsed ? <Tooltip id={'my-tooltip-nav-item-campaigns'} /> : <></> }
                    <NavLink to={paths.SUBSCRIBERS.url} className={({ isActive }) => isActive ? "active-nav" : ''} onClick={e => navigateToPath(e, paths.SUBSCRIBERS.url)} data-tooltip-id={"my-tooltip-nav-item-subscribers"} data-tooltip-content={"Subscribers"} data-tooltip-place="right">
                        <i className='fa-solid fa-users'></i>
                        <span className='nav-item-name'>Subscribers</span></NavLink>
                        { isCollapsed ? <Tooltip id={'my-tooltip-nav-item-subscribers'} /> : <></> }
                    <NavLink to={paths.SEGMENTLISTINGS.url} className={({ isActive }) => isActive || location?.pathname === '/segmentation' ? "active-nav" : ''} onClick={e => navigateToPath(e, paths.SEGMENTLISTINGS.url)} data-tooltip-id={"my-tooltip-nav-item-segments"} data-tooltip-content={"Segments"} data-tooltip-place="right">
                        <i className='fa-solid fa-scroll'></i>
                        <span className='nav-item-name'>Segments</span></NavLink>
                        { isCollapsed ? <Tooltip id={'my-tooltip-nav-item-segments'} /> : <></> }
                    <SidenavLoading />
                    <NavLink to={paths.LOGOUT.url} className={({ isActive }) => 'mt-auto' + (isActive ? " active-nav" : '')} onClick={e => navigateToPath(e, paths.LOGOUT.url)} data-tooltip-id={"my-tooltip-nav-item-logout"} data-tooltip-content={"Logout"} data-tooltip-place="right">
                        <i className='fa-solid fa-right-from-bracket'></i>
                        <span className='nav-item-name'>Logout</span></NavLink>
                        { isCollapsed ? <Tooltip id={'my-tooltip-nav-item-logout'} /> : <></> }
                    </>
                : isLoggedIn && !isInstalled
                    ?
                        <>
                            <NavLink to={paths.INSTALL.url} className={({isActive}) => isActive ? "active-nav" : ''} onClick={e => navigateToPath(e, paths.INSTALL.url)}><i className='fa-solid fa-download'></i>Install</NavLink>
                            <NavLink to={paths.LOGOUT.url} className={({isActive}) => 'mt-auto' + (isActive ? " active-nav" : '')} onClick={e => navigateToPath(e, paths.LOGOUT.url)}><i className='fa-solid fa-right-from-bracket'></i>Logout</NavLink>
                        </>
                    : <></>
            }

        </nav>
    )
}

export default Navigation