import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ExpressService from '../../service/ExpressService';

const initialState = {
    value: null,
    status: 'idle',
    error: null,
    updatePutResult: null,
    updatePutStatus: 'idle',
    updatePutError: null
};

export const fetchColors = createAsyncThunk('colors/fetchColors', async (__, {rejectWithValue}) => {
    const result = await ExpressService.callSFMC('/rest/data/v1/customobjectdata/key/rvw_colors/rowset/', 'GET', 'DE');

    if (result?.name?.includes("Error") && result.message) {
        return rejectWithValue(result.message)
    };

    return result;
});

export const updateColors = createAsyncThunk('colors/updateColors', async (unsavedColorsChanges) => {
    return await ExpressService.callSFMC('/rest/data/v1/async/dataextensions/key:rvw_colors/rows', 'PUT', 'DE', unsavedColorsChanges);
});

const colorsSlice = createSlice({
    name: 'colors',
    initialState,
    reducers: {
        resetColorsPutResults(state) {
            return { ...state, updatePutResult: null, updatePutStatus: 'idle', updatePutError: null };
        }
    },
    extraReducers: {
        [fetchColors.pending]: (state, action) => {
            return {...state, status: 'pending'};
        },
        [fetchColors.fulfilled]: (state, action) => {
            return {...state, status: 'fulfilled', value: action.payload};
        },
        [fetchColors.rejected]: (state, action) => {
            return {...state, status: 'rejected', value: action.payload};
        },
        [updateColors.pending]: (state, action) => {
            return {...state,
                updatePutStatus: 'pending',
                updatePutResult: null,
                updatePutError: null
            };
        },
        [updateColors.fulfilled]: (state, action) => {
            if (action?.payload?.status === '202-Accepted') {
                return {...state,
                    updatePutStatus: 'fulfilled',
                    updatePutResult: action.payload,
                    value: action.meta.arg
                };
            } else {
                return {...state,
                    updatePutStatus: 'fulfilled',
                    updatePutResult: action.payload
                };
            }
        },
        [updateColors.rejected]: (state, action) => {
            return {...state, updatePutStatus: 'rejected'};
        }
    }
});

export const { resetColorsPutResults } = colorsSlice.actions;

export const getColors = state => state?.colors?.value;

export const getColorsStatus = state => state?.colors?.status;

export const getColorsPutResult = state => state?.colors?.updatePutResult;

export const getColorsPutStatus = state => state?.colors?.updatePutStatus;

export default colorsSlice.reducer;