import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ExpressService from "../../service/ExpressService";

const initialState = {
    matchedSubscriberList: null,
    matchedSubscriberListStatus: 'idle',
    matchedSubscriberListError: null,
    currentSubscriber: null,
    currentSubscriberRevenue: null,
    currentSubscriberRevenueStatus: 'idle',
    currentSubscriberRevenueError: null,
    currentSubscriberEmailEngagement: null,
    currentSubscriberEmailEngagementError: null,
    sendEvents: null,
    sendEventsStatus: 'idle',
    sendEventsError: null,
    openEvents: null,
    openEventsStatus: 'idle',
    openEventsError: null,
    clickEvents: null,
    clickEventsStatus: 'idle',
    clickEventsError: null,
    bounceEvents: null,
    bounceEventsStatus: 'idle',
    bounceEventsError: null,
    notSentEvents: null,
    notSentEventsStatus: 'idle',
    notSentEventsError: null
};

// Gets Search Results
export const fetchMatchedSubscriberList = createAsyncThunk('subscriberHisotry/search', async (searchValue, {rejectWithValue}) => {
    const result = await ExpressService.callSFMC('/api/subscribers/search?q=' + encodeURIComponent(searchValue), 'GET');

    if ((result?.data && 'error' in result?.data) || result.data.status !== 'OK') {
        return rejectWithValue(result?.data?.error || `An error has occured while searching for "${searchValue}"`);
    };

    return result?.data?.results;
});

// Gets selected subscriber revenue
export const fetchSubscriberRevenue = createAsyncThunk('subscriberHistory/subscriberRevenue', async(subscriberKey, {rejectWithValue}) => {
    const result = await ExpressService.callSFMC("/rest/data/v1/customobjectdata/key/rvw_orders/rowset?$filter=SubscriberKey%20eq%20'" + encodeURIComponent(subscriberKey) + "'", 'GET', 'DE');

    if (result?.name?.includes('Error') && result.message) {
        return rejectWithValue(result.message)
    }

    return result;
});

// Gets selected subscriber email engagement
export const fetchSubscriberEmailEngagement = createAsyncThunk('subscriberHistory/subscriberEmailEngagement', async (subscriberKey, { rejectWithValue }) => {
    const result = await ExpressService.callSFMC("/rest/data/v1/customobjectdata/key/Contact_Master/rowset?$filter=ID%20eq%20'" + encodeURIComponent(subscriberKey) + "'", 'GET', 'DE');

    if (result?.name?.includes('Error') && result.message) {
        return rejectWithValue(result.message)
    }

    return result;
});

// Gets Sends Events
export const fetchSendEvents = createAsyncThunk('subscriberHisotry/sendEvents', async (subscriberKey, {rejectWithValue}) => {
    const result = await ExpressService.callSFMC('/api/events/' + subscriberKey + '/sends', 'GET');

    if ((result?.data && 'error' in result?.data) || result.data.status !== 'OK') {
        return rejectWithValue(result?.data?.error || `An error has occured while retrieving Send events for "${subscriberKey}"`);
    }

    return result?.data?.results;
});

// Gets Open Events
export const fetchOpenEvents = createAsyncThunk('subscriberHisotry/openEvents', async (subscriberKey, {rejectWithValue}) => {
    const result = await ExpressService.callSFMC('/api/events/' + subscriberKey + '/opens', 'GET');

    if ((result?.data && 'error' in result?.data) || result.data.status !== 'OK') {
        return rejectWithValue(result?.data?.error || `An error has occured while retrieving Open events for "${subscriberKey}"`);
    };

    return result?.data?.results;
});

// Gets Click Events
export const fetchClickEvents = createAsyncThunk('subscriberHisotry/clickEvents', async (subscriberKey, {rejectWithValue}) => {
    const result = await ExpressService.callSFMC('/api/events/' + subscriberKey + '/clicks', 'GET');

    if ((result?.data && 'error' in result?.data) || result.data.status !== 'OK') {
        return rejectWithValue(result?.data?.error || `An error has occured while retrieving Click events for "${subscriberKey}"`);
    };

    return result?.data?.results;
});

// Gets Bounce Events
export const fetchBounceEvents = createAsyncThunk('subscriberHisotry/bounceEvents', async (subscriberKey, {rejectWithValue}) => {
    const result = await ExpressService.callSFMC('/api/events/' + subscriberKey + '/bounces', 'GET');

    if ((result?.data && 'error' in result?.data) || result.data.status !== 'OK') {
        return rejectWithValue(result?.data?.error || `An error has occured while retrieving Bounce events for "${subscriberKey}"`);
    };

    return result?.data?.results;
});

// Gets Not Sent Events
export const fetchNotSentEvents = createAsyncThunk('subscriberHisotry/notSentEvents', async (subscriberKey, {rejectWithValue}) => {
    const result = await ExpressService.callSFMC('/api/events/' + subscriberKey + '/notSent', 'GET');

    if ((result?.data && 'error' in result?.data) || result.data.status !== 'OK') {
        return rejectWithValue(result?.data?.error || `An error has occured while retrieving Not Sent events for "${subscriberKey}"`);
    };

    return result?.data?.results;
});

const subscriberHistorySlice = createSlice({
    name: 'subscriberHistory',
    initialState,
    reducers: {
        resetSubcriberFlags: (state) => {
            return {
                ...state,
                matchedSubscriberList: null,
            };
        },
        resetCurrentSubscriber(state) {
            return {
                ...state,
                currentSubscriber: null,
                currentSubscriberRevenue: null,
                currentSubscriberEmailEngagement: null,
            }
        },
        setCurrentSubscriber(state, action) {
            return { ...state, currentSubscriber: action.payload }
        },
    },
    extraReducers: {
        // Search Results
        [fetchMatchedSubscriberList.pending]: (state, action) => {
            return { ...state, matchedSubscriberListStatus: 'pending' };
        },
        [fetchMatchedSubscriberList.fulfilled]: (state, action) => {
            let result = [];
            if (action.payload && action.payload.constructor.name === 'Object') {
                result.push(action.payload);
            } else if (action.payload && action.payload.constructor.name === 'Array') {
                result = action.payload;
            }
            return {...state, matchedSubscriberListStatus: 'fulfilled', matchedSubscriberList: result};
        },
        [fetchMatchedSubscriberList.rejected]: (state, action) => {
            return {...state, matchedSubscriberListStatus: 'rejected', matchedSubscriberListError: action.payload};
        },
        // Revenue
        [fetchSubscriberRevenue.pending]: (state, action) => {
            return { ...state, currentSubscriberRevenueStatus: 'pending' };
        },
        [fetchSubscriberRevenue.fulfilled]: (state, action) => {
            return {...state, currentSubscriberRevenueStatus: 'fulfilled', currentSubscriberRevenue: action.payload};
        },
        [fetchSubscriberRevenue.rejected]: (state, action) => {
            return {...state, currentSubscriberRevenueStatus: 'rejected', currentSubscriberRevenueError: action.payload};
        },
        // Email Engagements
        [fetchSubscriberEmailEngagement.pending]: (state, action) => {
            return { ...state, currentSubscriberEmailEngagementStatus: 'pending' };
        },
        [fetchSubscriberEmailEngagement.fulfilled]: (state, action) => {
            return {...state, currentSubscriberEmailEngagementStatus: 'fulfilled', currentSubscriberEmailEngagement: action.payload};
        },
        [fetchSubscriberEmailEngagement.rejected]: (state, action) => {
            return {...state, currentSubscriberEmailEngagementStatus: 'rejected', currentSubscriberEmailEngagementError: action.payload};
        },
        // Sends
        [fetchSendEvents.pending]: (state, action) => {
            return { ...state, sendEventsStatus: 'pending' };
        },
        [fetchSendEvents.fulfilled]: (state, action) => {
            return {...state, sendEventsStatus: 'fulfilled', sendEvents: action.payload};
        },
        [fetchSendEvents.rejected]: (state, action) => {
            return {...state, sendEventsStatus: 'rejected', sendEventsError: action.payload};
        },
        // Opens
        [fetchOpenEvents.pending]: (state, action) => {
            return { ...state, openEventsStatus: 'pending' };
        },
        [fetchOpenEvents.fulfilled]: (state, action) => {
            return {...state, openEventsStatus: 'fulfilled', openEvents: action.payload};
        },
        [fetchOpenEvents.rejected]: (state, action) => {
            return {...state, openEventsStatus: 'rejected', openEventsError: action.payload};
        },
        // Clicks
        [fetchClickEvents.pending]: (state, action) => {
            return { ...state, clickEventsStatus: 'pending' };
        },
        [fetchClickEvents.fulfilled]: (state, action) => {
            return {...state, clickEventsStatus: 'fulfilled', clickEvents: action.payload};
        },
        [fetchClickEvents.rejected]: (state, action) => {
            return {...state, clickEventsStatus: 'rejected', clickEventsError: action.payload};
        },
        // Bounces
        [fetchBounceEvents.pending]: (state, action) => {
            return { ...state, bounceEventsStatus: 'pending' };
        },
        [fetchBounceEvents.fulfilled]: (state, action) => {
            return {...state, bounceEventsStatus: 'fulfilled', bounceEvents: action.payload};
        },
        [fetchBounceEvents.rejected]: (state, action) => {
            return {...state, bounceEventsStatus: 'rejected', bounceEventsError: action.payload};
        },
        // Not Sents
        [fetchNotSentEvents.pending]: (state, action) => {
            return { ...state, notSentEventsStatus: 'pending' };
        },
        [fetchNotSentEvents.fulfilled]: (state, action) => {
            return {...state, notSentEventsStatus: 'fulfilled', notSentEvents: action.payload};
        },
        [fetchNotSentEvents.rejected]: (state, action) => {
            return {...state, notSentEventsStatus: 'rejected', notSentEventsError: action.payload};
        }
    }
});

export const { resetSubcriberFlags, resetCurrentSubscriber, setCurrentSubscriber } = subscriberHistorySlice.actions;

// Selected Subscriber
export const getCurrentSubscriber = state => state?.subscriberHistory?.currentSubscriber;

// Subscriber Revenue
export const getCurrentSubscriberRevenue = state => state?.subscriberHistory?.currentSubscriberRevenue;
export const getCurrentSubscriberRevenueStatus = state => state?.subscriberHistory?.currentSubscriberRevenueStatus;
export const getCurrentSubscriberRevenueError = state => state?.subscriberHistory?.currentSubscriberRevenueError;

// Email Engagement
export const getCurrentSubscriberEmailEngagement = state => state?.subscriberHistory?.currentSubscriberEmailEngagement;
export const getCurrentSubscriberEmailEngagementStatus = state => state?.subscriberHistory?.currentSubscriberEmailEngagementStatus;
export const getCurrentSubscriberEmailEngagementError = state => state?.subscriberHistory?.currentSubscriberEmailEngagementError;

// Search Results
export const getMatchedSubscriberList = state => state?.subscriberHistory?.matchedSubscriberList;
export const getMatchedSubscriberListStatus = state => state?.subscriberHistory?.matchedSubscriberListStatus;
export const getMatchedSubscriberListError = state => state?.subscriberHistory?.matchedSubscriberListError;

// SubscriberEvents
export const getSubscriberSendEvents = state => state?.subscriberHistory?.sendEvents;
export const getSubscriberSendEventsStatus = state => state?.subscriberHistory?.sendEventsStatus;
export const getSubscriberOpenEvents = state => state?.subscriberHistory?.openEvents;
export const getSubscriberOpenEventsStatus = state => state?.subscriberHistory?.openEventsStatus;
export const getSubscriberClickEvents = state => state?.subscriberHistory?.clickEvents;
export const getSubscriberClickEventsStatus = state => state?.subscriberHistory?.clickEventsStatus;
export const getSubscriberBounceEvents = state => state?.subscriberHistory?.bounceEvents;
export const getSubscriberBounceEventsStatus = state => state?.subscriberHistory?.bounceEventsStatus;
export const getSubscriberNotSentEvents = state => state?.subscriberHistory?.notSentEvents;
export const getSubscriberNotSentEventsStatus = state => state?.subscriberHistory?.notSentEventsStatus;

export default subscriberHistorySlice.reducer;