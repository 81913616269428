import { configureStore } from '@reduxjs/toolkit';
import meReducer from './meSlice';
import colorsReducer from './edit-brand/colorsSlice';
import stylesReducer from './edit-brand/stylesSlice';
import configsReducer from './edit-config/configsSlice';
import phrasesReducer from './edit-phrases/phrasesSlice';
import triggeredSettingsReducer from './triggered-settings/triggeredSettingsSlice';
import segmentationReducer from './segmentation/segmentationSlice';
import segmentListingsReducer from './segmentation/segmentListingsSlice';
import toastsReducer from './toasts/toastsSlice';
import commonReducers from './commonSlice';
import sidenavLoadingReducer from './sidenav-loading/sidenavLoadingSlice';
import subscriberHistoryReducer from './subscriber-history/subscriberHistorySlice';


export const store = configureStore({
  reducer: {
    me: meReducer,
    colors: colorsReducer,
    styles: stylesReducer,
    configs: configsReducer,
    phrases: phrasesReducer,
    triggerSettings : triggeredSettingsReducer,
    segmentation: segmentationReducer,
    segmentListings: segmentListingsReducer,
    common: commonReducers,
    toasts: toastsReducer,
    sidenavLoading: sidenavLoadingReducer,
    subscriberHistory: subscriberHistoryReducer,
  },
})