import React, { useState, useEffect } from 'react';
import PhraseRow from './PhraseRow';

const PhraseGroup = ({phrases, phraseIndex, onPhraseChange}) => {

    const [englishPhrase, setEnglishPhrase] = useState();
    const [allOtherPhrases, setAllOtherPhrases] = useState([]);
    const [englishPhraseValue, setEnglishPhraseValue] = useState();
    const [hasMadeChangeToPhraseGroup, setHasMadeChangeToPhraseGroup] = useState(false);

    const headerId = "headerId" + phraseIndex;
    const collapseId = "collapseId" + phraseIndex;
    const collapseIdRef = "#" + collapseId;

    const textboxStyle = {
        padding: "12px 15px 0px"
    }

    useEffect(() => {
        var result = phrases.find(obj => {
            return obj.locale == "en_US"
        })
        setEnglishPhraseValue(result.value);
        setEnglishPhrase(result);

        var otherPhrases = phrases.filter(obj => {
            return obj.locale != "en_US"
        })
        setAllOtherPhrases(otherPhrases);
    }, [])

    useEffect(() => {
        if(englishPhraseValue) {
            let phraseObjectSetter = {
                "key": englishPhrase.key,
                "locale": englishPhrase.locale,
                "email": englishPhrase.email,
                "isactive": englishPhrase.isactive,
                "name": englishPhrase.name,
                "value": englishPhraseValue,
                "type": englishPhrase.type
            }
            setEnglishPhrase(phraseObjectSetter);
        }
    },[englishPhraseValue])

    useEffect(() => {
        if (englishPhrase && englishPhrase.value) {
            //Needed to stop onPhraseChange useEffect from executing on initial load, which flips the didPhrasesChange flag
            if (hasMadeChangeToPhraseGroup) {
                onPhraseChange(englishPhrase);
                setHasMadeChangeToPhraseGroup(false);
            }
        }
    }, [englishPhrase])

    function updatePhrase(phraseObject) {
        onPhraseChange(phraseObject)
    }

    return (
        <>
        {englishPhrase && allOtherPhrases
            &&
            <div class="accordion-item">
                <div class="accordion-header custom-accordion-dropdown accordion-button collapsed" id={headerId} data-bs-toggle="collapse" data-bs-target={collapseIdRef} aria-expanded="false" aria-controls={collapseId}>
                    {englishPhrase.name}
                </div>
                <div id={collapseId} class="accordion-collapse collapse" aria-labelledby={headerId} data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <div className='edit-phrases-row'>
                            <div className='style-name-text'>{englishPhrase.locale}</div>
                            <textarea class="form-text-input  form-control" style={englishPhrase.value.length > 59 ? {padding: '12px 15px 10px'} : textboxStyle}
                                type="text" id={englishPhrase.key} name={englishPhrase.key} value={englishPhraseValue.replaceAll('&#34;', '"')}
                                onChange={e => {
                                    setHasMadeChangeToPhraseGroup(true);
                                    setEnglishPhraseValue(e.target.value.replaceAll('"', '&#34;'));}}
                                oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
                            />
                        </div>
                        {(() => {
                            if (allOtherPhrases) {
                                return(
                                    allOtherPhrases.sort((a,b) => a.locale.localeCompare(b.locale)).map((phrase, index) => {
                                        return (
                                            <PhraseRow phrase={phrase} key={index} onPhraseChange={updatePhrase} {...{setHasMadeChangeToPhraseGroup}}/>
                                        )
                                    })
                                )
                            }
                        })()}
                    </div>
                </div>
            </div>
        }
        </>

    )
}

export default PhraseGroup