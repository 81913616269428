import { useEffect, useState } from "react";
import Loading from "../bootstrap-components/Loading";
import './SegmentFieldModal.scss';

const SegmentFieldModal = ({fields, onDropdownSelection}) => {

    const [groupedFields, setGroupedFields] = useState();
    const [searchValue, setSearchValue] = useState();
    const [searchFilteredArray, setSearchFilteredArray] = useState([]);
    const [isSearch, setIsSearch] = useState(false);

    useEffect(() => {
        if(fields) {
            const getGroupedFields = Object.values(fields.sort((a,b) => a.category.localeCompare(b.category)).reduce(function (r, a) {
                r[a.category] = r[a.category] || [];
                r[a.category].push(a);
                return r;
            }, Object.create(null)));
            setGroupedFields(getGroupedFields);
        }
    },[fields])

    useEffect(() => {
        if(searchValue === '') {
            setIsSearch(false);
        }
    },[searchValue])


    function submitOnEnterKey(event) {
        if (event.key === 'Enter') {
            performSearch();
        }
    }

    function performSearch() {
        const searchArray = fields.filter((item) => {
            return item.name.toLowerCase().indexOf(searchValue.toLocaleLowerCase()) !== -1;
        });
        setSearchFilteredArray(searchArray);
        setIsSearch(true);
    }

    function selectField(field) {
        onDropdownSelection(field);
    }

    return(
        <>
            <div className='dropdown-menu dropdown-panel-container'>
                <div className="dropdown-panel">
                    <div className="form-input-prefix-icon">
                        <input type="search" className="form-control" placeholder="Search for Segment Rule ..." ariaLabel="Search" ariaDescribedby="basic-addon2" value={searchValue} onChange={e => setSearchValue(e.target.value)} onKeyUp={submitOnEnterKey} />
                        <i className='fa-solid fa-magnifying-glass'></i>
                    </div>
                    {
                        groupedFields && isSearch === false ?
                            <div className="segment-fields-list-flex">
                                {
                                    groupedFields.map((arrayGroup, index) => {
                                        return (
                                            <div key={"arrayGroup" + index} className="segment-array-group dropdown-section" style={{gridRowEnd: "span " + ((55 + 30 * groupedFields[index].length)/5)}} tt={groupedFields[index].length}>
                                                <div className="dropdown-section-title">{arrayGroup[0].category}</div>
                                                <div className="dropdown-section-body">
                                                    {
                                                        groupedFields[index].map((arrayItem, index) => (
                                                            <div key={"arrayItem" + index} className="segment-array-item" onClick={() => selectField(arrayItem)}>{arrayItem.name}</div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : isSearch === true && searchFilteredArray.length > 0 ?
                                <div className="segment-search-items-flex">
                                    {
                                        searchFilteredArray.slice().sort((a,b) => a.name.localeCompare(b.name)).map((searchItem) => {
                                            return (
                                                <div className="segment-search-item" onClick={() => selectField(searchItem)}>{searchItem.name}</div>
                                            )
                                        })
                                    }
                                </div>
                                : isSearch === true && searchFilteredArray.length === 0
                                    ? <div className="segment-search-no-results">No results returned for search "{searchValue}"</div>
                                    : <div style={{marginTop: "30px"}}><Loading /></div>
                    }
                </div>
            </div>
        </>
    )
}

export default SegmentFieldModal;