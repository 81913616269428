const Modal = ({showModal, secondaryAction, primaryAction, modalHeader, modalBody, modalClose, modalPrimary}) => {

    return (
        <div className="modal" style={{display: showModal ? 'block' : 'none' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{modalHeader}</h5>
                    </div>
                    <div className="modal-body">{modalBody}</div>
                    <div className="modal-footer">
                        <button type="button" class="btn btn-secondary" onClick={secondaryAction}>{modalClose}</button>
                        <button type="button" class="btn btn-primary" onClick={primaryAction}>{modalPrimary}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;