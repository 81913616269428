import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import ExpressService from '../../../service/ExpressService';
import Loading from '../../bootstrap-components/Loading';

const SubscriberStatus = ({setApiError, setHas401Error}) => {

    const [subscriberStatusDE, setSubscriberStatusDE] = useState();
    const [subStatusChartData, setSubStatusChartData] = useState();

    useEffect(() => {
        async function getSubStatusData() {
            const subsSatusDEData = await ExpressService.callSFMC("/rest/data/v1/customobjectdata/key/SubscriberStatus/rowset/", "GET", "DE", undefined, true);
            setSubscriberStatusDE(subsSatusDEData);
            if (subsSatusDEData?.name?.includes("Error") && subsSatusDEData.message) {
                setApiError(subsSatusDEData);
                subsSatusDEData.message.includes("401") && setHas401Error(true);
                setSubStatusChartData(subsSatusDEData);
            }
        }
        getSubStatusData();
    },[])

    useEffect(() => {
        if (Array.isArray(subscriberStatusDE) && subscriberStatusDE?.length === 0) {
            setSubStatusChartData([]);
        } else if (Array.isArray(subscriberStatusDE) && subscriberStatusDE?.length > 0) {
            const formatChartData = subscriberStatusDE.map(x => [x.status.charAt(0).toUpperCase() + x.status.slice(1), Number(x.count)])
            formatChartData.unshift(["Status", "Number of Subscribers"]);
            setSubStatusChartData(formatChartData);
        }
    }, [subscriberStatusDE])

    const options = {
        pieHole: 0.2,
        chartArea: {'width': '100%', 'height': '80%', left: 25, top: 35},
        backgroundColor: 'transparent'
    };

    return (
        <div className='card-body'>
            {
                subStatusChartData?.message?.includes("401")
                    ? <></>
                    : <h5 className='graph-title'>Subscribers' Status</h5>
            }
            {
                subStatusChartData === undefined
                    ? <div className='graph-loader'><Loading /></div>
                    : subStatusChartData.length > 0
                        ?
                            <div style={{width: "444px"}}>
                                <Chart
                                    chartType="PieChart"
                                    data={subStatusChartData}
                                    options={options}
                                    width={"100%"}
                                    height={"339px"}
                                />
                            </div>
                        : subStatusChartData.length === 0
                            ?
                                <div>
                                    No data was found in your "SubscriberStatus" Data Extension. This could be a result of recently running an Autobahn Install. Check back soon. If this issue persists reach out to your Red Van Workshop rep.
                                </div>
                            : subStatusChartData?.message?.includes("404")
                                ?
                                    <div>
                                        The Data Extension was not found.
                                    </div>
                                : <></>

            }

        </div>
    )
}

export default SubscriberStatus