export const emailAnalyticsQueries = (queryType, emailJobId, isParentBu) => {
    let subscriberDataView = isParentBu ?  "_Subscribers" : "ent._Subscribers";

    if (queryType === "sent") {
        return "SELECT s.SubscriberKey AS Id, s.EventDate AS SentDate, j.EmailName, sub.EmailAddress \nFROM _Job j \nINNER JOIN _Sent s \nON j.JobID = s.JobID \nLEFT JOIN " + subscriberDataView + " sub \nON s.SubscriberID = sub.SubscriberID \nWHERE j.JobID = " + "'" + emailJobId + "'"
    } else if (queryType === "open") {
        return "SELECT o.SubscriberKey AS Id, o.EventDate AS OpenDate, j.EmailName, sub.EmailAddress \nFROM _Job j \nINNER JOIN _Open o \nON j.JobID = o.JobID \nLEFT JOIN " + subscriberDataView + " sub \nON o.SubscriberID = sub.SubscriberID \nWHERE j.JobID = " + "'" + emailJobId + "'" + " \nAND o.IsUnique = 1"
    } else if (queryType === "click") {
        return "SELECT c.SubscriberKey AS Id, c.EventDate AS ClickDate, j.EmailName, sub.EmailAddress \nFROM _Job j \nINNER JOIN _Click c \nON j.JobID = c.JobID \nLEFT JOIN " + subscriberDataView + " sub \nON c.SubscriberID = sub.SubscriberID \nWHERE j.JobID = " + "'" + emailJobId + "'" + " \nAND c.IsUnique = 1"
    } else if (queryType === "unsubscribe") {
        return "SELECT u.SubscriberKey AS Id, u.EventDate AS UnsubscribeDate, j.EmailName, sub.EmailAddress \nFROM _Job j \nINNER JOIN _Unsubscribe u \nON j.JobID = u.JobID \nLEFT JOIN " + subscriberDataView + " sub \nON u.SubscriberID = sub.SubscriberID \nWHERE j.JobID = " + "'" + emailJobId + "'" + " \nAND u.IsUnique = 1"
    } else if (queryType === "totalBounce") {
        return "SELECT b.SubscriberKey AS Id, b.EventDate AS BounceDate, b.BounceCategory, b.BounceSubcategory, b.BounceType, j.EmailName, sub.EmailAddress \nFROM _Job j \nINNER JOIN _Bounce b \nON j.JobID = b.JobID \nLEFT JOIN " + subscriberDataView + " sub \nON b.SubscriberID = sub.SubscriberID \nWHERE j.JobID = " + "'" + emailJobId + "'" + " \nAND b.IsUnique = 1"
    } else if (queryType === "softBounce") {
        return "SELECT b.SubscriberKey AS Id, b.EventDate AS BounceDate, b.BounceCategory, b.BounceSubcategory, b.BounceType, j.EmailName, sub.EmailAddress \nFROM _Job j \nINNER JOIN _Bounce b \nON j.JobID = b.JobID \nLEFT JOIN " + subscriberDataView + " sub \nON b.SubscriberID = sub.SubscriberID \nWHERE j.JobID = " + "'" + emailJobId + "'" + " \nAND b.IsUnique = 1 \nAND b.BounceCategoryID = 2"
    } else if (queryType === "hardBounce") {
        return "SELECT b.SubscriberKey AS Id, b.EventDate AS BounceDate, b.BounceCategory, b.BounceSubcategory, b.BounceType, j.EmailName, sub.EmailAddress \nFROM _Job j \nINNER JOIN _Bounce b \nON j.JobID = b.JobID \nLEFT JOIN " + subscriberDataView + " sub \nON b.SubscriberID = sub.SubscriberID \nWHERE j.JobID = " + "'" + emailJobId + "'" + " \nAND b.IsUnique = 1 \nAND b.BounceCategoryID = 1"
    } else if (queryType === "otherBounce") {
        return "SELECT b.SubscriberKey AS Id, b.EventDate AS BounceDate, b.BounceCategory, b.BounceSubcategory, b.BounceType, j.EmailName, sub.EmailAddress \nFROM _Job j \nINNER JOIN _Bounce b \nON j.JobID = b.JobID \nLEFT JOIN " + subscriberDataView + " sub \nON b.SubscriberID = sub.SubscriberID \nWHERE j.JobID = " + "'" + emailJobId + "'" + " \nAND b.IsUnique = 1 \nAND b.BounceCategoryID != 1 \nAND b.BounceCategoryID != 2"
    } else if (queryType === "delivered") {
        return "SELECT DISTINCT s.SubscriberKey AS Id, s.EventDate AS SentDate, j.EmailName, sub.EmailAddress \nFROM _Sent s \nINNER JOIN _Job j \nON s.JobID = j.JobID \nLEFT JOIN " + subscriberDataView + " sub \nON s.SubscriberKey = sub.SubscriberKey \nLEFT JOIN NotSentExtract n \nON s.SubscriberKey = n.SubscriberKey AND s.JobID = n.SendID AND s.BatchID = n.BatchID \nLEFT JOIN _Bounce b \nON s.SubscriberKey = b.SubscriberKey AND s.JobID = b.JobID AND s.BatchID = b.BatchID \nWHERE j.JobID = " + "'" + emailJobId + "'" + " \nAND b.SubscriberKey IS NULL \nAND n.SubscriberKey IS NULL"
    } else if (queryType === "totalErrors") {
        return "SELECT n.SubscriberKey AS Id, n.EmailAddress, n.EventDate, j.EmailName, n.EventType, n.Reason \nFROM _Job j \nINNER JOIN NotSentExtract n \nON j.JobID = n.SendID \nWHERE j.JobID = " + "'" + emailJobId + "'"
    } else if (queryType === "totalExcluded") {
        return "SELECT DISTINCT s.SubscriberKey AS Id, s.EventDate AS SentDate, j.EmailName, sub.EmailAddress, \nCASE \nWHEN b.SubscriberKey IS NOT NULL THEN b.BounceCategory \nWHEN n.SubscriberKey IS NOT NULL THEN 'Error' \nEND AS NotDeliveredType, \nb.BounceSubcategory, n.Reason AS ErrorReason \nFROM _Sent s \nINNER JOIN _Job j \nON s.JobID = j.JobID \nLEFT JOIN _Subscribers sub \nON s.SubscriberKey = sub.SubscriberKey \nLEFT JOIN NotSentExtract n \nON s.SubscriberKey = n.SubscriberKey AND s.JobID = n.SendID \nLEFT JOIN _Bounce b \nON s.SubscriberKey = b.SubscriberKey AND s.JobID = b.JobID \nWHERE j.JobID = " + "'" + emailJobId + "'" + " \nAND (b.SubscriberKey IS NOT NULL OR n.SubscriberKey IS NOT NULL)"
    } else if (queryType === "conversions") {
        return "SELECT DISTINCT s.email AS Id, sub.EmailAddress, p.EmailName, sum(p.TotalPurchasePerSession) AS TotalPurchases \nFROM PurchasesBySessionDataView p \nINNER JOIN IGO_PROFILES s \nON p.user_id = s.user_id \nLEFT JOIN " + subscriberDataView + " sub \nON s.email = sub.SubscriberKey \nGROUP BY p.Job_ID, s.email, p.EmailName, sub.EmailAddress \nHAVING p.Job_ID = '" + emailJobId + "'"
    }
}