import React from 'react';
import './Title.scss';
import '../../styles/components/_headers.scss';

const Title = ({title, buttonText, saveAction}) => {

    return (
        <div className='page-title-container'>
            <div className='page-title'>{title}</div>
            { buttonText ? <button className='btn-primary' onClick={e => saveAction()}>{buttonText}</button> : <></> }
        </div>
    )
}

export default Title