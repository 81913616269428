import React, { useEffect, useState } from 'react';
import ExpressService from '../../../service/ExpressService';
import Loading from '../../bootstrap-components/Loading';
import '../../../styles/components/_rateHeatMap.scss';

const OpenRateHeatMap = ({setApiError, setHas401Error}) => {

    const [openRateDE, setOpenRateDE] = useState();
    const [openRateHeatMapData, setOpenRateHeatMapData] = useState();

    useEffect(() => {
        async function getOpenRateData() {
            const openRateData = await ExpressService.callSFMC("/rest/data/v1/customobjectdata/key/HeatMap_Opens/rowset/", "GET", "DE", undefined, true);
            setOpenRateDE(openRateData);
            if (openRateData?.name?.includes("Error") && openRateData.message) {
                setApiError(openRateData);
                openRateData.message.includes("401") && setHas401Error(true);
                setOpenRateHeatMapData(openRateData);
            }
        }
        getOpenRateData();
    },[])

    useEffect(() => {
        if (Array.isArray(openRateDE) && openRateDE?.length === 0) {
            setOpenRateHeatMapData([]);
        } else if (Array.isArray(openRateDE) && openRateDE?.length > 0) {
            const openRateValues = openRateDE.filter(x => x.openratebytime !== "").map(function(x) {
                return {...x, openratebytimeNumber: Number(x.openratebytime)}
            })
                .sort((x,y) => y.openratebytimeNumber - x.openratebytimeNumber)
                .map((z, index) => {
                    if (index <= 7) {
                        return {...z, hexcode: "#5fb761"}
                    } else if (index <= 14) {
                        return {...z, hexcode: "#70bf73"}
                    } else if (index <= 21) {
                        return {...z, hexcode: "#82c784"}
                    } else if (index <= 28) {
                        return {...z, hexcode: "#94cf95"}
                    } else if (index <= 35) {
                        return {...z, hexcode: "#a5d7a7"}
                    } else if (index <= 42) {
                        return {...z, hexcode: "#b7dfb8"}
                    } else if (index <= 49) {
                        return {...z, hexcode: "#c9e7c9"}
                    } else if (index <= 56) {
                        return {...z, hexcode: "#daefdb"}
                    } else if (index <= 63) {
                        return {...z, hexcode: "#ecf7ec"}
                    } else {
                        return {...z, hexcode: "#fefffe"}
                    }
                });
            const finalArray = [...openRateValues, ...openRateDE.filter(x => x.openratebytime === "")]
            setOpenRateHeatMapData(finalArray);
        }
    }, [openRateDE])

    function getStyle(hexcode) {
        if (hexcode) {
            return {backgroundColor: hexcode}
        } else {
            return {backgroundColor: "transparent"}
        }
    }

    const getCells = () => {
        let cellArray = [];

        for (let i = 0; i < 24; i++) {
            const monday = openRateHeatMapData.filter(x => Number(x.hour) === i && x.weekday === "Monday")[0];
            const tuesday = openRateHeatMapData.filter(x => Number(x.hour) === i && x.weekday === "Tuesday")[0];
            const wednesday = openRateHeatMapData.filter(x => Number(x.hour) === i && x.weekday === "Wednesday")[0];
            const thursday = openRateHeatMapData.filter(x => Number(x.hour) === i && x.weekday === "Thursday")[0];
            const friday = openRateHeatMapData.filter(x => Number(x.hour) === i && x.weekday === "Friday")[0];
            const saturday = openRateHeatMapData.filter(x => Number(x.hour) === i && x.weekday === "Saturday")[0];
            const sunday = openRateHeatMapData.filter(x => Number(x.hour) === i && x.weekday === "Sunday")[0];

            cellArray.push(<div className='heatmap-grid-cell' style={getStyle(monday.hexcode)}><div>{monday.openratebytime ? ((Number(monday.openratebytime)) * 100).toFixed(2) + '%' : "-"}</div></div>)
            cellArray.push(<div className='heatmap-grid-cell' style={getStyle(tuesday.hexcode)}><div>{tuesday.openratebytime ? ((Number(tuesday.openratebytime)) * 100).toFixed(2) + '%' : "-"}</div></div>)
            cellArray.push(<div className='heatmap-grid-cell' style={getStyle(wednesday.hexcode)}><div>{wednesday.openratebytime ? ((Number(wednesday.openratebytime)) * 100).toFixed(2) + '%' : "-"}</div></div>)
            cellArray.push(<div className='heatmap-grid-cell' style={getStyle(thursday.hexcode)}><div>{thursday.openratebytime ? ((Number(thursday.openratebytime)) * 100).toFixed(2) + '%' : "-"}</div></div>)
            cellArray.push(<div className='heatmap-grid-cell' style={getStyle(friday.hexcode)}><div>{friday.openratebytime ? ((Number(friday.openratebytime)) * 100).toFixed(2) + '%' : "-"}</div></div>)
            cellArray.push(<div className='heatmap-grid-cell' style={getStyle(saturday.hexcode)}><div>{saturday.openratebytime ? ((Number(saturday.openratebytime)) * 100).toFixed(2) + '%' : "-"}</div></div>)
            cellArray.push(<div className='heatmap-grid-cell' style={getStyle(sunday.hexcode)}><div>{sunday.openratebytime ? ((Number(sunday.openratebytime)) * 100).toFixed(2) + '%' : "-"}</div></div>)
        }

        return cellArray;
    }

    return (
        <div className='card-body'>
            {
                openRateHeatMapData?.message?.includes("401")
                    ? <></>
                    : <h5 className='graph-title'>Open Rate by Send Time</h5>
            }

            {
                openRateHeatMapData === undefined
                    ? <div className='graph-loader'><Loading /></div>
                    : openRateHeatMapData.length > 0
                        ?
                            <div className='full-grid-container'>
                                <div className='weekday-grid-x'>
                                    <div></div>
                                    <div className='weekday-header'>Monday</div>
                                    <div className='weekday-header'>Tuesday</div>
                                    <div className='weekday-header'>Wednesday</div>
                                    <div className='weekday-header'>Thursday</div>
                                    <div className='weekday-header'>Friday</div>
                                    <div className='weekday-header'>Saturday</div>
                                    <div className='weekday-header'>Sunday</div>
                                </div>
                                <div className='bottom-grid-container'>
                                    <div className='time-grid-y'>
                                        <div className='time-y-row'><div>12AM</div></div>
                                        <div className='time-y-row'><div>1AM</div></div>
                                        <div className='time-y-row'><div>2AM</div></div>
                                        <div className='time-y-row'><div>3AM</div></div>
                                        <div className='time-y-row'><div>4AM</div></div>
                                        <div className='time-y-row'><div>5AM</div></div>
                                        <div className='time-y-row'><div>6AM</div></div>
                                        <div className='time-y-row'><div>7AM</div></div>
                                        <div className='time-y-row'><div>8AM</div></div>
                                        <div className='time-y-row'><div>9AM</div></div>
                                        <div className='time-y-row'><div>10AM</div></div>
                                        <div className='time-y-row'><div>11AM</div></div>
                                        <div className='time-y-row'><div>12PM</div></div>
                                        <div className='time-y-row'><div>1PM</div></div>
                                        <div className='time-y-row'><div>2PM</div></div>
                                        <div className='time-y-row'><div>3PM</div></div>
                                        <div className='time-y-row'><div>4PM</div></div>
                                        <div className='time-y-row'><div>5PM</div></div>
                                        <div className='time-y-row'><div>6PM</div></div>
                                        <div className='time-y-row'><div>7PM</div></div>
                                        <div className='time-y-row'><div>8PM</div></div>
                                        <div className='time-y-row'><div>9PM</div></div>
                                        <div className='time-y-row'><div>10PM</div></div>
                                        <div className='time-y-row'><div>11PM</div></div>
                                    </div>
                                    <div className='day-time-grid'>
                                        {
                                            openRateHeatMapData ? getCells() : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        : openRateHeatMapData.length === 0
                            ?
                                <div style={{marginTop: "10px"}}>
                                    No data was found in your "HeatMap_Opens" Data Extension. This could be a result of recently running an Autobahn Install. Check back soon. If this issue persists reach out to your Red Van Workshop rep.
                                </div>
                            : openRateHeatMapData?.message?.includes("404")
                                ?
                                    <div style={{marginTop: "10px"}}>
                                        The Data Extension was not found.
                                    </div>
                                : <></>
            }

        </div>
    )

}

export default OpenRateHeatMap