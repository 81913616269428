import { useEffect, useState } from 'react';
import GoogleAnalyticsService from '../../service/GoogleAnalyticsService';
import Loading from '../bootstrap-components/Loading';
import { useSelector } from 'react-redux';
import { getMid } from '../../redux/meSlice';
import './UploadLogo.scss';

const UploadLogo = () => {

    const [base64Image, setBase64Image] = useState();
    const [uploadLogoResultOK, setUploadLogoResultOK] = useState(false);
    const [uploadLogoLoading, setUploadLogoLoading] = useState(false);
    const [uploadLogoErrorMessage, setUploadLogoErrorMessage] = useState();
    const [showUploadLogoResultMessage, setShowUploadLogoResultMessage] = useState(false);
    const [legacyLogoId, setLegacyLogoId] = useState();
    const mid = useSelector(getMid);

    useEffect(() => {
        getLogoLegacyId();
    },[mid])


    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                const srcData = fileReader.result;
                const base64StartingIndex = srcData?.indexOf(',');
                base64StartingIndex ? resolve(fileReader.result.slice(base64StartingIndex + 1)) : reject(new Error('Error reading file'));
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    }

    const convertFile = async (e) => {
        const file = e.target.files[0];
        try{
            const base64 = await convertToBase64(file);
            base64 && setBase64Image(base64);
        } catch (e) {
            // handle error here
        }
    }

    const requestBody = {
        name: 'Brand Logo Desktop',
        assetType: {
            name: 'png',
            id: 28
        },
        file: base64Image
    }

    const requestOptions = {
        method: 'PATCH',
        body: JSON.stringify(requestBody)
    };

    async function uploadLogo() {
        setUploadLogoResultOK(false);
        setUploadLogoLoading(true);
        var res = await fetch('/rest/asset/v1/content/assets/' + legacyLogoId, requestOptions);
        GoogleAnalyticsService.triggerEvent("sfmc_action", "uploaded_logo");
        setShowUploadLogoResultMessage(true);
        setTimeout(() => {setShowUploadLogoResultMessage(false)}, 5000);

        if (!res.ok) {
            const message = `An error has occured: ${res.status} - ${res.statusText}`;
            setUploadLogoErrorMessage(message);
            setUploadLogoLoading(false);
            return;
        }

        const data = await res.json();

        if (data) {
            setUploadLogoResultOK(true);
        } else {
            setUploadLogoErrorMessage('Error');
        }

        setUploadLogoLoading(false);
    }

    async function getLogoLegacyId() {
        var res = await fetch("/rest/asset/v1/content/assets/?$filter=CustomerKey%20eq%20'brand_logo_desktop_" + mid + "'&$fields=legacyData");

        if (!res.ok) {
            setLegacyLogoId('Error');
            return;
        }

        const data = await res.json();

        if (data) {
            setLegacyLogoId(data.items[0].id);
        } else {
            setLegacyLogoId('Error');
        }
    }

    return (
        <div className='d-flex justify-content-center flex-wrap mt-4'>
            {
                legacyLogoId && legacyLogoId !== 'Error' ?
                    <>
                        <div className='w-100 mb-2'>Please select a PNG to upload.</div>
                        <div className='input-group w-auto custom-file-button'>
                            <label class='input-group-text' for='inputGroupFile01'>Upload Logo</label>
                            <input class='form-control' type='file' id='inputGroupFile01' accept='image/png' onChange={(e) => convertFile(e)}/>
                        </div>
                        <div className='w-100'>
                            {uploadLogoLoading
                                ? <div className='mt-3'><Loading /></div>
                                : <button className='btn-secondary edit-de-btn' onClick={() => base64Image && uploadLogo()}>Save</button>
                            }
                        </div>
                        <div className='w-100'>
                            {showUploadLogoResultMessage
                                ? uploadLogoResultOK
                                    ? <p className='success-message'>Your logo was updated. It can take up to 10 minutes for this change to be reflected in Marketing Cloud.</p>
                                    : <p className='error-message'>{uploadLogoErrorMessage}</p>
                                : <></>
                            }
                        </div>
                    </>
                    : legacyLogoId === 'Error' 
                        ? <div className='mt-3'>There was an error. Please reach out to your Red Van Workshop rep if this persists.</div>
                        : <div className='mt-3'><Loading /></div>
                
            }
        </div>
    )
}

export default UploadLogo;