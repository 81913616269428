import React, {useState} from 'react';
import './CampaignsRow.scss';
import Loading from '../bootstrap-components/Loading';
import IndividualEmailAnalytics from './IndividualEmailAnalytics';

const CampaignsRow = ({emailSend, rowNum}) => {

    const sendDateFormated = new Date(emailSend.SendDate).toLocaleDateString('en-us', { month: '2-digit', day: 'numeric', year: '2-digit', hour: "numeric", minute: "numeric" });
    const [individualAnalyticsRow, setIndividualAnalyticsRow] = useState(null);

    function expandIndividualAnalytics() {
        setIndividualAnalyticsRow(
            <IndividualEmailAnalytics emailObject={emailSend} />
        )
    }

    return (
        <>
            <tr data-bs-toggle="collapse" data-bs-target={".row" + rowNum} onClick={() => expandIndividualAnalytics()}>
                <th scope="row">
                    <div>{emailSend.EmailName}</div>
                </th>
                <td>
                    <div>{emailSend.ID}</div>
                </td>
                <td>
                    <div>{sendDateFormated}</div>
                </td>
                <td>
                    <div>{emailSend.NumberSent}</div>
                </td>
                <td>
                    <div>{emailSend.OpenRate}</div>
                </td>
                <td>
                    <div>{emailSend.ClickRate}</div>
                </td>
                <td>
                    <div>{emailSend.BounceRate}</div>
                </td>
            </tr>
            <tr>
                <td colSpan={7}>
                    <div className={`collapse ${' row' + rowNum}`} data-bs-parent="tbody">
                        {individualAnalyticsRow || <div><Loading /></div> }
                    </div>
                </td>
            </tr>
        </>
    )
}

export default CampaignsRow