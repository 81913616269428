import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    toastsArray: []
};

const toastsSlice = createSlice({
    name: 'toasts',
    initialState,
    reducers: {
        addToastToArray(state, toastObject) {
            return {
                ...state,
                toastsArray: [...state.toastsArray, toastObject.payload]
            }
        },
        removeToastFromArray(state, toastKey) {
            return {
                ...state,
                toastsArray: state.toastsArray.filter(toastItem => {return toastItem.key !== toastKey.payload})
            }
        }
    }
});

export const { addToastToArray, removeToastFromArray } = toastsSlice.actions;
export const getToastArray = state => state?.toasts?.toastsArray;

export default toastsSlice.reducer;