import React, { useEffect, useState } from 'react';
import ColorDropdown from './ColorDropdown';
import { ChromePicker } from 'react-color';
import './StyleRow.scss';

const StyleRowColorDropdown = ({onStyleChange, style, colors}) => {

    const [valueValue, setValueValue] = useState('');
    const [nameValue, setNameValue] = useState('');
    const [colorSwatch, setColorSwatch] = useState({});
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [pickerStyles, setPickerStyles] = useState({});
    const [hasMadeChange, setHasMadeChange] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (colors) {
            setValueValue(style.value);
            setNameValue(getColorObj(style.value)?.name || '');
        }

        let colorSwatchSetter = {
            background: style.value
        }

        setColorSwatch(colorSwatchSetter);
    }, [])

    const [styleObject, setStyleObject] = useState(null);

    useEffect(() => {
        let styleObjectSetter = {
            "key": style.key,
            "value": valueValue,
            "name": style.name,
            "isactive": style.isactive,
            "category": style.category,
            "sub_category": style.sub_category,
            "input_identifier": style.input_identifier,
            "input_type": style.input_type
        }
        setStyleObject(styleObjectSetter);
        let colorSwatchSetter = {
            background: valueValue
        }
        setColorSwatch(colorSwatchSetter);
    }, [valueValue])

    useEffect(() => {
        if (styleObject?.value) {
            //Needed to stop onStyleChange useEffect from executing on initial load, which flips the didStylesChange flag
            if (hasMadeChange) {
                onStyleChange(styleObject);
                setHasMadeChange(false);
            }
        }
    }, [styleObject])

    function changeColor(hex, name) {
        if(hex) {
            setHasMadeChange(true);
            setValueValue(hex);
            setNameValue(name);
        }
    }

    const showColorPicker = () => {
        setDisplayColorPicker(true);
    }

    const closeColorPicker = () => {
        setDisplayColorPicker(false);
    }

    function setMousePos(event) {
        let xCoordinate = event.clientX + 15;
        let distanceFromBottom = window.innerHeight - event.clientY;
        if (distanceFromBottom < 255) {
            var yCoordinate = window.innerHeight - 260;
        } else {
            var yCoordinate = event.clientY - 30;
        }
        let pickerStylesSetter = {
            left: xCoordinate,
            top: yCoordinate
        }
        setPickerStyles(pickerStylesSetter);
        showColorPicker();
    }

    function getColorObj(hexValue) {
        return colors.find((colorObj) => colorObj?.hexcode?.toLowerCase() === hexValue.toLowerCase());
    }


    return (
            <div className='style-color-container'>
                <div>{style.input_identifier}</div>
                <div className='d-flex flex-row align-items-center color-swatch-gap'>
                    <div className='color-swatch' style={colorSwatch} onClick={setMousePos}></div>
                    {displayColorPicker
                        ?   <div className='color-picker-container' style={pickerStyles}>
                                <div className='color-picker-cover' onClick={closeColorPicker} />
                        <ChromePicker className='chrome-picker' color={valueValue} onChange={(color) => { setHasMadeChange(true); setNameValue(''); setValueValue(color.hex)}} />
                            </div>
                        : null
                    }
                    <div class="style-color-input-container form-control-dropdown-container">
                    <input type="text" class="form-control color-form-control style-dropdown-input form-control-dropdown" aria-label="Text input with dropdown button"
                        value={(isFocused ? valueValue : nameValue) || valueValue}
                        onChange={e => { setHasMadeChange(true); setNameValue(''); setValueValue(e.target.value.replaceAll('"', '&#34;')) }}
                        onFocus={() => setIsFocused(true) }
                        onBlur={() => { setNameValue(getColorObj(valueValue)?.name || ''); setIsFocused(false) }}
                    />
                        <div className="dropdown-icon-container d-flex align-items-center">
                            <div className="dropdown-icon" data-bs-toggle="dropdown" aria-expanded="false"></div>
                            <div className="dropdown-menu colors-menu">
                                {colors.slice().sort((a,b) => a.key.localeCompare(b.key)).map((color) => (
                                    <ColorDropdown key={color.key} color={color} onColorSelect={changeColor} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default StyleRowColorDropdown