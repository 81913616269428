import React, { useEffect, useState, useRef } from 'react';
import { ChromePicker } from 'react-color';
import './BrandPage.scss';
import { Tooltip } from 'react-tooltip';
import { ALERT_TYPE, showAlert } from '../../redux/toasts/toastHelpers';
import { useDispatch } from 'react-redux';

const AddColor = ({onColorAdd, colorList}) => {

    const dispatch = useDispatch();
    const colorSwatchRef = useRef();

    const [hexcode, setHexcode] = useState('#ffffff');
    const [nameValue, setNameValue] = useState('');
    const [colorSwatch, setColorSwatch] = useState({});
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [pickerStyles, setPickerStyles] = useState({});
    const initialColorSwatchSetter = {
        background: '#ffffff'
    }

    useEffect(() => {
        setColorSwatch(initialColorSwatchSetter);
    }, [])

    useEffect(() => {
        let colorSwatchSetter = {
            background: hexcode
        }
        setColorSwatch(colorSwatchSetter);
    }, [hexcode])

    const showColorPicker = () => {
        setDisplayColorPicker(true);
    }

    const closeColorPicker = () => {
        setDisplayColorPicker(false);
    }

    function setMousePos(event) {
        let xCoordinate = event.clientX + 15;
        let distanceFromBottom = window.innerHeight - event.clientY;
        if (distanceFromBottom < 255) {
            var yCoordinate = window.innerHeight - 260;
        } else {
            var yCoordinate = event.clientY - 30;
        }
        let pickerStylesSetter = {
            left: xCoordinate,
            top: yCoordinate
        }
        setPickerStyles(pickerStylesSetter);
        showColorPicker();
    };

    function addColorAction() {
        if (typeof nameValue === "string" && nameValue !== '') {
            let existingColorObj = colorList && colorList.find((color) => color.hexcode === hexcode);
            if (!existingColorObj) {
                let colorObjectSetter = {
                    "key": nameValue.replace(/\s/g, ''),
                    "name": nameValue,
                    "hexcode": hexcode,
                    "type": 'color'
                }
                onColorAdd(colorObjectSetter);
                setHexcode('#ffffff');
                setNameValue('');
                setColorSwatch(initialColorSwatchSetter);
            } else { // duplicate color
                dispatch(showAlert(ALERT_TYPE.DANGER, `This color is already associated with the name ${existingColorObj.name}`));
            }
        }
    }

    return (
        <div div className="d-flex flex-row m-auto add-color-container position-relative" >
                <div className='add-color-icon-div' data-tooltip-id={"my-tooltip-color-add"} data-tooltip-content={"Disabled - No Changes Made"} data-tooltip-place="bottom"><i class="fa-solid fa-plus" onClick={e => {addColorAction()}}></i></div>
                { nameValue === '' && <div className='position-absolute'><Tooltip id={"my-tooltip-color-add"} /></div> }
                <div id='add-color-swatch' className="color-swatch" style={colorSwatch} onClick={setMousePos} ref={colorSwatchRef}></div>
                {displayColorPicker
                    ?   <div className='color-picker-container' style={pickerStyles}>
                            <div className='color-picker-cover' onClick={closeColorPicker} />
                            <ChromePicker color={hexcode} onChange={(color) => {setHexcode(color.hex);}} />
                        </div>
                    : null
                }
                <input className="form-text-input w-100 form-control" placeholder="Add new color ..." type="text" id='add-color-input' name='add-color-input' value={nameValue.replaceAll('&#34;', '"')} onChange={e => {setNameValue(e.target.value.replaceAll('"', '&#34;'))}} />
        </div>
    )
}

export default AddColor