import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showSidenavLoading: false,
    isQueryComplete: false,
    activeDeKey: null,
    activeQueryId: null,
};

const sidenavLoadingSlice = createSlice({
    name: 'sidenavLoading',
    initialState,
    reducers: {
        resetQueryFlags(state) {
            return { ...state, isQueryComplete: null, activeDeKey: null, activeQueryId: null };
        },
        setShowSidenavLoading(state, param) {
            return { ...state, showSidenavLoading: param.payload };
        },
        setIsQueryComplete(state, param) {
            return { ...state, isQueryComplete: param.payload };
        },
        setSidenavLoadingActiveDeKey(state, param) {
            return { ...state, activeDeKey: param.payload };
        },
        setSidenavLoadingActiveQueryId(state, param) {
            return { ...state, activeQueryId: param.payload };
        },
    }
});

export const { resetQueryFlags, setShowSidenavLoading, setIsQueryComplete, setSidenavLoadingActiveDeKey, setSidenavLoadingActiveQueryId } = sidenavLoadingSlice.actions;

export const getShowSidenavLoading = state => state?.sidenavLoading?.showSidenavLoading;
export const getIsQueryComplete = state => state?.sidenavLoading?.isQueryComplete;
export const getSidenavLoadingActiveDeKey = state => state?.sidenavLoading?.activeDeKey;
export const getSidenavLoadingActiveQueryId = state => state?.sidenavLoading?.activeQueryId;

export default sidenavLoadingSlice.reducer;