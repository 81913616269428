import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ExpressService from '../../service/ExpressService';

const initialState = {
    value: null,
    status: 'idle',
    error: null,
    updatePutResult: null,
    updatePutStatus: 'idle',
    updatePutError: null
};

export const fetchConfigs = createAsyncThunk('configs/fetchConfigs', async (__, {rejectWithValue}) => {
    const result = await ExpressService.callSFMC('/rest/data/v1/customobjectdata/key/rvw_conf/rowset/', 'GET', 'DE');

    if (result?.name?.includes("Error") && result.message) {
        return rejectWithValue(result.message)
    };

    return result;
});

export const updateConfigs = createAsyncThunk('configs/updateConfigs', async (unsavedConfigsChanges) => {
    return await ExpressService.callSFMC('/rest/data/v1/async/dataextensions/key:rvw_conf/rows', 'PUT', 'DE', unsavedConfigsChanges);
});

const configsSlice = createSlice({
    name: 'configs',
    initialState,
    reducers: {
        resetPutResults(state) {
            return { ...state, updatePutResult: null, updatePutStatus: 'idle', updatePutError: null };
        }
    },
    extraReducers: {
        [fetchConfigs.pending]: (state, action) => {
            return {...state, status: 'pending'};
        },
        [fetchConfigs.fulfilled]: (state, action) => {
            return {...state, status: 'fulfilled', value: action.payload};
        },
        [fetchConfigs.rejected]: (state, action) => {
            return {...state, status: 'rejected',  value: action.payload};
        },
        [updateConfigs.pending]: (state, action) => {
            return {...state,
                updatePutStatus: 'pending',
                updatePutResult: null,
                updatePutError: null
            };
        },
        [updateConfigs.fulfilled]: (state, action) => {
            if (action?.payload?.status === '202-Accepted') {
                return {...state,
                    updatePutStatus: 'fulfilled',
                    updatePutResult: action.payload,
                    value: action.meta.arg
                };
            } else {
                return {...state,
                    updatePutStatus: 'fulfilled',
                    updatePutResult: action.payload
                };
            }
        },
        [updateConfigs.rejected]: (state, action) => {
            return {...state, updatePutStatus: 'rejected'};
        }
    }
});

export const { resetPutResults } = configsSlice.actions;

export const getConfigs = state => state?.configs?.value;

export const getConfigsStatus = state => state?.configs?.status;

export const getConfigsPutResult = state => state?.configs?.updatePutResult;

export default configsSlice.reducer;