import React, { useEffect, useState } from 'react';
import ExpressService from '../../service/ExpressService';
import Loading from '../bootstrap-components/Loading';
import EmailPreviewDropdownOptions from './EmailPreviewDropdownOptions';
import './EmailPreview.scss';
import EmailPreviewSettings from './EmailPreviewSettings';
import { useDispatch } from 'react-redux';
import { ALERT_TYPE, showAlert } from '../../redux/toasts/toastHelpers';

const EmailPreview = ({reloadEmailPreview, setEmailError}) => {

    const textStyle = {
        margin: '30px auto',
        padding: '0px 15px'
    }

    const dispatch = useDispatch();
    const [emailPreviewPostRequest, setEmailPreviewPostRequest] = useState(null);
    const [reloadEmailPreviewCount, setReloadEmailPreviewCount] = useState(0);
    const [activeEmail, setActiveEmail] = useState();
    const [triggeredSendSettingsDE, setTriggeredSendSettingsDE] = useState();
    const [dropdownSelectedTriggers, setDropdownSelectedTriggers] = useState();
    const [showTriggeredSettings, setShowTriggeredSettings] = useState(false);
    const [initialTriggeredSettingsData, setInitialTriggeredSettingsData] = useState();
    const [unsavedTriggeredSettingsData, setUnsavedTriggeredSettingsData] = useState();
    const [isTriggeredSettingsSaving, setIsTriggeredSettingsSaving] = useState();

    useEffect(() =>{
        if(reloadEmailPreview > 0 && reloadEmailPreview > reloadEmailPreviewCount) {
            setEmailPreviewPostRequest(null);
            setTimeout(function () {
                fetchEmailPreview(
                    activeEmail.values.email_id,
                    activeEmail.values.dataextensionexternalkey,
                    activeEmail.values.dataextensionrow
                );
                let tempReloadEmailPreviewCount = reloadEmailPreviewCount;
                let newReloadEmailPreviewCount = tempReloadEmailPreviewCount + 1;
                setReloadEmailPreviewCount(newReloadEmailPreviewCount);
            }, 1000)
        }
        // need to update this to sync and remove the manual delay
    }, [reloadEmailPreview])

    async function fetchEmailPreview(emailId, deExternalKey, rowNumber) {
        setEmailPreviewPostRequest(null);
        const requestUrl = "/rest/guide/v1/emails/" + emailId + "/dataExtension/key:" + deExternalKey + "/row/" + rowNumber + "/preview"
        async function postEmailPreview() {
            const emailPreviewPostCall = await ExpressService.callSFMC(requestUrl, "POST");
            setEmailPreviewPostRequest(emailPreviewPostCall);
        }
        postEmailPreview();
    }

    useEffect(() => {
        async function getTriggeredSendSettingsDE() {
            const getSettingsCall = await ExpressService.callSFMC('/rest/data/v1/customobjectdata/key/TriggeredSendSettings/rowset/', 'GET', 'sync DE');
            if (getSettingsCall?.name?.includes("Error") && getSettingsCall.message) {
                setEmailError(getSettingsCall);
            }
            if (getSettingsCall.length === 0) {
                setEmailPreviewPostRequest("No Trigger Data");
            }

            setTriggeredSendSettingsDE(getSettingsCall);
        }
        getTriggeredSendSettingsDE();
    }, [])

    useEffect(() => {
        if(triggeredSendSettingsDE && Array.isArray(triggeredSendSettingsDE)) {
            setUnsavedTriggeredSettingsData(triggeredSendSettingsDE);

            const filteredTriggers = triggeredSendSettingsDE.filter((x) => x.values.isdisplayedinpreviewdropdown === "True").sort((a,b) => a.values.name.localeCompare(b.values.name));
            setDropdownSelectedTriggers(filteredTriggers);
            setActiveEmail(filteredTriggers[0]);
        }
    },[triggeredSendSettingsDE])

    useEffect(() => {
        if(activeEmail) {
            fetchEmailPreview(
                activeEmail.values.email_id,
                activeEmail.values.dataextensionexternalkey,
                activeEmail.values.dataextensionrow
            )
        }
    }, [activeEmail])

    function upsertArray(array, element) {
        const tempArray = [...array];
        const i = array.findIndex(_element => (_element.keys.triggeredsendexternalkey === element.keys.triggeredsendexternalkey));
        if (i > -1) {
            tempArray[i] = element;
        } else {
            tempArray.push(element);
        }
        return tempArray;
    }

    function updateTriggerSettingsArray(triggerSettingsRowObject) {
        if (triggerSettingsRowObject) {
            const updatedArray = upsertArray(unsavedTriggeredSettingsData, triggerSettingsRowObject);
            setUnsavedTriggeredSettingsData(updatedArray);
        }
    }

    async function handleSaveTriggerSettingsClick() {
        setIsTriggeredSettingsSaving(true);
        const data = await ExpressService.callSFMC('/rest/hub/v1/dataevents/key:TriggeredSendSettings/rowset', 'POST', null, unsavedTriggeredSettingsData);

        if (data.status === "200-OK") {
            // update TriggeredSendSettingsDE state so that the dropdown has the new data without having to run another GET call
            setTriggeredSendSettingsDE(unsavedTriggeredSettingsData);
            setShowTriggeredSettings(false);
            dispatch(showAlert(ALERT_TYPE.SUCCESS, 'Success'));
        } else {
            dispatch(showAlert(ALERT_TYPE.DANGER, 'There was an error when saving the settings'));
        }

        setIsTriggeredSettingsSaving(false);
    }

    return (
        <div className='email-preview-overflow'>
            <div className='email-preview-title-container'>
                <i className={"fa-solid fa-gear email-preview-settings-icon" + (showTriggeredSettings ? " icon-blue" : " icon-gray")} onClick={() => showTriggeredSettings ? setShowTriggeredSettings(false) : setShowTriggeredSettings(true)}></i>
                <div className="btn-group email-preview-dropdown-container">
                    <button className="btn btn-sm text-white dropdown-toggle email-preview-dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {
                            !activeEmail && !dropdownSelectedTriggers
                                ? <div className="spinner-border segment-row-loading" role="status"><span className="sr-only">Loading...</span></div>
                                : !activeEmail && dropdownSelectedTriggers.length < 1
                                    ? "No Triggered Emails Present"
                                    : activeEmail
                                        ? activeEmail.values.name + " Preview     "
                                        : <></>
                        }
                    </button>
                    <div className="dropdown-menu">
                        {
                            (!dropdownSelectedTriggers || typeof dropdownSelectedTriggers === 'undefined') ?
                                <Loading />
                            : (dropdownSelectedTriggers.length < 1) ?
                                <div className='mt-3'>No Triggered Emails Present</div>
                            : dropdownSelectedTriggers.map((emailObject, index) => (
                                <EmailPreviewDropdownOptions key={emailObject.keys.triggeredsendexternalkey + "_" + index} email={emailObject} onDropdownSelection={setActiveEmail} />
                            ))
                        }
                    </div>
                </div>
            </div>
            {(unsavedTriggeredSettingsData && showTriggeredSettings) && <EmailPreviewSettings triggeredSettingsData={unsavedTriggeredSettingsData} updateTriggerSettingsArray={updateTriggerSettingsArray} handleSaveTriggerSettingsClick={handleSaveTriggerSettingsClick} isTriggeredSettingsSaving={isTriggeredSettingsSaving} /> }
            {(() => {
                if (!emailPreviewPostRequest) {
                    return(
                        <div style={textStyle}>
                            <Loading/>
                        </div>
                    )
                } else if (emailPreviewPostRequest === "No Trigger Data") {
                    return (
                        <div style={textStyle}>There is no data present in your "TriggeredSendSettings" Data Extension, so there is no Email to display. This could be due to an Autobahn install recently being run. However, if this issue persists, reach out to your Red Van Workshop rep for assistance.</div>
                    )
                } else if (emailPreviewPostRequest.status == "200-OK") {
                    return(
                        <iframe className='email-preview-iframe' srcdoc={emailPreviewPostRequest.data.message.views[0].content} frameBorder='0' />
                    )
                } else {
                    return (
                        <div style={textStyle}>Error Loading Email Preview</div>
                    )
                 }
            })()}
        </div>
    )

}

export default EmailPreview