import React, { useEffect, useState } from "react";
import "./SubscriberEvents.scss";
import { useSelector } from "react-redux";
import { getCurrentSubscriberRevenue, getCurrentSubscriberRevenueStatus, getSubscriberBounceEvents, getSubscriberBounceEventsStatus, getSubscriberClickEvents, getSubscriberClickEventsStatus, getSubscriberNotSentEvents, getSubscriberNotSentEventsStatus, getSubscriberOpenEvents, getSubscriberOpenEventsStatus, getSubscriberSendEvents, getSubscriberSendEventsStatus } from "../../redux/subscriber-history/subscriberHistorySlice";
import SubscriberEventsRow from "./SubscriberEventsRow";
import Loading from "../bootstrap-components/Loading";

const SubscriberEvents = () => {
    const eventNames = { SENT: 'Sent', OPENED: 'Opened', CLICKED: 'Clicked', BOUNCED: 'Bounced', UNSENT: 'Not Sent', ORDERED: 'Ordered'};

    const currentSubscriberSendEvents = useSelector(getSubscriberSendEvents);
    const currentSubscriberOpenEvents = useSelector(getSubscriberOpenEvents);
    const currentSubscriberClickEvents = useSelector(getSubscriberClickEvents);
    const currentSubscriberBounceEvents = useSelector(getSubscriberBounceEvents);
    const currentSubscriberNotSentEvents = useSelector(getSubscriberNotSentEvents);
    const currentSubscriberRevenue = useSelector(getCurrentSubscriberRevenue);

    const currentSubscriberSendEventsStatus = useSelector(getSubscriberSendEventsStatus);
    const currentSubscriberOpenEventsStatus = useSelector(getSubscriberOpenEventsStatus);
    const currentSubscriberClickEventsStatus = useSelector(getSubscriberClickEventsStatus);
    const currentSubscriberBounceEventsStatus = useSelector(getSubscriberBounceEventsStatus);
    const currentSubscriberNotSentEventsStatus = useSelector(getSubscriberNotSentEventsStatus);
    const currentSubscriberRevenueStatus = useSelector(getCurrentSubscriberRevenueStatus)

    const isAllStatusesFulfilled = currentSubscriberSendEventsStatus === 'fulfilled' && currentSubscriberOpenEventsStatus === 'fulfilled' && currentSubscriberClickEventsStatus === 'fulfilled' && currentSubscriberBounceEventsStatus === 'fulfilled' && currentSubscriberNotSentEventsStatus === 'fulfilled' && currentSubscriberRevenueStatus === 'fulfilled';
    const isAnyStatusErrored = currentSubscriberSendEventsStatus === 'rejected' || currentSubscriberOpenEventsStatus === 'rejected' || currentSubscriberClickEventsStatus === 'rejected' || currentSubscriberBounceEventsStatus === 'rejected' || currentSubscriberNotSentEventsStatus === 'rejected' || currentSubscriberRevenueStatus === 'rejected';
    const isAllStatusesComplete = isAllStatusesFulfilled || isAnyStatusErrored;

    const [allEventsArray, setAllEventsArray] = useState([]);
    const [isArrayDoneBuilding, setIsArrayDoneBuilding] = useState();

    useEffect(() => {
        if (isAllStatusesComplete) {
            addEventItemsToAllEventsArray();
            setIsArrayDoneBuilding(true);
        }
    }, [isAllStatusesComplete])

    function addEventItemsToAllEventsArray() {
        let stagingEventArray = [];

        stagingEventArray = [
            ...addEventTypeToArray(currentSubscriberSendEvents, eventNames.SENT),
            ...addEventTypeToArray(currentSubscriberOpenEvents, eventNames.OPENED),
            ...addEventTypeToArray(currentSubscriberClickEvents, eventNames.CLICKED),
            ...addEventTypeToArray(currentSubscriberBounceEvents, eventNames.BOUNCED),
            ...addEventTypeToArray(currentSubscriberNotSentEvents, eventNames.UNSENT),
            ...addEventTypeToArray(currentSubscriberRevenue, eventNames.ORDERED)
        ]

        setAllEventsArray(stagingEventArray);
    }

    function addEventTypeToArray(eventValue, eventType) {
        if (eventValue && !Array.isArray(eventValue) && typeof eventValue === 'object') {
            return [{
                EventDateEpoch: getJavascriptDate(eventValue.EventDate),
                Type: eventType,
                JobId: eventValue.SendID
            }];
        } else if (eventValue && Array.isArray(eventValue)) {
            return eventValue.map((event) => ({
                EventDateEpoch: getJavascriptDate(event.EventDate || event.orderdatetime),
                Type: eventType,
                JobId: event.SendID,
                OrderNumber: event.ordernumber
            }));
        }
        return [];
    }

    function getJavascriptDate(dateValue) {
        let javascriptDate = new Date(dateValue);
        return javascriptDate.getTime();
    }

    function getErroredEventsList() {
        let erroredList = [];
        currentSubscriberSendEventsStatus === 'rejected' && erroredList.push(eventNames.SENT);
        currentSubscriberOpenEventsStatus === 'rejected' && erroredList.push(eventNames.OPENED);
        currentSubscriberClickEventsStatus === 'rejected' && erroredList.push(eventNames.CLICKED);
        currentSubscriberBounceEventsStatus === 'rejected' && erroredList.push(eventNames.BOUNCED);
        currentSubscriberNotSentEventsStatus === 'rejected' && erroredList.push(eventNames.UNSENT);
        currentSubscriberRevenueStatus === 'rejected' && erroredList.push(eventNames.ORDERED);
        return erroredList.join(', ');
    }

    return (
        <div className="card subscriber-events">
            <div className="card-title">Events</div>
            <div className="card-body d-flex flex-column align-items-start subscribers-card-extra-padding subscribers-events-card">
                {
                    isAnyStatusErrored &&
                    <div className={"d-grid event-row text--danger" + (allEventsArray.length > 0 ? ' mb-3' : '') }>
                            <i class="fa-solid fa-circle-exclamation event-icon text--danger"></i>
                            Unable to retrieve { getErroredEventsList() } events
                        </div>
                }
                {
                    // If isAllStatusesFulfilled && isArrayDoneBuilding are done loading and the allEventsArray has 1 or more items
                    isAllStatusesComplete && isArrayDoneBuilding && allEventsArray.length > 0

                        ? allEventsArray.sort((a, b) => b.EventDateEpoch - a.EventDateEpoch).map((subscriberEvent) => (
                            <SubscriberEventsRow {...{subscriberEvent}} />
                        ))

                        // If isAllStatusesFulfilled && isArrayDoneBuilding are done loading and the total allEventsArray is an empty array
                        : isAllStatusesFulfilled && isArrayDoneBuilding && allEventsArray.length === 0

                            ? <div>No Events were found for this Subscriber</div>

                            // If isAllStatusesFulfilled && isArrayDoneBuilding are not done then show loading
                                : <div><Loading /></div>
                }
            </div>
        </div>
    )
}

export default SubscriberEvents;