import './ColorDropdown.scss';

const ColorDropdown = ({ color, onColorSelect }) => {

    const colorSwatchDropdown = {
        background: color.hexcode
    }

    function selectColor() {
        onColorSelect(color.hexcode, color.name);
    }

    return (
        <div className="color-dropdown-container dropdown-item" onClick={selectColor}>
            <div className="color-swatch color-swatch-dropdown" style={colorSwatchDropdown}></div>
            <div>{color.name}</div>
        </div>
    )
}

export default ColorDropdown