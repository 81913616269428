import { addToastToArray, getToastArray, removeToastFromArray } from "./toastsSlice";

export const ALERT_TYPE = {
    SUCCESS: 'green',
    WARNING: 'yellow',
    DANGER: 'red'
}

export function showAlert(color, msg, timeout = 10000) {
    return async (dispatch, getState) => {
        const key = `alert-${color}-${getToastArray(getState()).length + 1}`;
        const obj = { key, color, msg };

        dispatch(addToastToArray(obj));
        setTimeout(() => dispatch(removeToastFromArray(key)), timeout);
    };
}
