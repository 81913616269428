export const deFields = [
    {
        "Name": "Id",
        "FieldType": "Text",
        "IsRequired": true,
        "MaxLength": 254,
        "IsPrimaryKey": true
    },
    {
        "Name": "EmailAddress",
        "FieldType": "EmailAddress",
        "IsRequired": false
    },
    {
        "Name": "MobilePhone",
        "FieldType": "Text",
        "IsRequired": false,
        "MaxLength": 50
    },
    {
        "Name": "FirstName",
        "FieldType": "Text",
        "IsRequired": false,
        "MaxLength": 250
    },
    {
        "Name": "LastName",
        "FieldType": "Text",
        "IsRequired": false,
        "MaxLength": 250
    },
    {
        "Name": "AccountId",
        "FieldType": "Text",
        "IsRequired": false,
        "MaxLength": 18
    },
    {
        "Name": "CompanyName",
        "FieldType": "Text",
        "IsRequired": false,
        "MaxLength": 250
    },
    {
        "Name": "Gender",
        "FieldType": "Text",
        "IsRequired": false,
        "MaxLength": 10
    },
    {
        "Name": "Locale",
        "FieldType": "Text",
        "IsRequired": false,
        "MaxLength": 50
    },
    {
        "Name": "SiteID",
        "FieldType": "Text",
        "IsRequired": false,
        "MaxLength": 50
    },
    {
        "Name": "Suffix",
        "FieldType": "Text",
        "IsRequired": false,
        "MaxLength": 50
    },
    {
        "Name": "Title",
        "FieldType": "Text",
        "IsRequired": false,
        "MaxLength": 50
    },
    {
        "Name": "UserName",
        "FieldType": "Text",
        "IsRequired": false,
        "MaxLength": 50
    },
    {
        "Name": "Birthdate",
        "FieldType": "Date",
        "IsRequired": false,
    },
    {
        "Name": "CreatedById",
        "FieldType": "Text",
        "IsRequired": false,
        "MaxLength": 50
    },
    {
        "Name": "et4ae5__HasOptedOutOfMobile",
        "FieldType": "Boolean",
        "IsRequired": false
    },
    {
        "Name": "et4ae5__Mobile_Country_Code",
        "FieldType": "Text",
        "IsRequired": false,
        "MaxLength": 50
    },
    {
        "Name": "HasOptedOutOfEmail",
        "FieldType": "Boolean",
        "IsRequired": false
    },
    {
        "Name": "OwnerId",
        "FieldType": "Text",
        "IsRequired": false,
        "MaxLength": 50
    },
    {
        "Name": "DateAdded",
        "FieldType": "Date",
        "IsRequired": false,
    }
]