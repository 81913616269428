import ExpressService from "./ExpressService";
import { deFields } from './segmentFields';
import { emailAnalyticsDeFields } from "./emailAnalyticsSegmentFields";

const SegmentationService = {
    createDe: async function(deName, folderId, segmentType) {
        try {
            if (segmentType === "customSegment") {
                var deFieldsArray = deFields;
            } else if (segmentType === "sent" || segmentType === "delivered") {
                var deFieldsArray = emailAnalyticsDeFields.sent;
            } else if (segmentType === "open") {
                var deFieldsArray = emailAnalyticsDeFields.open;
            } else if (segmentType === "click") {
                var deFieldsArray = emailAnalyticsDeFields.click;
            } else if (segmentType === "unsubscribe") {
                var deFieldsArray = emailAnalyticsDeFields.unsubscribe;
            } else if (segmentType === "totalBounce" || segmentType === "softBounce" || segmentType === "hardBounce" || segmentType === "otherBounce") {
                var deFieldsArray = emailAnalyticsDeFields.bounce;
            } else if (segmentType === "totalErrors") {
                var deFieldsArray = emailAnalyticsDeFields.totalError;
            } else if (segmentType === "totalExcluded") {
                var deFieldsArray = emailAnalyticsDeFields.totalExcluded;
            } else if (segmentType === "conversions") {
                var deFieldsArray = emailAnalyticsDeFields.conversions;
            }

            const  createDeRequestBody = {
                "Name": deName,
                "CustomerKey": deName,
                "Fields": deFieldsArray,
                "isSendable": true,
                "IsTestable": true,
                "sendableFieldName": "Id",
                "sendableFieldType": "Text",
                "CategoryID": folderId
            }

            const res = await fetch('/soap/dataextension/create', {
                method: "POST",
                body: JSON.stringify(createDeRequestBody),
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (!res.ok) {
                const message = `An error has occured: ${res.status} - ${res.statusText}`;
                throw new Error(message);
            }

            const data = await res.json();
            return data;
        } catch(err) {
            return err
        }
    },

    createQuery: async function(queryName, folderId, queryString, deId) {
        try {
            const createQueryBody = {
                "name": queryName,
                "key": queryName,
                "categoryId": folderId,
                "description": "Created using Autobahn Segmentation",
                "queryText": queryString,
                "targetName": queryName,
                "targetKey": queryName,
                "targetId": deId,
                "targetUpdateTypeId": 0
            }

            const data = await ExpressService.callSFMC('/rest/automation/v1/queries/', 'POST', null, createQueryBody);
            return data;
        } catch(err) {
            return err
        }
    },

    updateQuery: async function(queryDefId, queryString) {
        try {
            const updateQueryBody = {
                "queryDefinitionId": queryDefId,
                "queryText": queryString
            }

            const endpointUrl = '/rest/automation/v1/queries/' + queryDefId
            const data = await ExpressService.callSFMC(endpointUrl, 'PATCH', null, updateQueryBody);
            return data;
        } catch(err) {
            return err;
        }
    },

    addRowToSegmentListingsDe: async function(deKey, queryDefinitionId, queryString, segmentRulesObject, segmentType) {
        try {
            const rowToAdd = [{
                "keys": {
                    "QueryID": queryDefinitionId
                },
                "values": {
                    "SegmentName": deKey,
                    "Status": "processing",
                    "SQL": queryString,
                    "DataExtensionExternalKey": deKey,
                    "SegmentObject": segmentRulesObject === "no rulesArray" ? 'no rules - email analytics segment' : JSON.stringify(segmentRulesObject),
                    "SegmentType": segmentType
                }
            }]

            const data = await ExpressService.callSFMC('/rest/hub/v1/dataevents/key:segment_listings/rowset', 'POST', null, rowToAdd);
            return data;
        } catch(err) {
            return err
        }
    },
    getSegmentRowCount: async function(deKey) {
        try {
            const data = await ExpressService.callSFMC('/rest/data/v1/customobjectdata/key/' + deKey + '/rowset?$pagesize=1&$page=1&$orderBy=EmailAddress desc', 'GET');

            if (data?.name?.includes("Error") && data.message) {
                return "Error"
            }
            return data.data.count;
        } catch(err) {
            return err
        }
    },
    getSegmentDeFolderId: async function() {
        try {
            const data = await ExpressService.callSFMC('/rest/automation/v1/folders?$filter=categorytype%20eq%20dataextension%20', 'GET');

            if (data?.name?.includes("Error") && data.message) {
                return "Error"
            }
            return data.data.items.filter((item) => {
                return item.name === "Segments"
            })[0].categoryId;
        } catch(err) {
            return err
        }
    },
    createAutomation: async function(automationName, queryName, queryId, automationFolder) {
        try {
            let currentDate = new Date();
            let getDate = currentDate.getDate().toString().length > 1 ? currentDate.getDate() : "0" + currentDate.getDate();
            let trueMonth = currentDate.getMonth() + 1;
            let getMonth = trueMonth.toString().length > 1 ? trueMonth : "0" + trueMonth;
            let scheduleStart = currentDate.getFullYear() + "-" + getMonth + "-" + getDate + "T03:00:00";

            const payload = {
                "name": automationName,
                "key": automationName,
                "description": "Created Via Autobahn Segments",
                "steps": [
                    {
                        "annotation": "",
                        "stepNumber": 0,
                        "activities": [
                            {
                                "name": queryName,
                                "objectTypeId": 300,
                                "displayOrder": 0,
                                "activityObjectId": queryId
                            }
                        ]
                    }
                ],
                "startSource": {
                    "typeId": 1,
                    "schedule": {
                        "iCalRecur": "FREQ=DAILY;INTERVAL=1;UNTIL=20790606",
                        "startDate": scheduleStart,
                        "timeZoneId": 1
                    }
                },
                "categoryId": automationFolder
            }
            const data = await ExpressService.callSFMC('/rest/automation/v1/automations/', 'POST', null, payload)

            if (data?.name?.includes("Error") && data.message) {
                return "Error"
            }
            return data;
        } catch (err) {
            return err
        }
    },
    getAutomationObject: async function(automationLegacyId) {
        try {
            const data = await ExpressService.callSFMC('/rest/legacy/v1/beta/bulk/automations/automation/definition/' + automationLegacyId + '/', 'GET');

            if (data?.name?.includes("Error") && data.message) {
                return "Error";
            } else {
                return data;
            }

        } catch (err) {
            return err
        }
    },
    activateAutomation: async function(automationLegacyId, scheduleObjectId) {
        try {
            const payload = {
                "id": automationLegacyId,
                "scheduleObject": {
                    "id": scheduleObjectId
                }
            }

            const data = await ExpressService.callSFMC('/rest/legacy/v1/beta/bulk/automations/automation/definition/?action=schedule', 'POST', null, payload);

            if (data?.name?.includes("Error") && data.message) {
                return "Error";
            } else {
                return data;
            }

        } catch (err) {
            return err
        }
    },
    pauseAutomation: async function(automationLegacyId, scheduleObjectId) {
        try {
            const payload = {
                "id": automationLegacyId,
                "scheduleObject": {
                    "id": scheduleObjectId
                }
            }

            const data = await ExpressService.callSFMC('/rest/legacy/v1/beta/bulk/automations/automation/definition/?action=pauseSchedule', 'POST', null, payload);

            if (data?.name?.includes("Error") && data.message) {
                return "Error";
            } else {
                return data;
            }

        } catch (err) {
            return err
        }
    },
    updateSegmentListingsSync: async function (queryId, automationStatus, automationLegacyId, scheduleObjectId) {
        var modifiedDate = new Date()
        try {
            const payload = [
                {
                    "keys": {
                        "QueryID": queryId
                    },
                    "values": {
                        "AutomationStatus": automationStatus,
                        "AutomationLegacyId": automationLegacyId,
                        "AutomationScheduleObjectId": scheduleObjectId,
                        "ModifiedDate": modifiedDate.toISOString()
                    }
                }
            ]

            const data = await ExpressService.callSFMC('/rest/hub/v1/dataevents/key:segment_listings/rowset', 'POST', null, payload);

            if (data?.name?.includes("Error") && data.message) {
                return "Error";
            } else {
                return data;
            }

        } catch (err) {

        }
    }
}

export default SegmentationService;