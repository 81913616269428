import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAutomationSegmentsFolderId, getAutomationSegmentsFolderId, getAutomationSegmentsFolderIdStatus, getSegmentListingsPagination, getSegmentListingsPaginationStatus, fetchSegmentListingsPagination } from '../../redux/segmentation/segmentListingsSlice';
import Loading from '../bootstrap-components/Loading';
import SegmentationService from '../../service/SegmentationService';
import SegmentListingRow from './SegmentListingsRow';
import { getCheckQueryResult, getCheckQueryStatus, getIsCreateCompleteError, getIsCreateCompleteSuccess, getIsRunQueryCompleteError, getIsShowNewSegmentationEditorRow, getSegmentListingsPutResult, resetCreateFlags, setIsShowNewSegmentationEditorRow, setIsShowSegmentationEditorRow, setOpenedRowSegmentName } from '../../redux/segmentation/segmentationSlice';
import { Tooltip } from 'react-tooltip';
import { ALERT_TYPE, showAlert } from '../../redux/toasts/toastHelpers';
import Title from '../layout/Title';
import Segmentation from './Segmentation';
import './SegmentListings.scss';


const SegmentListing = ({timerRef}) => {

    const dispatch = useDispatch();
    const automationSegmentsFolderId = useSelector(getAutomationSegmentsFolderId);
    const automationSegmentsFolderIdStatus = useSelector(getAutomationSegmentsFolderIdStatus);
    const checkQueryStatus = useSelector(getCheckQueryStatus);
    const checkQueryResult = useSelector(getCheckQueryResult);
    const [contactMasterRowCount, setContactMasterRowCount] = useState();
    const segmentListingsPutResult = useSelector(getSegmentListingsPutResult);
    const [isRefreshing, setIsRefreshing] = useState();
    const [clickedCreateAutomationSegment, setClickedCreateAutomationSegment] = useState();
    const [createAutomationRes, setCreateAutomationRes] = useState();
    const [automationGetScheduleObject, setAutomationGetScheduleObject] =  useState();
    const [activateOrPauseAutomationRes, setActivateOrPauseAutomationRes] = useState();
    const [updateSegmentListingsAutomationStatusRes, setUpdateSegmentListingsAutomationStatusRes] = useState();
    const isSegmentRunning = checkQueryStatus === 'pending' || checkQueryResult?.isRunning === true ? true : false;
    const isSegmentRunningClass = isSegmentRunning ? ' disabled' : '';
    const isSegmentRunningToolTipMsg = isSegmentRunning ? 'Disabled - Segment Currently Running' : '';
    const [segmentPageCount, setSegmentPageCount] = useState();
    const [currentSegmentPage, setCurrentSegmentPage] = useState();
    const segmentListingsPagination = useSelector(getSegmentListingsPagination);
    const segmentListingsPaginationStatus = useSelector(getSegmentListingsPaginationStatus);
    const [segmentListings, setSegmentListings] = useState();
    const [segmentationEditorRow, setSegmentationEditorRow] = useState(null);
    const mutationRef = useRef(null);
    const [isCreateRowExpanded, setIsCreateRowExpanded] = useState(null);
    const createBtnClass = isCreateRowExpanded ? ' d-none' : '';
    const [allowReopenEditorRow, setAllowReopenEditorRow] = useState();
    const [hasClickedCreateBtn, setHasClickedCreateBtn] = useState(false);
    const [hasClickedEditBtn, setHasClickedEditBtn] = useState(false);
    const [openEditorRow, SetOpenEditorRow] = useState(false);
    const isCreateCompleteSuccess = useSelector(getIsCreateCompleteSuccess);
    const isCreateCompleteError = useSelector(getIsCreateCompleteError);
    const isRunQueryCompleteError = useSelector(getIsRunQueryCompleteError);
    const isShowNewSegmentationEditorRow = useSelector(getIsShowNewSegmentationEditorRow);

    const tableHeaders = [
        { name: 'Segment Name', tooltipContent: 'Segments are saved in "/Data Extensions/Autobahn/Segments"' },
        { name: 'Segment Count', isSmallColumn: true },
        { name: 'Last Modified', isSmallColumn: true },
        { name: 'Actions', isSmallColumn: true },
    ];
    const newSegmentationEditorRowId = 'row-create-segment';

    useEffect(() => {
        getMasterRowCount();
        setCurrentSegmentPage(1);
        dispatch(fetchSegmentListingsPagination(1));
    }, [])

    useEffect(() => {
        // listen for collapse event from bootstrap for create button row and the segement editor row
        if (mutationRef.current) {
            const observer = new MutationObserver((mutations) => {
                mutations.forEach(mutation => {
                    if (mutation.oldValue.includes('collapsing')) {
                        // hide create button row when editor row is expanded
                        if (mutation.target.classList.contains("show")) {
                            setIsCreateRowExpanded(true);
                        } else if (mutation.oldValue.includes('show')) {
                            setIsCreateRowExpanded(false);
                        }
                    }
                })
            });
            observer.observe(mutationRef.current, {
                attributes: true,
                attributeOldValue: true,
                attributeFilter: ['class']
            });
            return () => observer.disconnect();
        }
    }, [mutationRef]);

    useEffect(() => {
        if (isCreateCompleteSuccess || isCreateCompleteError) {
            setAllowReopenEditorRow(true);
            if (isRunQueryCompleteError) {
                dispatch(fetchSegmentListingsPagination(currentSegmentPage ? currentSegmentPage : 1));
            }
        } else {
            setAllowReopenEditorRow(false);
        }
    }, [isCreateCompleteSuccess, isCreateCompleteError]);

    useEffect(() => {
        if(segmentListingsPutResult) {
            setIsRefreshing(false);
            dispatch(fetchSegmentListingsPagination(currentSegmentPage ? currentSegmentPage : 1));
        }
    },[segmentListingsPutResult])

    useEffect(() => {
        if (clickedCreateAutomationSegment && clickedCreateAutomationSegment.automationType === "create") {
            if (automationSegmentsFolderId) {
                createAutomation();
            } else {
                dispatch(fetchAutomationSegmentsFolderId());
            }
        } else if (clickedCreateAutomationSegment && clickedCreateAutomationSegment.automationType === "pause") {
            pauseAutomationAction();
        } else if (clickedCreateAutomationSegment && clickedCreateAutomationSegment.automationType === "activate") {
            activateAutomationAction();
        }
    },[clickedCreateAutomationSegment])

    useEffect(() => {
        if (automationSegmentsFolderIdStatus && automationSegmentsFolderIdStatus === "rejected") {
            activateErrorMessage();
        } else if (automationSegmentsFolderId && automationSegmentsFolderIdStatus && automationSegmentsFolderIdStatus === "fulfilled") {
            createAutomation();
        }
    },[automationSegmentsFolderId, automationSegmentsFolderIdStatus])

    useEffect(() => {
        if (createAutomationRes && createAutomationRes.status === "201-Created") {
            async function getAutomation() {
                const getAutomationObject = await SegmentationService.getAutomationObject(createAutomationRes.data.legacyId);
                setAutomationGetScheduleObject(getAutomationObject);
            }
            getAutomation();
        } else if (createAutomationRes && createAutomationRes.status !== "201-Created") {
            activateErrorMessage();
        }
    },[createAutomationRes])

    useEffect(() => {
        if (automationGetScheduleObject && automationGetScheduleObject.status === "200-OK") {
            activateAfterCreateAutomationAction();
        } else if (automationGetScheduleObject && automationGetScheduleObject.status !== "200-OK") {
            activateErrorMessage();
        }
    },[automationGetScheduleObject])

    useEffect(() => {
        if (activateOrPauseAutomationRes && activateOrPauseAutomationRes.status === "201-Created" && (clickedCreateAutomationSegment.automationType === "create" || clickedCreateAutomationSegment.automationType === "activate")) {
            updateSegmentListingsUpdateAutomationStatus('active');
        } else if (activateOrPauseAutomationRes && activateOrPauseAutomationRes.status === "201-Created" && clickedCreateAutomationSegment.automationType === "pause") {
            updateSegmentListingsUpdateAutomationStatus('paused');
        } else if (activateOrPauseAutomationRes && activateOrPauseAutomationRes.status !== "201-Created") {
            activateErrorMessage();
        }
    },[activateOrPauseAutomationRes])

    useEffect(() => {
        if (updateSegmentListingsAutomationStatusRes && updateSegmentListingsAutomationStatusRes.status === "200-OK") {
            activateSuccessMessage();
            dispatch(fetchSegmentListingsPagination(currentSegmentPage ? currentSegmentPage : 1));
            clearAutomationStateData();
        } else if (updateSegmentListingsAutomationStatusRes && updateSegmentListingsAutomationStatusRes.status !== "200-OK") {
            activateErrorMessage();
        }
    },[updateSegmentListingsAutomationStatusRes])

    useEffect(() => {
        if (segmentListingsPagination) {
            if(segmentListingsPagination && typeof segmentListingsPagination === 'object' && segmentListingsPagination.currentPage && segmentListingsPagination.totalPages && Array.isArray(segmentListingsPagination.dEdata)) {
                setSegmentListings(segmentListingsPagination.dEdata);
                setSegmentPageCount(segmentListingsPagination.totalPages);
                setCurrentSegmentPage(segmentListingsPagination.currentPage);
                dispatch(setOpenedRowSegmentName(null));
            } else if (segmentListingsPagination && typeof segmentListingsPagination === 'object' && segmentListingsPagination.currentPage && segmentListingsPagination.totalPages === 0 && (!segmentListingsPagination.dEdata || segmentListingsPagination.dEdata.length === 0)) {
                setSegmentListings([]);
                setSegmentPageCount(1);
                setCurrentSegmentPage(segmentListingsPagination.currentPage);
                dispatch(setOpenedRowSegmentName(null));
            } else {
                setSegmentListings('There was an error. Reach out to a Read Van Workshop rep if this persists.');
            }
        }
    }, [segmentListingsPagination])

    useEffect(() => {
        // 1.reset flags 2.and load 'create new' editor row if 'Create New' btn clicked
        if (openEditorRow) {
            dispatch(resetCreateFlags());
            // open "create new" editor row if Create btn is clicked
            if (hasClickedCreateBtn) {
                expandNewSegmentationEditorRow();
                dispatch(setIsShowNewSegmentationEditorRow(true));
            } else if (hasClickedEditBtn) {
                dispatch(setIsShowSegmentationEditorRow(true));   // show editor row from Edit Segment
            }

            SetOpenEditorRow(false);
            setHasClickedCreateBtn(false);
            setHasClickedEditBtn(false);
        }
    }, [openEditorRow]);

    useEffect(() => {
        // empty 'create new' editor row whenever Create or Edit is clicked
        if (hasClickedCreateBtn || hasClickedEditBtn) {
            setSegmentationEditorRow(false);
            dispatch(setIsShowSegmentationEditorRow(false));   // show editor row from Edit Segment
            dispatch(setIsShowNewSegmentationEditorRow(false));
            SetOpenEditorRow(true);
        }
    }, [hasClickedCreateBtn, hasClickedEditBtn]);

    async function getMasterRowCount() {
        const contactMasterRowCountData = await SegmentationService.getSegmentRowCount('Contact_Master');
        setContactMasterRowCount(contactMasterRowCountData);
    }

    async function createAutomation() {
        const createAutomationResponse = await SegmentationService.createAutomation(clickedCreateAutomationSegment.segmentRow.segmentname, clickedCreateAutomationSegment.segmentRow.segmentname, clickedCreateAutomationSegment.segmentRow.queryid, automationSegmentsFolderId);
        setCreateAutomationRes(createAutomationResponse);
    }

    async function activateAfterCreateAutomationAction() {
        const activateAutomationCall = await SegmentationService.activateAutomation(createAutomationRes.data.legacyId, automationGetScheduleObject.data.scheduleObject.id);
        setActivateOrPauseAutomationRes(activateAutomationCall);
    }

    async function activateAutomationAction() {
        const activateAutomationCall = await SegmentationService.activateAutomation(clickedCreateAutomationSegment.segmentRow.automationlegacyid, clickedCreateAutomationSegment.segmentRow.automationscheduleobjectid);
        setActivateOrPauseAutomationRes(activateAutomationCall);
    }

    async function pauseAutomationAction() {
        const pauseAutomationCall = await SegmentationService.pauseAutomation(clickedCreateAutomationSegment.segmentRow.automationlegacyid, clickedCreateAutomationSegment.segmentRow.automationscheduleobjectid);
        setActivateOrPauseAutomationRes(pauseAutomationCall);
    }

    async function updateSegmentListingsUpdateAutomationStatus(automationStatus) {
        const updateSegmentListings = await SegmentationService.updateSegmentListingsSync(clickedCreateAutomationSegment.segmentRow.queryid, automationStatus, createAutomationRes ? createAutomationRes.data.legacyId : clickedCreateAutomationSegment.segmentRow.automationlegacyid, automationGetScheduleObject ? automationGetScheduleObject.data.scheduleObject.id : clickedCreateAutomationSegment.segmentRow.automationscheduleobjectid);
        setUpdateSegmentListingsAutomationStatusRes(updateSegmentListings);
    }

    function clearAutomationStateData() {
        setClickedCreateAutomationSegment();
        setCreateAutomationRes();
        setAutomationGetScheduleObject();
        setActivateOrPauseAutomationRes();
        setUpdateSegmentListingsAutomationStatusRes();
    }

    function activateErrorMessage() {
        dispatch(showAlert(ALERT_TYPE.DANGER, 'There was an error with the Segment Daily Refresh for ' + clickedCreateAutomationSegment.segmentRow.segmentname));
        clearAutomationStateData();
    }

    function activateSuccessMessage() {
        dispatch(showAlert(ALERT_TYPE.SUCCESS, "Your Segment Daily Refresh update for '" + clickedCreateAutomationSegment.segmentRow.segmentname + "' was successful"));
    }

    function changePage(changeNumber) {
        dispatch(fetchSegmentListingsPagination(changeNumber))
    }

    function displayNumber(number) {
        if (number === 1) {

            if (segmentPageCount === 1 || currentSegmentPage === 1) {
                return 1
            } else if (currentSegmentPage === segmentPageCount && segmentPageCount > 2) {
                return (currentSegmentPage - 2)
            } else {
                return (currentSegmentPage - 1)
            }

        } else if (number === 2) {

            if (currentSegmentPage === 1 || segmentPageCount === 2) {
                return 2
            } else if (currentSegmentPage === segmentPageCount) {
                return (currentSegmentPage - 1)
            } else {
                return currentSegmentPage
            }

        } else if (number === 3) {

            if (currentSegmentPage === 1 && segmentPageCount > 2) {
                return 3
            } else if (currentSegmentPage !== segmentPageCount) {
                return (currentSegmentPage + 1)
            } else {
                return currentSegmentPage
            }
        }
    }

    function clickNumber(number) {
        if (number === 1) {

            if (currentSegmentPage === segmentPageCount && segmentPageCount > 2) {
                changePage(currentSegmentPage - 2);
            } else if (currentSegmentPage !== 1) {
                changePage(currentSegmentPage - 1);
            }
        } else if (number === 2) {

            if (currentSegmentPage === 1) {
                changePage(2);
            } else if (currentSegmentPage === segmentPageCount) {
                changePage(currentSegmentPage - 1);
            }
        } else if (number === 3) {

            if (currentSegmentPage === 1) {
                changePage(3);
            } else if (currentSegmentPage !== segmentPageCount) {
                changePage(currentSegmentPage + 1);
            }
        }
    }

    function classIsActive(number) {
        if (number === 1) {
            return currentSegmentPage === 1 && " active"
        } else if (number === 2) {

            if ((currentSegmentPage === 2 && segmentPageCount === 2) || (currentSegmentPage !== segmentPageCount  && currentSegmentPage !== 1)) {
                return " active"
            }
        } else if (number === 3) {
            return currentSegmentPage === segmentPageCount && " active"
        }
    }

    function renderCreateNewSegmentRow(isLoading) {
        return (
            !(typeof segmentListings === 'string' || segmentListings instanceof String)
                ?  <>
                    {
                        allowReopenEditorRow ?
                        <tr className='reopen-create-btn-row no-hover'>
                            <td cope="col" colSpan={tableHeaders.length}>
                                <div className='reopen-create-btn-wrapper' onClick={() => { !isSegmentRunning && setHasClickedCreateBtn(true) }}>
                                    <button className='btn-secondary'>
                                        Create New Segment +
                                    </button>
                                </div>
                            </td>
                        </tr>
                        : <tr className={`create-btn-row no-hover${isLoading ? ' d-none' : ''}`}>
                            <td cope="col" colSpan={tableHeaders.length}>
                                <div className='tooltip-target-wrapper create-btn-wrapper' style={isCreateRowExpanded ? {height: 0, margin:0} : {}} onClick={() => { !isSegmentRunning && setHasClickedCreateBtn(true) }} data-tooltip-id="my-tooltip-create-new-segment" data-tooltip-content={isSegmentRunningToolTipMsg} data-tooltip-place="left">
                                    <button data-bs-toggle="collapse" data-bs-target={`#${newSegmentationEditorRowId}`} className={'btn-secondary' + isSegmentRunningClass + createBtnClass + (isCreateRowExpanded ? ' d-none' : '')}>
                                        Create New Segment +
                                    </button>
                                </div>
                                {<Tooltip id="my-tooltip-create-new-segment" style={{ marginTop: '-1px', marginLeft: '10px' }} />}
                            </td>
                        </tr>
                    }
                    <tr className='editor-row no-hover'>
                        <td colSpan={7}>
                            <div ref={mutationRef} className='collapse' id={newSegmentationEditorRowId} data-bs-parent="tbody">
                                { isShowNewSegmentationEditorRow && (segmentationEditorRow || <div><Loading /></div>) }
                            </div>
                        </td>
                    </tr>
                </>
                : <></>
        )
    }

    const renderTable = () => {
        return (
            segmentListingsPaginationStatus === 'idle' || segmentListingsPaginationStatus === 'pending'
            ? <tr><td colSpan={tableHeaders.length}><div className='my-3'><Loading /></div></td></tr>
            : (typeof segmentListings === 'string' || segmentListings instanceof String)
                ? <tr><td colSpan={tableHeaders.length}><div className='my-3'>There was an error. Please reach out to a Red Van Workshop rep if this persists.</div></td></tr>
                : Array.isArray(segmentListings) && segmentListings.length > 0
                    ? segmentListings.map((segmentListing, index) => (
                        <SegmentListingRow key={"segmentListingsRow" + index}
                                            rowId={"segmentListingsRow" + index}
                                            segmentRow={segmentListing}
                                            timerRef={timerRef}
                                            setIsRefreshing={setIsRefreshing}
                                            isRefreshing={isRefreshing}
                                            clickedCreateAutomationQueryId={clickedCreateAutomationSegment ? clickedCreateAutomationSegment.segmentRow.queryid : null}
                                            setClickedCreateAutomationSegment={setClickedCreateAutomationSegment}
                                            checkQueryResult={checkQueryResult}
                                            setHasClickedEditBtn={setHasClickedEditBtn}
                                            hasClickedEditBtn={hasClickedEditBtn}
                                            allowReopenEditorRow={allowReopenEditorRow}
                        />
                    ))
                    : Array.isArray(segmentListings) && segmentListings.length === 0
                        ?
                            <tr><td colSpan={tableHeaders.length}><div className='my-3'>
                                No data was found in your "Your Segments" Data Extension. This could be a result of recently running an Autobahn Install. Check back soon. If this issue persists reach out to your Red Van Workshop rep.
                            </div></td></tr>
                        :
                            <tr><td colSpan={tableHeaders.length}><div className='my-3'>
                                There was an error when retrieving your Data Extension
                            </div></td></tr>
        )
    }

    // before calling this, please empty the setSegmentationEditorRow() first to trigger re-render of Segmentation
    function expandNewSegmentationEditorRow() {
        setSegmentationEditorRow(
            <Segmentation segmentationEditorRowId={newSegmentationEditorRowId} segmentRowData={null} allowReopenEditorRow={allowReopenEditorRow} />
        );
        setAllowReopenEditorRow(false);
    }

    return (
        <div className='page'>
            <Title title={'Segments'} buttonText={null} saveAction={null} />
            <div className='card'>

                <h2 className='card-title'>Marketing List(
                {
                        (contactMasterRowCount === null || contactMasterRowCount === undefined
                        ? <Loading />
                        : typeof contactMasterRowCount === "string"
                            ? <span className='mb-0'>There was an error retrieving this data</span>
                                : <span className='mb-0'>{contactMasterRowCount}</span>)

                })</h2>
                <div className='card-body'>
                    <table className='table table-borderless table-hover left-align-first-column'>
                        <thead>
                            <tr>
                                {tableHeaders.map((headerObj, index) =>
                                    <th cope="col" className={headerObj.isSmallColumn ? 'segment-table-sm-col' : ''}>
                                        {headerObj.tooltipContent ?
                                            <div>
                                                {headerObj.name}
                                                <i className="fa-solid fa-circle-info pl-1"  data-tooltip-id={"my-tooltip-" + headerObj.name} data-tooltip-content={headerObj.tooltipContent} data-tooltip-place="right"></i>
                                                <Tooltip id={"my-tooltip-" + headerObj.name}></Tooltip>
                                            </div>
                                            : <div>{headerObj.name}</div>
                                        }
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {renderCreateNewSegmentRow(segmentListingsPaginationStatus === 'idle' || segmentListingsPaginationStatus === 'pending')}
                            {renderTable()}
                        </tbody>
                    </table>

                    <div className='pagination-controls-container'>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                {/* Last Button */}
                                <li className="page-item" onClick={() => currentSegmentPage > 1 && changePage(1)}>
                                    <a className={"page-link" + (currentSegmentPage === 1 ? " disabled" : "")} href="#" aria-label="First">
                                        <span aria-hidden="true">&laquo;</span>
                                    </a>
                                </li>
                                {/* Previous Button */}
                                <li className="page-item" onClick={() => currentSegmentPage > 1 && changePage(currentSegmentPage - 1)}>
                                    <a className={"page-link" + (currentSegmentPage === 1 ? " disabled" : "")} href="#" aria-label="Previous">
                                        <span aria-hidden="true">&lsaquo;</span>
                                    </a>
                                </li>
                                {/* 1 of 3 Numbers Button */}
                                <li onClick={() => clickNumber(1)} className={"page-item" + classIsActive(1)}><a class="page-link" href="#">{displayNumber(1)}</a></li>
                                {/* 2 of 3 Numbers Button */}
                                {
                                    segmentPageCount > 1 ?
                                        <li onClick={() => clickNumber(2)} className={"page-item" + classIsActive(2)}><a className="page-link" href="#">{displayNumber(2)}</a></li>
                                        : <></>
                                }
                                {/* 3 of 3 Numbers Button */}
                                {   segmentPageCount > 2 ?
                                    <li onClick={() => clickNumber(3)} className={"page-item" + classIsActive(3)}><a className="page-link" href="#">{displayNumber(3)}</a></li>
                                    : <></>
                                }
                                {/* Next Button */}
                                <li className="page-item" onClick={() => segmentPageCount !== currentSegmentPage && changePage(currentSegmentPage + 1)}>
                                    <a className={"page-link" + ((segmentPageCount === currentSegmentPage) ? " disabled" : "")} href="#" aria-label="Next">
                                        <span aria-hidden="true">&rsaquo;</span>
                                    </a>
                                </li>
                                {/* Last Button */}
                                <li className="page-item" onClick={() => segmentPageCount !== currentSegmentPage && changePage(segmentPageCount)}>
                                    <a className={"page-link" + ((segmentPageCount === currentSegmentPage) ? " disabled" : "")} href="#" aria-label="Last">
                                        <span aria-hidden="true">&raquo;</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SegmentListing;