import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { checkQuery, getCheckQueryStatus, getRunQueryResult, runQuery, resetCheckQueryStatus, setActiveDeKey, setActiveQueryId, getActiveQueryId, getDeRowCountResults, resetActiveDe, resetUpdateSegmentListings, resetDeRowCount, getRunQueryStatus, getIsShowSegmentationEditorRow, getOpenedRowSegmentName, setOpenedRowSegmentName } from '../../redux/segmentation/segmentationSlice';
import { Tooltip } from 'react-tooltip'
import GoogleAnalyticsService from "../../service/GoogleAnalyticsService";
import { useNavigate } from "react-router-dom";
import './SegmentListingsRow.scss';
import Segmentation from "./Segmentation";
import Loading from "../bootstrap-components/Loading";
import TimeAgo from 'react-timeago'
import { resetQueryFlags, setSidenavLoadingActiveDeKey, setSidenavLoadingActiveQueryId } from "../../redux/sidenav-loading/sidenavLoadingSlice";
import { getZoneInfo } from "../../redux/meSlice";
import spacetime from "spacetime";
import { ALERT_TYPE, showAlert } from "../../redux/toasts/toastHelpers";

const SegmentListingRow = ({segmentRow, rowId, timerRef, setIsRefreshing, isRefreshing, clickedCreateAutomationQueryId, setClickedCreateAutomationSegment, checkQueryResult, setHasClickedEditBtn, hasClickedEditBtn, allowReopenEditorRow}) => {

    const dispatch = useDispatch();
    const editBtnRef = useRef(null);
    const runQueryResult = useSelector(getRunQueryResult);
    const runQueryStatus = useSelector(getRunQueryStatus);
    const [hasClickedRefresh, setHasClickedRefresh] = useState(false);
    const checkQueryStatus = useSelector(getCheckQueryStatus);
    const activeQueryId = useSelector(getActiveQueryId);
    const [refreshHasError, setRefreshHasError] = useState();
    const dERowCountCall = useSelector(getDeRowCountResults);
    const organizationZoneInfo = useSelector(getZoneInfo);
    const isShowSegmentationEditorRow = useSelector(getIsShowSegmentationEditorRow);
    const openedRowSegmentName = useSelector(getOpenedRowSegmentName);
    const [segmentationEditorRow, setSegmentationEditorRow] = useState(null);
    const navigate = useNavigate();
    const isCurrentRow = activeQueryId === segmentRow.queryid;  // detect if this component instance is the active current row
    const lastModifiedTimeInLocal = convertOrganizationTimeToLocal();


    useEffect(() => {
        if (runQueryResult && runQueryResult === "OK" && hasClickedRefresh) {
            runCheckQuery();
        }
    },[runQueryResult])

    useEffect(() => {
        if (runQueryResult && runQueryResult.errorcode === 0 && isCurrentRow) {
            handleRunQueryError();
        }
    },[runQueryResult, activeQueryId])

    useEffect(() => {
        if(dERowCountCall === "Error" && isCurrentRow) {
            setRefreshHasError("DE");
        }
    },[dERowCountCall])

    useEffect(() => {
        if(checkQueryStatus === "fulfilled" && hasClickedRefresh === false && checkQueryResult?.isRunning === true) {
            setIsRefreshing(true);
            if (checkQueryResult.queryDefinitionId === segmentRow.queryid) {
                setHasClickedRefresh(true);
            }
        } else if (runQueryStatus === 'rejected' || checkQueryStatus === 'rejected') {
            setIsRefreshing(false);
            if (isCurrentRow) {
                handleRunQueryError();
            }
        }
    }, [runQueryStatus, checkQueryResult, checkQueryStatus, segmentRow])

    useEffect(() => {
        if (isShowSegmentationEditorRow && isCurrentRow) {
            expandSegmentationEditorRow();
        }
    }, [isShowSegmentationEditorRow]);

    useEffect(() => {
        if (hasClickedEditBtn && isCurrentRow) {
            setSegmentationEditorRow(false);
        }
    }, [hasClickedEditBtn]);

    function handleRefresh() {
        GoogleAnalyticsService.triggerEvent("sfmc_action", "refreshed_segment");
        setRefreshHasError(null);
        dispatch(resetActiveDe());
        dispatch(resetDeRowCount());
        dispatch(resetCheckQueryStatus());
        dispatch(resetUpdateSegmentListings());
        setIsRefreshing(true);
        setHasClickedRefresh(true);
        dispatch(setActiveDeKey(segmentRow.dataextensionexternalkey));
        dispatch(setActiveQueryId(segmentRow.queryid));
        dispatch(runQuery(segmentRow.queryid));

        // reset side nav loading flags
        dispatch(resetQueryFlags());
        dispatch(setSidenavLoadingActiveDeKey(segmentRow.dataextensionexternalkey));
        dispatch(setSidenavLoadingActiveQueryId(segmentRow.queryid));
    }

    function runCheckQuery() {
        dispatch(checkQuery(segmentRow.queryid));
    }

    function handleRunQueryError() {
        setRefreshHasError("query");
        if (!isRefreshing && openedRowSegmentName === segmentRow.segmentname) {
            // close editor row
            editBtnRef.current.click();

            dispatch(showAlert(ALERT_TYPE.DANGER, "There was an error when refreshing '" + segmentRow.segmentname + "'. Your query might not exist."));
            setRefreshHasError(null);
            dispatch(setOpenedRowSegmentName(null));
        } else if (isRefreshing) {
            dispatch(showAlert(ALERT_TYPE.DANGER, "There was an error when refreshing '" + segmentRow.segmentname + "'. Your query might not exist."));
            setRefreshHasError(null);
        }
    }

    function displayRefreshIcon() {
        if (isRefreshing && isCurrentRow && !refreshHasError) {
            // a query is running and query id === current row
            return (
                <div className="spinner-border segment-row-loading" role="status"><span className="sr-only">Loading...</span></div>
            )
        } else if (isRefreshing && !isCurrentRow && !refreshHasError) {
            // a query is running and query id !== current row
            return (
                <></>
            )
        } else if (isCurrentRow && refreshHasError && refreshHasError !== 'query') {
            // current row query id matches query that had an error other than 'query' error when running
            return (
                <><i key={segmentRow.queryid + "errorIcon"} className="fa-solid fa-circle-exclamation" data-tooltip-id={"my-tooltip-refresh-err-" + rowId} data-tooltip-content={"There was an error. Your " + refreshHasError + " might not exist."} data-tooltip-place="left"></i><Tooltip id={"my-tooltip-refresh-err-" + rowId} /></>
            )
        } else if (!isRefreshing && (!checkQueryResult?.isRunning || refreshHasError)) {
            // no query is actively running
            return (
                <><i class="fa-solid fa-rotate-right segment-icon" key={segmentRow.queryid + "refreshIcon"} onClick={() => handleRefresh()} data-tooltip-id={"my-tooltip-refresh-" + rowId} data-tooltip-content={"Refresh Segment"} data-tooltip-place="left"></i><Tooltip id={"my-tooltip-refresh-" + rowId} /></>
            )
        } else {
            return (
                <><i key={segmentRow.queryid + "errorIcon"} className="fa-solid fa-circle-exclamation" data-tooltip-id={"my-tooltip-refresh-err-" + rowId} data-tooltip-content={"There was an error. Reach out to Red Van Rep."} data-tooltip-place="left"></i><Tooltip id={"my-tooltip-refresh-err-" + rowId} /></>
            )
        }
    }

    function handleEdit() {
        navigate(
            '/segmentation',
            {
                state: segmentRow
            }
        )
    }

    async function startAutomationProcess(automationType) {
        setClickedCreateAutomationSegment({
            automationType: automationType,
            segmentRow: segmentRow
        });
    }

    function displayAutomationIcon() {
        if (clickedCreateAutomationQueryId && clickedCreateAutomationQueryId !== segmentRow.queryid) {
            return (
                <></>
            )
        } else if (clickedCreateAutomationQueryId && clickedCreateAutomationQueryId === segmentRow.queryid) {
            return (
                <div className="spinner-border segment-row-loading" role="status"><span className="sr-only">Loading...</span></div>
            )
        }

        if (segmentRow.automationstatus === "not created" || segmentRow.automationstatus === "") {
            return (
                <><i key={segmentRow.queryid + "createAutomationIcon"} class="fa-solid fa-bolt-lightning segment-icon" data-tooltip-id={"my-tooltip-automate-" + rowId} data-tooltip-content={"Create and Activate Automated Daily Refresh"} data-tooltip-place="left" onClick={() => startAutomationProcess('create')}></i><Tooltip id={"my-tooltip-automate-" + rowId} /></>
            )
        } else if (segmentRow.automationstatus === "active") {
            return (
                <><i key={segmentRow.queryid + "createAutomationIcon"} class="fa-solid fa-bolt-lightning segment-icon automated-refresh-active" data-tooltip-id={"my-tooltip-automate-" + rowId} data-tooltip-content={"Automated Refresh - Active. Click to pause."} data-tooltip-place="left" onClick={() => startAutomationProcess('pause')}></i><Tooltip id={"my-tooltip-automate-" + rowId} /></>
            )
        } else if (segmentRow.automationstatus === "paused") {
            return (
                <><i key={segmentRow.queryid + "createAutomationIcon"} class="fa-solid fa-bolt-lightning segment-icon automated-refresh-paused" data-tooltip-id={"my-tooltip-automate-" + rowId} data-tooltip-content={"Automated Refresh - Paused. Click to activate."} data-tooltip-place="left" onClick={() => startAutomationProcess('activate')}></i><Tooltip id={"my-tooltip-automate-" + rowId} /></>
            )
        } else {
            return (
                <></>
            )
        }
    }

    function expandSegmentationEditorRow() {
        setSegmentationEditorRow(
            <Segmentation segmentationEditorRowId={rowId} segmentRowData={segmentRow} allowReopenEditorRow={allowReopenEditorRow} />
        )
    }

    function convertOrganizationTimeToLocal() {
        // convert time from organization-level set time zone to local time zone with iso-utc format
        let localeISOTime =spacetime(segmentRow.modifieddate, organizationZoneInfo).goto(null).format('iso-utc')
        return new Date(localeISOTime).toLocaleString();
    }

    return (
        <>
            <tr>
                <th scope="row"><div> {segmentRow.segmentname} </div></th>
                <td><div> {segmentRow.segmentcount || '-'} </div></td>
                <td className="text-nowrap"><div>
                    <TimeAgo
                        date={lastModifiedTimeInLocal}
                        formatter={(value, unit, suffix) => {
                            if(unit === "second"){
                                return "less than 1 minute ago";
                            }else{
                                return value + " " + unit + (value > 1 ? "s" : "") + " " + suffix;
                            }
                    }} /> </div>
                </td>
                <td>
                    <div className="icons-cell-wrapper">
                        <div className="icons-cell-content">
                            {
                                isRefreshing || (clickedCreateAutomationQueryId && clickedCreateAutomationQueryId !== segmentRow.queryid)
                                    ? <></>
                                    : segmentRow.segmenttype === "custom"
                                        ? <><i key={segmentRow.queryid + "editIcon"} class="fa-solid fa-pen segment-icon" data-bs-toggle="collapse" data-bs-target={"#" + rowId} ref={editBtnRef} onClick={() => { dispatch(setActiveQueryId(segmentRow.queryid)); dispatch(setOpenedRowSegmentName(segmentRow.segmentname)); setSegmentationEditorRow(false); setHasClickedEditBtn(true) }} data-tooltip-id={"my-tooltip-edit-" + rowId} data-tooltip-content={"Edit Segment"} data-tooltip-place="left"></i><Tooltip id={"my-tooltip-edit-" + rowId} /></>
                                        : <></>
                            }
                            { displayRefreshIcon() }
                            { displayAutomationIcon() }
                        </div>
                    </div>
                </td>
            </tr>
            <tr className="editor-row no-hover">
                <td colSpan={7}>
                    <div className='collapse' id={rowId} data-bs-parent="tbody">
                        { isShowSegmentationEditorRow && (segmentationEditorRow || <div><Loading /></div>) }
                    </div>
                </td>
            </tr>
        </>
    )
}

export default SegmentListingRow;