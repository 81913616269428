import { Navigate } from "react-router-dom";
import Loading from "../bootstrap-components/Loading";

const Protected =({ isLoggedIn, children }) => {
   if (isLoggedIn === undefined) {
      return <Loading />
   } else if (!isLoggedIn) {
      return <Navigate to="/" replace />
   } else {
      return children
   }
}

export default Protected;