const TopLevelError = ({apiError, has401Error, resetFlags}) => {

    const errorDefault = 'If you recently ran an Autobahn Install please check back soon, your data is being processed. If this issue persists, please reach out to your Red Van Workshop rep.'
    const error401 = 'It looks like you don’t have access. Please logout and try to log back in. If this issue persists, please reach out to your Red Van Workshop rep.';
    const has401ErrorPassedIn = apiError?.message?.includes('401') || has401Error;

    function logout() {
        window.location.href='/logout';
        resetFlags();
    }

    return (
        <>
            <div className='my-3'>
                <div>
                    {has401ErrorPassedIn ? error401 : errorDefault}
                </div>
                {has401ErrorPassedIn && <button className='mt-4 btn-primary' onClick={logout}>Logout</button>}
            </div>
        </>
    )
}

export default TopLevelError;