import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loading from "../bootstrap-components/Loading"
import { setNavDisabled } from "../../redux/commonSlice";
import { setIsRunQueryCompleteError } from "../../redux/segmentation/segmentationSlice";

const SegmentationUpdateLoading = ({ segmentName, updateQueryCall, checkQueryResult, checkQueryStatus, runQueryStatus }) => {

    const dispatch = useDispatch();
    const [isCreatePending, setIsCreatePending] = useState();

    useEffect(() => {
        const isResponsesBack = updateQueryCall;
        setIsCreatePending(!isResponsesBack);
    }, [updateQueryCall]);

    useEffect(() => {
        dispatch(setNavDisabled(isCreatePending));
    }, [isCreatePending]);

    useEffect(() => {
        if (runQueryStatus === 'rejected') {
            dispatch(setIsRunQueryCompleteError(true));
        }
    }, [runQueryStatus]);


    return (
        <>
            <h5 className='card-title'>
                Updating Segment
                {
                    (isCreatePending ? " — Please do not navigate away"
                    : checkQueryStatus === 'idle' || checkQueryStatus === 'pending' || (checkQueryStatus === 'fulfilled' && checkQueryResult?.isRunning === true)
                    ? ' — Your are free to leave this page' : '')
                }
            </h5>
            <div className='segment-status-group'>
                <div className='segment-status-item'>
                    {
                        updateQueryCall && updateQueryCall === "200-OK"
                            ?
                                <>
                                    <i class="fa-solid fa-check title-check-mark" />
                                    <div className="ml-3">Query named "{segmentName}" updated successfully</div>
                                </>
                            : updateQueryCall && updateQueryCall !== "200-OK"
                                ?
                                    <>
                                        <i class="fa-solid fa-xmark segment-error-icon" />
                                        <div className="ml-3">There was an error updating query named "{segmentName}"</div>
                                    </>
                                :
                                    <>
                                        <div className="fa-loading-container">
                                            <Loading />
                                        </div>
                                        <div className="ml-3">Query named "{segmentName}" is being updated</div>
                                    </>
                    }

                </div>
                <div className='segment-status-item'>
                    {
                        runQueryStatus !== 'rejected' && (runQueryStatus === 'pending' || checkQueryStatus === 'idle' || checkQueryStatus === 'pending' || (checkQueryStatus === 'fulfilled' && checkQueryResult?.isRunning === true))
                            ?
                                <>
                                    <div className="fa-loading-container">
                                        <Loading />
                                    </div>
                                    <div className="ml-3">Segment data processing</div>
                                </>
                            : runQueryStatus === 'fulfilled' || (checkQueryStatus === 'fulfilled' && checkQueryResult?.isRunning === false)
                                ?
                                    <>
                                        <i class="fa-solid fa-check title-check-mark" />
                                        <div className="ml-3">Segment data processing complete</div>
                                    </>
                                :
                                    <>
                                        <i class="fa-solid fa-xmark segment-error-icon" />
                                        <div className="ml-3">Error processing segment data</div>
                                    </>
                    }
                </div>
            </div>
        </>
    )
}

export default SegmentationUpdateLoading