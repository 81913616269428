import React, { useEffect, useState } from 'react';
import './EmailPreviewSettings.scss';
import EmailPreviewSettingsRow from './EmailPreviewSettingsRow';

const EmailPreviewSettings = ({triggeredSettingsData, updateTriggerSettingsArray, handleSaveTriggerSettingsClick, isTriggeredSettingsSaving}) => {

    

    return (
        <div className='email-preview-settings-container-relative'>
            <div className='email-preview-settings-container-absolute'>
                <div className='email-preview-settings-top d-flex justify-content-between'>
                    <div className='email-preview-settings-top-title'>ENABLE EMAILS TO PREVIEW</div>
                    {
                        isTriggeredSettingsSaving ? 
                            <div className='email-settings-spinner d-flex justify-content-center'><div className="spinner-border segment-row-loading" role="status"><span className="sr-only">Loading...</span></div></div>
                            : <button className='email-preview-settings-button' onClick={() => handleSaveTriggerSettingsClick()}>Save and Close</button>
                    }
                </div>
                <div className='email-preview-settings-body'>
                    { 
                        triggeredSettingsData.slice().sort((a,b) => a.values.name.localeCompare(b.values.name)).map((triggeredSend) => (
                            <EmailPreviewSettingsRow key={'triggeredSendRow_' + triggeredSend.keys.triggeredsendexternalkey} triggeredSettingsRowData={triggeredSend} updateTriggerSettingsArray={updateTriggerSettingsArray} handleSaveTriggerSettingsClick={handleSaveTriggerSettingsClick} />
                        ))
                    }
                </div>
            </div>
        </div>
    )

}

export default EmailPreviewSettings