import { Navigate } from "react-router-dom";
import Loading from "../bootstrap-components/Loading";

const ProtectedInstall = ({ isInstalled, children }) => {
   if (isInstalled === undefined) {
      return <Loading />
   } else if (!isInstalled) {
      return <Navigate to="/install" replace />
   }
      return children;
};

export default ProtectedInstall;