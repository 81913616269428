import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import ExpressService from '../../../service/ExpressService';
import Loading from '../../bootstrap-components/Loading';

const SendVolume = ({setApiError, setHas401Error}) => {
    const [sendVolumeDE, setSendVolumeDE] = useState();
    const [sendVolumeData, setSendVolumeData] = useState();
    const [hAxisTicks, setHAxisTicks] = useState({});

    useEffect(() => {
        async function getSendVolume() {
            const sendDEData = await ExpressService.callSFMC("/rest/data/v1/customobjectdata/key/SendDataMonthYearGrouping/rowset/", "GET", "DE", undefined, true);
            setSendVolumeDE(sendDEData);
            if (sendDEData?.name?.includes("Error") && sendDEData.message) {
                setApiError(sendDEData);
                sendDEData.message.includes("401") && setHas401Error(true);
                setSendVolumeData(sendDEData);
            }
        }
        getSendVolume();
    },[])

    useEffect(() => {
        if (Array.isArray(sendVolumeDE) && sendVolumeDE?.length > 0) {
            const volumeConvertedDates = [];

            //Need to add -1- for the day so we can  convert to date for sorting
            sendVolumeDE.forEach(function (sendVolume) {
                var newDate = sendVolume.monthyear.replace("-", "-1-");
                volumeConvertedDates.push({"monthyear": newDate, "monthyearcount": sendVolume.monthyearcount});
            });

            const sortedVolumeConvertedDates = volumeConvertedDates.sort((a, b) => new Date(a.monthyear) - new Date(b.monthyear))

            //Set hAxis tick labels, gridlines are not supported with category axes unless done this way
            setHAxisTicks(
                sortedVolumeConvertedDates.map(
                    (x, index) => {
                        return ({v: index, f: x.monthyear.replace("-1-", "-")})
                    }
                )
            );

            //Remove the -1- again so it doesn't show up in the chart
            const sortedVolumeCorrectedDates = sortedVolumeConvertedDates.map((x, index) => [{v: index, f: x.monthyear.replace("-1-", "-")}, Number(x.monthyearcount) || 0]);
            sortedVolumeCorrectedDates.unshift(["", ""]);
            setSendVolumeData(sortedVolumeCorrectedDates);
        } else if (Array.isArray(sendVolumeDE) && sendVolumeDE?.length === 0) {
            setSendVolumeData([]);
        }
    }, [sendVolumeDE])

    const options = {
        backgroundColor: "transparent",
        legend: "none",
        pointSize: 5,
        hAxis: {
            ticks: hAxisTicks,
            textStyle: {
                fontSize: 10
            }
        },
        height: 400,
        chartArea: {
            height: 350
        },
        tooltipFontSize: 16
    };

    return (
        <div className='card-body'>
            {
                sendVolumeData?.message?.includes("401")
                    ? <></>
                    : <h5 className='graph-title'>Send Volume</h5>
            }

            {
                sendVolumeData === undefined
                    ? <div className='graph-loader'><Loading /></div>
                    : sendVolumeData.length > 0
                        ?
                            <div style={{width: "800px"}}>
                                <Chart
                                    chartType="LineChart"
                                    data={sendVolumeData}
                                    options={options}
                                    width={"100%"}
                                    height={"400px"}
                                />
                            </div>
                        : sendVolumeData.length === 0
                            ?
                                <div style={{marginTop: "10px"}}>
                                    No data was found in your "SendDataMonthYearGrouping" Data Extension. This could be a result of recently running an Autobahn Install. Check back soon. If this issue persists reach out to your Red Van Workshop rep.
                                </div>
                            : sendVolumeData?.message?.includes("404")
                                ?
                                    <div style={{marginTop: "10px"}}>
                                        The Data Extension was not found.
                                    </div>
                                : <></>
            }
        </div>
    )
}

export default SendVolume;