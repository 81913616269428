import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ExpressService from '../../service/ExpressService';

const initialState = {
    value: null,
    status: 'idle',
    error: null,
    updatePutResult: null,
    updatePutStatus: 'idle',
    updatePutError: null
};

export const fetchStyles = createAsyncThunk('styles/fetchStyles', async (__, {rejectWithValue}) => {
    const result = await ExpressService.callSFMC('/rest/data/v1/customobjectdata/key/rvw_styles/rowset/', 'GET', 'DE');

    if (result?.name?.includes("Error") && result.message) {
        return rejectWithValue(result.message)
    };

    return result;
});

export const updateStyles = createAsyncThunk('styles/updateStyles', async (unsavedStylesChanges) => {
    return await ExpressService.callSFMC('/rest/data/v1/async/dataextensions/key:rvw_styles/rows', 'PUT', 'DE', unsavedStylesChanges);
});

const stylesSlice = createSlice({
    name: 'styles',
    initialState,
    reducers: {
        resetStylesPutResults(state) {
            return { ...state, updatePutResult: null, updatePutStatus: 'idle', updatePutError: null };
        }
    },
    extraReducers: {
        [fetchStyles.pending]: (state, action) => {
            return {...state, status: 'pending'};
        },
        [fetchStyles.fulfilled]: (state, action) => {
            return {...state, status: 'fulfilled', value: action.payload};
        },
        [fetchStyles.rejected]: (state, action) => {
            return {...state, status: 'rejected', value: action.payload};
        },
        [updateStyles.pending]: (state, action) => {
            return {...state,
                updatePutStatus: 'pending',
                updatePutResult: null,
                updatePutError: null
            };
        },
        [updateStyles.fulfilled]: (state, action) => {
            if (action?.payload?.status === '202-Accepted') {
                return {...state,
                    updatePutStatus: 'fulfilled',
                    updatePutResult: action.payload,
                    value: action.meta.arg
                };
            } else {
                return {...state,
                    updatePutStatus: 'fulfilled',
                    updatePutResult: action.payload
                };
            }
        },
        [updateStyles.rejected]: (state, action) => {
            return {...state, updatePutStatus: 'rejected'};
        }
    }
});

export const { resetStylesPutResults } = stylesSlice.actions;

export const getStyles = state => state?.styles?.value;

export const getStylesStatus = state => state?.styles?.status;

export const getStylesPutResult = state => state?.styles?.updatePutResult;

export const getStylesPutStatus = state => state?.styles?.updatePutStatus;

export default stylesSlice.reducer;