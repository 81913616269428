import React, { useEffect, useState } from "react";
import styles from "./SubscriberHistorySearch.module.scss"
import { useDispatch, useSelector } from "react-redux";
import spacetime from "spacetime";
import { fetchMatchedSubscriberList, fetchSubscriberRevenue, getMatchedSubscriberListStatus, getMatchedSubscriberList, resetSubcriberFlags, setCurrentSubscriber, fetchSubscriberEmailEngagement, fetchSendEvents, fetchOpenEvents, fetchClickEvents, fetchBounceEvents, fetchNotSentEvents } from "../../redux/subscriber-history/subscriberHistorySlice";
import Loading from "../bootstrap-components/Loading";
import { Tooltip } from "react-tooltip";

const SubscriberHistorySearch = () => {
    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState(null);
    const [subscriberList, setSubscriberList] = useState();     // list for render
    const matchedSubscriberList = useSelector(getMatchedSubscriberList);    // matched list from api
    const fetchMatchedSubscriberListStatus = useSelector(getMatchedSubscriberListStatus);

    const isPending = fetchMatchedSubscriberListStatus === 'pending';
    const isErrored = fetchMatchedSubscriberListStatus === 'rejected';

    const errorMessage = 'An error has occured when searching for subscribers';

    useEffect(() => {
        return () => dispatch(resetSubcriberFlags());
    }, []);

    useEffect(() => {
        if (matchedSubscriberList) {
            if (matchedSubscriberList?.length === 1) { // load sub detail comp
                loadSubscriberInfo(matchedSubscriberList[0]);
            } else if (matchedSubscriberList?.length > 1) { // multiple: load results
                setSubscriberList(matchedSubscriberList);
            } else { // no results
                setSubscriberList(null);
            }
        }
    }, [matchedSubscriberList]);

    function submitOnEnterKey(event) {
        if (event.key === "Enter") {
            performSearch();
        }
    }

    function performSearch() {
        if (searchValue) {
            dispatch(fetchMatchedSubscriberList(searchValue));
        }
    }

    function loadSubscriberInfo(selectedSubscriber) {
        if (selectedSubscriber) {
            // fetch revenue infos
            dispatch(fetchSubscriberRevenue(selectedSubscriber.SubscriberKey));
            dispatch(fetchSubscriberEmailEngagement(selectedSubscriber.SubscriberKey));
            dispatch(setCurrentSubscriber(selectedSubscriber));
            // fetch events data
            dispatch(fetchSendEvents(selectedSubscriber.SubscriberKey));
            dispatch(fetchOpenEvents(selectedSubscriber.SubscriberKey));
            dispatch(fetchClickEvents(selectedSubscriber.SubscriberKey));
            dispatch(fetchBounceEvents(selectedSubscriber.SubscriberKey));
            dispatch(fetchNotSentEvents(selectedSubscriber.SubscriberKey));
        }
    }


    return (
        <div className={'card-body ' + styles.center}>
            <div className={`w-75 ${styles.searchBox}`}>
                <div className="form-input-prefix-icon">
                    <input
                        type="search"
                        class="form-control"
                        placeholder="Search for a subscriber"
                        arialabel="Search for a subscriber"
                        ariaDescribedby="Search for a subscriber"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyUp={submitOnEnterKey}
                    />
                    <i className='fa-solid fa-magnifying-glass'></i>
                </div>
                <div className={styles.searchResultContainer}>
                    {
                        isPending ? <div><Loading /></div>
                            : isErrored ? <div className={styles.searchResultText}>Error: {errorMessage}</div>
                                : subscriberList ?
                                    <div className={"list-group list-group-flush list-group-custom-border " + styles.listGroup}>
                                        {
                                            subscriberList.map((subcriberObj) =>
                                                {
                                                    let createdDate = spacetime(subcriberObj.CreatedDate).format(`{year}-{date-pad}-{month-pad}`);
                                                    let tooltipContent = `Email: ${subcriberObj.EmailAddress} Created Date: ${createdDate}`;
                                                        return (
                                                            <button
                                                                class="list-group-item list-group-item-action"
                                                                onClick={() => loadSubscriberInfo(subcriberObj)}
                                                                data-tooltip-id={"my-tooltip-search-item-" + subcriberObj.ID}
                                                                data-tooltip-content={tooltipContent}
                                                                data-tooltip-place="bottom"
                                                            >
                                                                <div className={styles.searchResultItemContainer}>
                                                                    <Tooltip id={"my-tooltip-search-item-" + subcriberObj.ID } />
                                                                    <span><span className="font-weight-bold">Key:</span> {subcriberObj.SubscriberKey}</span>
                                                                    <span><span className="font-weight-bold">Email:</span> {subcriberObj.EmailAddress}</span>
                                                                    <span><span className="font-weight-bold">Created Date:</span> {createdDate}</span>
                                                                </div>
                                                                <i class="fa-solid fa-chevron-right"></i>
                                                            </button>
                                                        )
                                                }
                                            )
                                        }
                                    </div>
                                    : subscriberList === null ? <div className={styles.searchResultText}>No data found.</div> : <></>
                    }
                </div>
            </div>

        </div>
    );
};

export default SubscriberHistorySearch;