import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ExpressService from '../service/ExpressService';

const initialState = {
    value: null,
    status: 'idle',
    error: null,
    refreshStatus: 'idle'
};

export const fetchMe = createAsyncThunk('me/fetchMe', async () => {
    const userInfo = await fetch('/api/me');
    return await userInfo.json();
});

export const refreshMe = createAsyncThunk('me/refreshMe', async () => {
    const userInfo = await ExpressService.callSFMC('/api/refreshTokensAndInfo', 'GET', undefined, undefined, true);
    return await userInfo;
});

const meSlice = createSlice({
    name: 'me',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchMe.pending]: (state, action) => {
            return {...state, status: 'pending'};
        },
        [fetchMe.fulfilled]: (state, action) => {
            return {...state, status: 'fulfilled', value: action.payload};
        },
        [fetchMe.rejected]: (state, action) => {
            return {...state, status: 'rejected'};
        },
        [refreshMe.pending]: (state, action) => {
            return {...state,
                refreshStatus: 'pending',
            };
        },
        [refreshMe.fulfilled]: (state, action) => {
            return {...state,
                refreshStatus: 'fulfilled',
                value: action.payload.data
            };
        },
        [refreshMe.rejected]: (state, action) => {
            return {...state, refreshStatus: 'rejected'};
        }
    }
});

export const getMe = state => state?.me?.value;

export const getMeStatus = state => state?.me?.status;

export const getIsLoggedIn = state => state?.me?.value?.isLoggedIn;

export const getUserExpTime = state => state?.me?.value?.user?.exp;

export const getMid = state => state?.me?.value?.user?.organization?.member_id;

export const getEid = state => state?.me?.value?.user?.organization?.enterprise_id;

export const getZoneInfo = state => state?.me?.value?.user?.organization?.zoneinfo;

export default meSlice.reducer;