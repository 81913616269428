import "./SubscriberEventsRow.scss";
import spacetime from "spacetime";

const SubscriberEventsRow = ({subscriberEvent}) => {

    const faIcon =
        subscriberEvent.Type === "Sent" ? "fa-envelope"
        : subscriberEvent.Type === "Opened" ? "fa-envelope-open"
        : subscriberEvent.Type === "Clicked" ? "fa-arrow-pointer"
        : subscriberEvent.Type === "Bounced" ? "fa-user-xmark"
        : subscriberEvent.Type === "Not Sent" ? "fa-triangle-exclamation"
        : subscriberEvent.Type === "Ordered" ? "fa-cart-shopping"
        : subscriberEvent.Type === "Conversion" ? "fa-cart-plus"
        : "";

    const eventDateSpaceTime = spacetime(subscriberEvent.EventDateEpoch).format(`{month-short} {date-pad}, {year} {time}`)

    function returnEventText() {
        if (subscriberEvent.Type === "Sent" || subscriberEvent.Type === "Opened" || subscriberEvent.Type === "Clicked" || subscriberEvent.Type === "Bounced" || subscriberEvent.Type === "Not Sent" ) {
            return subscriberEvent.Type + ' - Email "Summer Deals - 20% off Camping Gear"';
        } else if (subscriberEvent.Type === 'Ordered') {
            return 'Made Purchase - Not Attributed to an Email';
        }
    }

    return (
        <div className="d-grid event-row">
            <i className={`fa-solid ${faIcon} event-icon`}></i>
            <div>
                { returnEventText() }
            </div>
            <div className="event-vertcal-line"></div>
            <div className="event-date-time">
                { eventDateSpaceTime }
                <br/>
                { subscriberEvent.JobId && 'Job ID: ' + subscriberEvent.JobId }
                { subscriberEvent.OrderNumber && 'Order Number: ' + subscriberEvent.OrderNumber }
            </div>
        </div>
    )
}

export default SubscriberEventsRow;