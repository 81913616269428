import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import ExpressService from '../../../service/ExpressService';
import Loading from '../../bootstrap-components/Loading';

const SubscriberEngagement = ({setApiError, setHas401Error}) => {
    const [subscriberEngagementDE, setSubscriberEngagementDE] = useState();
    const [subscriberEngagementChartData, setSubscriberEngagementChartData] = useState();

    useEffect(() => {
        async function getSendEmailRates() {
            const subscriberEngagementDEData = await ExpressService.callSFMC("/rest/data/v1/customobjectdata/key/Engagement_Final/rowset/", "GET", "DE", undefined, true);
            setSubscriberEngagementDE(subscriberEngagementDEData);
            if (subscriberEngagementDEData?.name?.includes("Error") && subscriberEngagementDEData.message) {
                setApiError(subscriberEngagementDEData);
                subscriberEngagementDEData.message.includes("401") && setHas401Error(true);
                setSubscriberEngagementChartData(subscriberEngagementDEData);
            }
        }
        getSendEmailRates();
    },[])

    useEffect(() => {
        if (Array.isArray(subscriberEngagementDE) && subscriberEngagementDE?.length === 0) {
            setSubscriberEngagementChartData([]);
        } else if (Array.isArray(subscriberEngagementDE) && subscriberEngagementDE?.length > 0) {
            const subscribers = {};
            for (const [key, value] of Object.entries(subscriberEngagementDE[0])) {
                subscribers[key] = Number(value) || 0;
            }

            setSubscriberEngagementChartData([
                ["Subscriber Engagement", "Opens", "Clicks", "Orders"],
                ["Unengaged (0 actions in the last 3 months)", subscribers.unengagedopencount, subscribers.unengagedclickcount, subscribers.unengagedordercount],
                ["Engaged (1 action in the last 3 months)", subscribers.engagedopencount, subscribers.engagedclickcount, subscribers.engagedordercount],
                ["Highly Engaged (> 1 actions in the last 3 months)", subscribers.highlyengagedopencount, subscribers.highlyengagedclickcount, subscribers.highlyengagedordercount]
            ]);
        }
    }, [subscriberEngagementDE])

    const options = {
        backgroundColor: "transparent"
    };

    return (
        <div className='card-body'>
            {
                subscriberEngagementChartData?.message?.includes("401")
                    ? <></>
                    : <h5 className='graph-title'>Subscribers' Engagement</h5>
            }
            {
                subscriberEngagementChartData === undefined
                    ? <div className='graph-loader'><Loading /></div>
                    : subscriberEngagementChartData.length > 0
                        ?
                            <div style={{width: "600px"}}>
                                <Chart
                                    chartType="ColumnChart"
                                    data={subscriberEngagementChartData}
                                    options={options}
                                    width={"100%"}
                                    height={"300px"}
                                />
                            </div>
                        : subscriberEngagementChartData.length === 0
                            ?
                                <div style={{marginTop: "10px"}}>
                                    No data was found in your “Engagement_Final” Data Extension. This could be a result of recently running an Autobahn Install. Check back soon. If this issue persists reach out to your Red Van Workshop rep.
                                </div>
                            : subscriberEngagementChartData?.message?.includes("404")
                                ?
                                    <div style={{marginTop: "10px"}}>
                                        The Data Extension was not found.
                                    </div>
                                :
                                    <></>
            }
        </div>
    )
}

export default SubscriberEngagement;